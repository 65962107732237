/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { validateSlide } from "./helpers/validateSlide";
import { fetchWeeklyPlan } from "./helpers/fetchWeeklyPlan";
// import { verifyOtp } from "./helpers/otp";
import { RenderSlide } from "./UIComponents/RenderSlide";
// import { PhoneInput } from "./UIComponents/PhoneInput";
// import { OTPVerification } from "./UIComponents/OTPVerification";
import { NavigationButtons } from "./UIComponents/NavigationButtons";
import { ErrorMessage } from "./UIComponents/ErrorMessage";
import Loader from "./UIComponents/Loader";
import ReactModal from "react-modal";
import { slides } from "./helpers/slides";
import { auth, db } from "../../../config/firebase";
// import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { deleteDoc, doc, getDoc, setDoc } from "@firebase/firestore";
import { UserLoginModal } from "../../ModalHelpers/UserCreds";

const UserFitnessForm = ({ isModalOpen, onClose }) => {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(0);
    const [errorMsg, setErrorMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const [startScreen, setStartScreen] = useState(false); // Determines if the starting slide is shown, on false, directly opening the slides

    const [formValues, setFormValues] = useState({
        age: "",
        gender: "",
        height: "",
        weight: "",
        fitnessGoal: "",
        healthConcerns: "",
        activityLevel: "",
        dailyFitnessTime: "",
        dietPreference: "",
        diseases: [],
        workoutTypes: [],
        area: "",
        budget: "",
    });

    const [view, setView] = useState("userDetails");
    const [, setPhoneNumber] = useState("");
    const [, setName] = useState("");
    const [, setOtp] = useState(Array(6).fill(""));
    const [, setConfirmationResult] = useState(null);
    const [responseData, setResponseData] = useState("");
    const [otpVerified, setOtpVerified] = useState(false);
    // const [buttonText, setButtonText] = useState("Verify Phone Number");

    const [showSignIn, setShowSignIn] = useState(false);

    useEffect(() => {
        if (otpVerified && responseData?.status === "success") {
          transferDataToPermanentUser(); // Transfer data after authentication
          navigate("/myFitnessPlan", {
              state: {
                  weeklyPlan: responseData.weeklyPlan || [],
                  status: "success",
                  message: responseData.message,
                  userDetails: formValues,
              },
          });
        }
    }, [otpVerified, responseData, navigate, formValues]);

    const handleNext = () => {
        if (!validateSlide(currentStep, slides, formValues, setErrorMsg)) return;
        setCurrentStep((prev) => prev + 1);
    };

    const handleBack = () => {
        setErrorMsg("");
        setCurrentStep((prev) => prev - 1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateSlide(currentStep, slides, formValues, setErrorMsg)) return;

        setLoading(true);
        const fetchedData = await fetchWeeklyPlan(formValues);

        if (fetchedData.status === "success") {

          const userId = "tempUser"; // Save under 'tempUser' initially
          // const userPlanRef = doc(db, "user", userId, "plan", "details");

          try {
              // Save user details in Firestore
              await saveUserDataToFirestore(userId, formValues, fetchedData);

              toast.success("Your plan has been saved!");

              // Check if the user is already authenticated
              if (auth.currentUser) {
                // If user is authenticated, navigate directly to /myFitnessPlan
                navigate("/myFitnessPlan", {
                    state: {
                        weeklyPlan: fetchedData.weeklyPlan || [],
                        status: "success",
                        message: fetchedData.message,
                        userDetails: formValues,
                    },
                });

                transferDataToPermanentUser();
              } else {
                    // Show login modal for unauthenticated users
                    setShowSignIn(true);
              }
          } catch (error) {
            //   console.error("Error saving data to Firestore:", error);
              toast.error("Failed to save your plan. Please try again.");
          }
          setResponseData(fetchedData);
        } else {
            toast.error("There was an error fetching data. Please try again later.");
        }
        setLoading(false);
    };

    const transferDataToPermanentUser = async () => {
      if (!auth.currentUser) return;

      const userId = auth.currentUser.uid;
      const tempUserRef = doc(db, "user", "tempUser", "plan", "details");
      const permanentUserRef = doc(db, "user", userId, "plan", "details");

      const userDetails = {
        age: formValues.age,
        gender: formValues.gender,
        city: formValues.area,
        name: auth.currentUser.displayName,
        phoneNumber: auth.currentUser.phoneNumber,
    };

      try {
          const tempDataSnapshot = await getDoc(tempUserRef);
          if (tempDataSnapshot.exists()) {
                const tempData = tempDataSnapshot.data();

                // Save data to the authenticated user's UID
                await setDoc(permanentUserRef, tempData);

                // Save or update user details
                await handleSaveOrUpdateUserDetails(userId, userDetails);

                // Optionally delete the temporary data
                await deleteDoc(tempUserRef);

                // console.log("Data transferred to permanent user UID.");
          } else {
                // console.log("No temporary data found to transfer.");
          }
      } catch (error) {
        //   console.error("Error transferring data to permanent user:", error);
          toast.error("Failed to transfer your data. Please contact support.");
      }
    };

    const saveUserDataToFirestore = async (userId, formValues, fetchedData) => {
      try {
          // Reference to the user's plan document in Firestore
          const userPlanRef = doc(db, "user", userId, "plan", "details");

          // Map the fetched data into structured Firestore data
          const weeklyPlan = fetchedData.weeklyPlan.map((day) => ({
              day: day.day,
              meals: day.meals || {},
              meditation: day.meditation || {},
              workout: day.workout || {},
          }));

          // Save user details and weekly plan in Firestore
          await setDoc(userPlanRef, {
              userDetails: {
                  ...formValues, // Include form values like age, height, etc.
              },
              weeklyPlan, // Save the structured weekly plan data
          });

        //   console.log("User data saved successfully!");
      } catch (error) {
          console.error("Error saving user data to Firestore:", error);
      }
    };

    const handleSaveOrUpdateUserDetails = async (userId, userDetails) => {
        const userRef = doc(db, "user", userId);
    
        try {
            const userSnapshot = await getDoc(userRef);
    
            if (userSnapshot.exists()) {
                // User data already exists
                const existingData = userSnapshot.data();
    
                // Prompt user to decide whether to update details or check for a friend
                const userChoice = window.confirm(
                    "We already have your details saved. Do you want to update them? If not, click 'Cancel' to check for a friend's fitness plan."
                );
    
                if (userChoice) {
                    // User chose to update details
                    await setDoc(userRef, { ...existingData, ...userDetails }, { merge: true });
                    toast.success("Your details have been updated successfully!");
                } else {
                    toast.info("You can now check for a friend's fitness plan.");
                }
            } else {
                // Save new user details
                await setDoc(userRef, userDetails);
                toast.success("Your details have been saved successfully!");
            }
        } catch (error) {
            // console.error("Error saving or updating user details:", error);
            toast.error("Failed to save your details. Please try again.");
        }
    };

    // const initializeRecaptcha = async () => {
    //     if (!window.recaptchaVerifier && document.getElementById("recaptcha-container")) {
    //         try {
    //             window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
    //                 size: "invisible",
    //             });
    //             await window.recaptchaVerifier.render();
    //         } catch (error) {
    //             console.error("Error initializing reCAPTCHA", error);
    //         }
    //     }
    // };

    // const handleSendOtp = async () => {
    //     if (!phoneNumber || !name) {
    //         toast.error("Please enter your full name and phone number.");
    //         return;
    //     }
    //     try {
    //         setButtonText("Verifying...");
    //         initializeRecaptcha();
    //         const appVerifier = window.recaptchaVerifier;

    //         const result = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
    //         setConfirmationResult(result);
    //         setView("otpVerification");
    //         toast.success("OTP sent successfully!");
    //         setButtonText("Verify OTP");
    //     } catch (error) {
    //         setButtonText("Verify Phone Number");
    //         toast.error("Failed to send OTP. Please try again.");
    //     }
    // };

    // const handleVerifyOtp = async () => {
    //     const result = await verifyOtp({ otp, confirmationResult });
    //     if (result.success) {
    //         setOtpVerified(true);
    //         setOtp(Array(6).fill(""));
    //         setView("userDetails");
    //     } else {
    //         toast.error(result.message);
    //     }
    // };

    const resetForm = () => {
        onClose();
        setPhoneNumber("");
        setName("");
        setOtp(Array(6).fill(""));
        setConfirmationResult(null);
        setResponseData("");
        setOtpVerified(false);
        setCurrentStep(0);
        setView("userDetails");
        setStartScreen(true);
    };

    return (
        <ReactModal
            isOpen={isModalOpen}
            onRequestClose={resetForm}
            contentLabel="User Fitness Form"
            className="fixed inset-0 flex items-center justify-center z-50 text-black"
            overlayClassName="fixed inset-0 bg-logoGray bg-opacity-60 z-50"
        >
            {startScreen ? (
                <div className="space-y-4 bg-white rounded-3xl shadow-lg max-w-[90%] h-[250px] max-h-[250px] relative flex flex-col items-center p-6">
                    <button
                        className="absolute top-2 right-4 text-gray-500 text-2xl hover:text-black"
                        onClick={resetForm}
                    >
                        &times;
                    </button>
                    <h2 className="text-2xl font-bold mb-12 text-primary">
                        Welcome to Train Rex!
                    </h2>
                    <div className="flex flex-col space-y-8 w-full">
                        <button
                            className="px-8 py-2 bg-secondary text-white rounded-lg hover:bg-lightSecondary"
                            onClick={() => setStartScreen(false)}
                        >
                            Get personalized plans
                        </button>
                        <button
                            className="px-4 py-2 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400"
                            onClick={resetForm}
                        >
                            Explore Fitness Marketplace
                        </button>
                    </div>
                </div>
            ) : (
                <div className="bg-white rounded-3xl shadow-lg max-w-[90%] max-h-[600px] relative flex flex-col items-center p-6">
                    <button
                        className="absolute top-2 right-4 text-gray-500 text-2xl hover:text-black"
                        onClick={resetForm}
                    >
                        &times;
                    </button>
                    {loading && <Loader />}
                    {/* <Loader /> */}
                    {view === "userDetails" && (
                        <form onSubmit={handleSubmit}>
                            <h2 className="text-2xl font-bold mb-4 text-primary">
                                {slides[currentStep].title}
                            </h2>
                            <RenderSlide
                                slide={slides[currentStep]}
                                formValues={formValues}
                                handleInputChange={(e) =>
                                    setFormValues((prev) => ({ ...prev, [e.target.name]: e.target.value }))
                                }
                                handleMultiSelectChange={(name, option) => {
                                    const currentArray = Array.isArray(formValues[name])
                                        ? [...formValues[name]]
                                        : [];
                                    setFormValues({
                                        ...formValues,
                                        [name]: currentArray.includes(option)
                                            ? currentArray.filter((item) => item !== option)
                                            : [...currentArray, option],
                                    });
                                }}
                            />
                            <ErrorMessage errorMsg={errorMsg} />
                            <NavigationButtons
                                currentStep={currentStep}
                                totalSteps={slides.length}
                                handleBack={handleBack}
                                handleNext={handleNext}
                                handleSubmit={handleSubmit}
                            />
                        </form>
                    )}
                    {/* {view === "phoneInput" && (
                        <PhoneInput
                            name={name}
                            phoneNumber={phoneNumber}
                            setName={setName}
                            setPhoneNumber={setPhoneNumber}
                            sendOtp={handleSendOtp}
                            buttonText={buttonText}
                        />
                    )}
                    {view === "otpVerification" && (
                        <OTPVerification
                            otp={otp}
                            handleOtpInputChange={(index, value) => {
                                const updatedOtp = [...otp];
                                updatedOtp[index] = value;
                                setOtp(updatedOtp);
                            }}
                            verifyOtp={handleVerifyOtp}
                            phoneNumber={phoneNumber}
                            setView={setView}
                            buttonText={buttonText}
                        />
                    )} */}
                    {/* <div id="recaptcha-container"></div> */}
                    <button
                        onClick={resetForm}
                        className="absolute top-2 right-4 text-gray-500 text-2xl"
                    >
                        &times;
                    </button>
                </div>
            )}
            {/* When user clicks sign in/up, then this modal opens */}
            <UserLoginModal
                modalIsOpen={showSignIn}
                setModalIsOpen={setShowSignIn}
                myData={{ city: formValues.area }}
            />
        </ReactModal>
    );
};

export default UserFitnessForm;
