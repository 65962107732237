/**
 * addTrexToWallet.js
 * 
 * This file helps when user adds trex to their wallets
 */

import { doc, increment, updateDoc } from "@firebase/firestore";
import { db } from "../../config/firebase";

/**
 * This function adds trex to user database
 * @param   {Number} trex       -   Amount of trex to be added
 * @param   {Object} userID     -   details of the user
 * 
 * @returns {Boolean}           -   true; if storing successful, false; otherwise
 */
export const addTrexToWallet = async (trex, userID) => {
    try {
        const userRef = doc(db, 'user', userID);
        await updateDoc(userRef, {
            trexCredits     :   increment(trex / 100),
            isUpdated       :   false,
        });
        return true;

    } catch (error) {
        return false;
    }
}