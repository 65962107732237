import { addDoc, collection, doc, getDoc, getDocs, increment, query, updateDoc, where } from "@firebase/firestore";
import { db } from "../../../config/firebase";
import { referralCodesList } from "../../../components/Databases";

/** 
 * Fetches referral code of the logged in user
 * If it is not present, it calls a function which creates a referral code for the user
 * 
 * @param   {String}    userID      -   uid of the user
 * @param   {String}    userName    -   name of the user (helps in customsied generation)
 * @returns {String}                -   Referral code of the user
 */
export const fetchReferralCode = async (userID, userName) => {
    try {
        // Step 1: Find the referral code document in "referralCodes" where userID matches userDocId
        const referralCodesRef     = collection(db, referralCodesList);
        const referralCodeQuery    = query(referralCodesRef, where("userID", "==", userID));
        const referralCodeSnapshot = await getDocs(referralCodeQuery);

        if (!referralCodeSnapshot.empty) {
            // Get the referral code field from the matched document
            const referralCodeData = referralCodeSnapshot.docs[0].data();
            return referralCodeData.referralCode;
        } 
        // If there's no referral code of the user
        // Then this function generates one and stores it in the database
        else {
            return iWantMyReferralCode(userID, userName);
        }
    } catch (error) {
        // console.error("Error fetching referral code:", error);
        return null;
    }
}

// Checks if the referral code used is valid or not
export const checkReferralCodeValidity = async (referralCode) => {
    try {
        // Create a query to find the referral code document
        const referralCodesRef = collection(db, referralCodesList);
        const q = query(referralCodesRef, where("referralCode", "==", referralCode));

        
        // Execute the query
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            // If document(s) found, retrieve the first one and extract userID
            const docData = querySnapshot.docs[0].data();
            const userID = docData.userID;

            return { isValid: true, userID, code : referralCode };
        } else {
            // If no document found
            return { isValid: false, userID: null };
        }
    } catch (error) {
        // console.error("Error in Referral code validity check:", error);
        return { isValid: false, userID: null };
    }
};

/**
 * Returns the amount of trex credits that needs to be added in referrar's account
 * @returns {Number}    -   Trex Amount
 */
const incrementTrexCreditsBy = (totalMemberships = 1) => {
    // amount /= 100;
    // if (amount > 1000) {
    //     return 10;
    // } else if (amount > 3000) {
    //     return 20;
    // } else if (amount > 5000) {
    //     return 30;
    // } else if (amount > 10000) {
    //     return 50;
    // } else if (amount > 20000) {
    //     return 75;
    // } else {
    //     return 100;
    // }
    return 50 * totalMemberships;
}   

/**
 * Processes a referral code to grant the referrer Trex credits and 
 * increment the usage counter of the referral code.
 * 
 * @param {string} userID           - User ID of the referral giver.
 * @param {number} totalMemberships - Number of memberships referred.
 * 
 * @returns {Promise<void>} - Resolves when the referral process is complete.
 * @throws {Error} - Logs error if any Firebase operation fails.
 */
export const handleReferralCodeProcessing = async (userID, totalMemberships) => {

    try {
        // Fetch the referral giver's user document
        if(!userID) {
            return;
        }

        const userRef = doc(db, "user", userID);
        const userSnapshot = await getDoc(userRef);

        if (userSnapshot.exists()) {
            // Increment Trex credits for the referral giver
            await updateDoc(userRef, {
                trexCredits: increment(incrementTrexCreditsBy(totalMemberships) <= 0 ? 0 : incrementTrexCreditsBy(totalMemberships))
            });

            // Query referral code to update its usage counter
            const referralCodeRef = collection(db, referralCodesList);
            const referralQuery = query(referralCodeRef, where("userID", "==", userID));
            const referralQuerySnapshot = await getDocs(referralQuery);

            // Update the first (and only) document
            if (!referralQuerySnapshot.empty) {
                const referralDocRef = referralQuerySnapshot.docs[0].ref;
                await updateDoc(referralDocRef, {
                    counter: increment(totalMemberships)
                });
            }
        }
    } catch (error) {
        console.error("Error updating referral giver details:", error);
    }
};

/**
 * Generates a referral code for the user
 * This is a customised referral code
 * 
 * @param   {String} userID     -   uid of the user
 * @param   {String} userName   -   name of the user
 * @returns {String}            -   The newly created referral code for the user
 */
const iWantMyReferralCode = async (userID, userName) => {
    let code = generateReferralCode(userName);
    const codeRef = collection(db, referralCodesList);
    try {
        await addDoc(codeRef, {
            referralCode    :   code,
            userID          :   userID,
            counter         :   0,
        })
        return code;
    } catch (error) {
        console.error('Error generating referral code : ', error);
        return '';
    }
}

/**
 * Generates a unique 8-character referral code based on the user's name.
 *
 * The code consists of the first 5 letters of the user's name (padded if shorter)
 * followed by 3 random digits. It ensures the code is unique by checking against
 * the `referralCodes` collection in Firebase.
 *
 * @param   {String} userName           - Name of the user.
 *
 * @returns {Promise<String>}           - A promise that resolves to a unique 8-character referral code.
 */
const generateReferralCode = async (userName) => {
    /**
     * @returns {String}    -   Random 3 digits
     */
    const generateRandomDigits = () => {
        return Math.floor(100 + Math.random() * 900).toString(); // Ensures 3 random digits
    };

    /**
     * Sanitises the name
     * Takes the first 5 characters
     * if the name is shorter, this adds 'x's
     * 
     * @returns {String}    -   5 characters name
     */ 
    const sanitizeName = (name) => {
        const cleanName = name.toLowerCase().replace(/[^a-z]/g, ''); // Remove non-alphabetic chars
        return cleanName.padEnd(5, 'x').slice(0, 5); // Pad or truncate to 5 chars
    };

    /**
     * Checks if the code is unique or not
     * By checking for all the referral codes in the database
     * 
     * @param   {String} code   -   referral code
     * @returns {Boolean}       -   true, if the code is unique; false otherwise
     */
    const isCodeUnique = async (code) => {
        const referralCodesSnapshot = await getDocs(collection(db, 'referralCodes'));
        return !referralCodesSnapshot.docs.some(doc => doc.data().code === code);
    };

    let referralCode;

    // This is a constant part thus need not be put in do
    const namePart = sanitizeName(userName);
    do {
        const randomDigits = generateRandomDigits();
        referralCode = `${namePart}${randomDigits}`;
    } while (!(await isCodeUnique(referralCode))); // Ensure uniqueness

    return referralCode;
};