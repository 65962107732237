import { getDaysAgo } from '../MarketplaceHelpers/gymDataHelpers/GymReviewsHelper'; // Helper function to calculate the number of days since the review was posted
import verified from "../../assets/Verified.webp"; 
import StarRating from '../../components/StarRating';

/**
 * ReviewItem Component
 * This component is responsible for displaying an individual review for a gym.
 * It shows the user's name, their rating, the time since the review was posted, 
 * and whether the review has been verified by Train Rex. 
 * 
 * @param {Object} review   - An object containing the details of the review, including:
 *                          - userName  {String}    : The name of the user who left the review
 *                          - rating    {Number}    : The rating given by the user (1-5 stars)
 *                          - timestamp {timestamp} : The timestamp of when the review was posted
 *                          - verified  {Bool}      : A boolean indicating whether the review is verified by Train Rex
 *                          - review    {String}    : The actual review content/text written by the user
 */
export const ReviewItem = ({ review }) => (
    <div className="flex flex-col mb-4">
        {/* HEADING OF THE REVIEW */}
        {/* Review Header: Displays user's name and their star rating */}
        <div className="flex flex-row justify-between py-2">
            <p className="flex text-secondary text-sm lg:text-2xl font-bold">
                {review?.isAnonymous ? 'Anonymous' : review.userName} {/* Display reviewer's name */}
            </p>
            <div className="flex">
                <StarRating 
                    rating={review.rating} // Show star rating
                    color={'secondary'} // Use secondary color for stars
                    showNoOfReviews={false} // Don't show the number of reviews
                />
            </div>
        </div>

        {/* BELOW THE HEADING */}
        {/* Timestamp and Verified Badge */}
        <div className="flex -mt-2 mb-2">
            <div className="text-left text-primary text-xs">
                {getDaysAgo(review.timestamp)} {/* Display the number of days ago the review was posted */}
            </div>
            
            {/* Display the "Verified by Train Rex" badge if the review is verified */}
            {review.verified ? (
                <>
                    <div className="text-base -mt-1 px-1 text-primary"> | </div>
                    <span className="text-xs text-secondary mr-1 font-semibold -mb-1">
                        Verified Review
                    </span>
                    <img src={verified} alt="Trusted" className="h-4 w-4" /> {/* Verified badge image */}
                </>
            ) : null}
        </div>

        {/* MAIN BODY OF REVIEW */}
        {/* Review Content: The actual review written by the user */}
        <p className="flex text-black text-sm lg:text-lg text-left whitespace-pre-line">
            {review.review}
        </p>

        {/* Horizontal line separator */}
        <div className="flex border-t-[1px] border-secondary mt-4"></div>
    </div>
);

