/**
 * HandlingReviews.js
 * 
 * Contains the helper functions to fetch / add reviews into the database
 */

import { collection, getDocs } from "@firebase/firestore";
import { db } from "../../config/firebase";
import { cityList, gymList, reviewList, studioList } from "../../components/Databases";

/**
 * Fetches the Reviews of the gym
 * @param {Object} basicGymData - Contains gymName and city
 * @returns {Object} - Contains the reviews of the gym
 */
export const fetchGymReviews = async (basicGymData) => {
    // console.log("Data : ", basicGymData)
    let reviews = [];
    try {
        // Reference to the Reviews collection for the specified gym
        const reviewRef = collection(db, cityList, basicGymData.city, gymList, basicGymData.gymName, reviewList);

        // Fetch reviews
        const reviewsSnapshot = await getDocs(reviewRef);
        reviewsSnapshot.forEach((doc) => {
            // Filter out the collection started review
            if (doc.id === 'testReview')
                return;

            reviews.push({
                id: doc.id,
                ...doc.data()
            });
        });

        return reviews;

    } catch (error) {
        console.error('Error in Fetching Reviews:', error);
    }
};

export const fetchStudioReviews = async (studioCity, studioName) => {
    let reviews = [];
    try {
        // Reference to the Reviews collection for the specified gym
        const reviewRef = collection(db, cityList, studioCity, studioList, studioName, reviewList);

        // Fetch reviews
        const reviewsSnapshot = await getDocs(reviewRef);
        reviewsSnapshot.forEach((doc) => {
            // Filter out the collection started review
            if (doc.id === 'testReview')
                return;

            reviews.push({
                id: doc.id,
                ...doc.data()
            });
        });
        
        return reviews;

    } catch (error) {
        console.error('Error in Fetching Reviews:', error);
    }
};
