import CTA1 from '../../assets/Website_content/CTA1.webp'
import CTA2 from '../../assets/Website_content/CTA2.webp'
import CTA3 from '../../assets/Website_content/CTA3.webp'
import CTA1lg from '../../assets/Website_content/CTA1 lg.webp'
import CTA2lg from '../../assets/Website_content/CTA2 lg.webp'
import CTA3lg from '../../assets/Website_content/CTA3 lg.webp'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GymContext } from '../../hooks/FetchEverythingContext'

const CTA = ({ USR, scrollToGetInTouch, isLandingPage = false }) => {
    const {selectedCity} = useContext(GymContext);
    const navigate = useNavigate();
    const [isMobile] = useState(window.innerWidth <= 430);
    const [browser, setBrowser] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();

        if (userAgent.includes("chrome") && !userAgent.includes("edg")) {
        setBrowser("Google Chrome");
        } else if (userAgent.includes("firefox")) {
        setBrowser("Mozilla Firefox");
        } else if (userAgent.includes("safari") && !userAgent.includes("chrome")) {
        setBrowser("Safari");
        } else if (userAgent.includes("edg")) {
        setBrowser("Microsoft Edge");
        } else if (userAgent.includes("opera") || userAgent.includes("opr")) {
        setBrowser("Opera");
        } else if (userAgent.includes("msie") || userAgent.includes("trident")) {
        setBrowser("Internet Explorer");
        } else {
        setBrowser("Unknown Browser");
        }

        // Start loading all images
        const imageUrls = [isMobile ? CTA1 : CTA1lg, isMobile ? CTA2 : CTA2lg, isMobile ? CTA3 : CTA3lg];
        Promise.all(imageUrls.map(url => {
            return new Promise((resolve) => {
                const img = new Image();
                img.src = url;
                img.onload = resolve;
            });
        })).then(() => {
            setIsLoading(false);
        });
    }, [isMobile]);

    return (
        <>
            {isLoading && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-lightSecondary"></div>
                </div>
            )}
            <div className={`flex flex-col gap-8 mt-28 ${USR >= 1.5 ? 'lg:h-[31vw] lg:mt-20' : USR <= 1 ? 'lg:h-[25vw] lg:mt-28' : USR === 1.25 ? 'lg:h-[28vw] lg:mt-24' : 'lg:h-[26vw] lg:mt-24'}`}>
                {/* <div className={`font-bold text-3xl md:text-6xl ${USR >= 1.5 ? 'lg:text-4xl' : USR <= 1 ? 'lg:text-6xl' : USR === 1.25 ? 'lg:text-4xl' : 'lg:text-5xl'} text-tertiary text-center lg:text-left`}>
                    WHY WEIGHT FOR<br className={`lg:hidden`} /><span className='text-purple lg:ml-1'>NEW YEARS?</span>
                </div> */}
                
                <div className={`flex flex-col lg:flex-row lg:justify-between gap-8 w-full h-full`}>
                    {/* First Image */}
                    <div onClick={isLandingPage ? '' : () => navigate(`/${selectedCity}/gyms`)} className={`relative ${isLandingPage ? '' : 'cursor-pointer'} lg:w-[79%]`}>
                        <img src={isMobile ? CTA1 : CTA1lg} alt='one' className={`w-full h-40 ${isLandingPage ? 'lg:scale-x-90 lg:-ml-8 lg:h-[114%]' : ''} lg:h-full lg:min-w-full rounded-2xl lg:rounded-[45px] lg:border-4 lg:border-lightSecondary`} />
                        <div className={`absolute -bottom-4 left-8 text-[3.5vw] lg:left-24 ${USR >= 1.5 ? 'lg:text-3xl lg:-bottom-7' : USR <= 1 ? 'lg:text-4xl lg:-bottom-8' : USR === 1.25 ? 'lg:text-3xl' : 'lg:text-4xl lg:-bottom-8'} px-4 lg:px-8 py-2 rounded-full bg-lightSecondary text-primary font-[850] shadow-md flex gap-1 items-center`}>
                            GET 25% OFF
                        </div>
                        <div className={`absolute top-6 left-8 lg:left-20 lg:top-20 whitespace-nowrap font-semibold lg:font-bold text-tertiary text-[5.5vw] ${isLandingPage ? 'lg:text-6xl' : USR >= 1.5 ? 'lg:text-4xl' : USR <= 1 ? 'lg:text-[64px]' : USR === 1.25 ? 'lg:text-5xl' : 'lg:text-4xl'} text-shadow-sm lg:leading-[1.3]`}>
                            Kickstart your fitness journey,<br />SAVE BIG on selected gyms!
                        </div>
                    </div>

                    <div className={`${isLandingPage ? 'hidden' : 'flex'} lg:flex-col-reverse justify-between mt-2 lg:w-[35%]  lg:mt-0 lg:gap-8`}>
                        {/* Second Image */}
                        <div
                            onClick={scrollToGetInTouch}
                            className={`relative w-[45%] lg:w-full cursor-pointer`}
                        >
                            <img
                                src={isMobile ? CTA2 : CTA2lg}
                                alt="one"
                                className={`w-full h-[18.7vh] ${browser === "Safari" ? "lg:h-[13.5vw]" : "lg:h-fit"} lg:w-full rounded-2xl lg:rounded-[45px] lg:border-4 lg:border-lightSecondary`}
                            />
                            <div className={`absolute top-[85%] lg:top-[88%] right-2 lg:right-7 text-[3.4vw] lg:text-lg px-6 py-2 rounded-full bg-lightSecondary text-primary font-[850] shadow-md`}>
                                AVAIL NOW
                            </div>
                            <div className={`absolute top-6 left-4 lg:top-8 lg:left-7 font-bold lg:font-[800] text-lightSecondary text-2xl lg:text-4xl text-shadow-sm`}>
                                FREE
                            </div>
                            <div className={`absolute bottom-8 left-4 lg:bottom-6 lg:left-7 font-bold lg:font-[800] text-tertiary text-[4vw] text-shadow-sm leading-tight ${USR === 1.5 ? 'lg:text-xl' : USR > 1.5 ? 'lg:text-xl' : 'lg:text-2xl'}`}>
                                60 MIN FITNESS<br />CONSULTATION
                            </div>
                        </div>


                        {/* Third Image */}
                        <div onClick={() => navigate(`/${selectedCity}/gyms`)} className={`relative w-[45%] lg:w-full cursor-pointer`}>
                            <div className='absolute top-0 left-0 h-full w-full bg-primary rounded-2xl lg:rounded-[45px] bg-opacity-35 lg:hidden    '></div>
                            <img src={isMobile ? CTA3 : CTA3lg} alt='one' className={`w-full h-[18.7vh] ${browser === 'Safari' ? 'lg:h-[13.5vw]' : 'lg:h-fit'} lg:w-full rounded-2xl lg:rounded-[45px] lg:border-4 lg:border-lightSecondary`} />
                            <div className={`absolute top-5 -left-8  text-[3.4vw] lg:top-7 lg:-left-16 lg:text-2xl  ${USR >= 1.5 ? 'lg:top-7 lg:-left-16 lg:text-3xl' : USR <= 1 ? 'lg:top-7 lg:-left-16 lg:text-2xl' : USR === 1.25 ? 'lg:top-7 lg:-left-16 lg:text-xl' : 'lg:top-7 lg:-left-16 lg:text-xl'} px-8 py-2 rounded-full bg-lightSecondary text-primary font-[850] shadow-md`}>
                                GET NOW
                            </div>
                            <div className={`absolute bottom-7 left-4 lg:left-48 font-bold lg:font-[850] text-tertiary text-[4vw] leading-[6vw] lg:leading-8 ${USR >= 1.5 ? 'lg:text-xl' : USR <= 1 ? 'lg:text-3xl' : USR === 1.25 ? 'lg:text-3xl' : 'lg:text-2xl'} text-shadow-sm`}>
                                ONE DAY<br />PASS 
                                <span className={`pl-2  text-[8vw] ${USR >= 1.5 ? 'lg:text-xl' : USR <= 1 ? 'lg:text-5xl' : USR === 1.25 ? 'lg:text-4xl' : 'lg:text-2xl'} text-lightSecondary`}>
                                    at ₹0
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>   
    )
}

export default CTA;