// import TRLogo from '../TR_MainLogo.svg';

// export const signUpEmail = (userName) => `
//   <div style="max-width: 600px; margin: 0 auto; padding: 40px; font-family: Arial, sans-serif; color: #333;">
      
//       <!-- Logo -->
//       <div style="text-align: center; margin-bottom: 30px;">
//           <img src=${TRLogo} alt="Train Rex Logo" style="max-width: 150px;">
//       </div>

//       <!-- Greeting -->
//       <h2 style="text-align: center; color: #222; font-size: 24px; font-weight: bold; margin-bottom: 20px;">
//           Hi ${userName}, 

//           Welcome to Train Rex – Simplifying Fitness For You!
//       </h2>

//       <!-- Body Content -->
//       <p style="font-size: 16px; line-height: 1.6; text-align: justify;">
//           Welcome to <strong>Train Rex</strong>, where fitness meets convenience, flexibility, and exclusivity. 
//           You are now part of a platform that puts you in complete control of your membership, allowing you to train on your terms, 
//           at the best gyms, with no unnecessary restrictions.
//       </p>

//       <p style="font-size: 16px; line-height: 1.6; text-align: justify;">
//           We believe that fitness should fit into your life, not the other way around. Train Rex gives you access to 
//           <strong>elite gyms</strong>, <strong>personalized training options</strong>, and <strong>exclusive privileges</strong>, 
//           ensuring that you experience fitness at its highest standard—wherever and whenever it works for you.
//       </p>

//       <p style="font-size: 16px; line-height: 1.6; text-align: justify;">
//           <strong>You decide where you train. You decide how you train.</strong>  
//           We provide the platform that makes it effortless.
//       </p>

//       <!-- Footer -->
//       <div style="margin-top: 30px; text-align: center;">
//           <p style="font-size: 14px; color: #555;">For any assistance, our team is available at  
//               <a href="mailto:info@trainrex.org" style="color: #6a0dad; text-decoration: none;">info@trainrex.org</a>.
//           </p>
//       </div>

//       <div style="margin-top: 10px; text-align: center; font-size: 12px; color: #777;">
//           &copy; ${new Date().getFullYear()} Train Rex. All Rights Reserved.
//       </div>
//   </div>
// `;

export const signUpEmail = (userName) => {
    return`
Hi ${userName},

Welcome to Train Rex, where fitness meets convenience, flexibility, and exclusivity. You are now part of a platform that puts you in complete control of your membership, allowing you to train on your terms, at the best gyms, with no unnecessary restrictions.

We believe that fitness should fit into your life, not the other way around. Train Rex gives you access to elite gyms, personalized training options, and exclusive privileges, ensuring that you experience fitness at its highest standard—wherever and whenever it works for you.

You decide where you train. You decide how you train. We provide the platform that makes it effortless.

For any assistance, our team is available at info@trainrex.org.

The Train Rex Team`;
}