import React, { useEffect } from "react";

const SectionNav = ({ activeSection, handleScrollToSection, refs }) => {
    // Handle updating the active section based on scroll
    useEffect(() => {
        const handleScroll = () => {
            let currentSection = "";

            console.log(1)

            // Determine the currently visible section
            Object.keys(refs).forEach((section) => {
                const sectionRef = refs[section];
                if (sectionRef.current) {
                    const { top, bottom } = sectionRef.current.getBoundingClientRect();

                    if (top <= window.innerHeight / 2 && bottom >= window.innerHeight / 2) {
                        currentSection = section;
                    }
                }
            });

            // Update the active section if it changes
            if (currentSection && currentSection !== activeSection) {
                handleScrollToSection(null, currentSection);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [refs, activeSection, handleScrollToSection]);

    return (
        <nav className="sticky lg:top-24 top-[125px] z-10 bg-gradient-to-b from-[#AF9FCA] to-complementPurple text-white shadow-md border-b rounded-2xl">
            <div className="flex justify-around py-2 text-sm md:text-base relative">
                {["diet", "exercise", "recommendations"].map((section) => (
                    <button
                        key={section}
                        onClick={() => handleScrollToSection(refs[section], section)}
                        className={`relative ${activeSection === section ? "font-bold" : ""}`}
                    >
                        {section.charAt(0).toUpperCase() + section.slice(1)}
                        {activeSection === section && (
                            <div className="absolute -bottom-1 left-2 right-0 h-1 bg-lightSecondary rounded-full"></div>
                        )}
                    </button>
                ))}
            </div>
        </nav>
    );
};

export default SectionNav;
