import { useState } from 'react';
import { doc, updateDoc, collection, addDoc, increment, getDoc } from '@firebase/firestore'; // Firebase functions for database updates
import { db } from '../../config/firebase';
import { cityList, gymList, reviewList, studioList } from '../../components/Databases';
import Alert from '../../components/customComponents/Alert';

const ratingDescriptions = [
    '',
    'Bad',
    'Not Bad',
    'Average',
    'Good',
    'Awesome',
];

/**
 * AddReviewModal Component
 * 
 * This component displays a modal that allows users to submit a review for a specific gym.
 * The review includes the user's name (pre-filled), a rating, and a written review.
 * The review is saved to the Firebase Firestore database and updates the gym's total reviews 
 * and average rating accordingly.
 * 
 * @param {Function} setShowAddReviewModal - Function to control the visibility of the modal
 * @param {string} gymLocation          - The location of the gym being reviewed (used for Firestore path)
 * @param {string} gymName              - The name of the gym being reviewed (used for Firestore path)
 * @param {boolean} isRecentGymUser     - Boolean indicating if the user has recently used the gym (for verification)
 * @param {Object} user                 - user details
 * @param {boolean} isGymReview         - Boolean indicating if the review is of a gym or a studio
 */
const AddReviewModal = ({
    setShowAddReviewModal,
    gymLocation,
    gymName,
    isRecentGymUser,
    user,
    isGymReview
}) => {
    // Component State
    const [name, setName] = useState('');
    const [rating, setRating] = useState(0);
    const [reviewText, setReviewText] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [isAnonymous, setIsAnonymous] = useState(false); // State for anonymous review

    const handleStarClick = (value) => {
        setRating(value);
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        setSubmitting(true); // Set submission state to true to disable the submit button

        try {
            // Define the collection and document paths based on isGymReview
            const baseCollection = isGymReview ? gymList : studioList;
            const reviewFieldName = isGymReview ? "gymName" : "studioName";
            
            const gymReftoAddReview = collection(db, cityList, gymLocation, baseCollection, gymName, reviewList );
            const gymRefForReviewsCount = doc(db, cityList, gymLocation, baseCollection, gymName );
            
            if (rating < 1) {
                Alert("error", "Please give the gym a rating");
                setSubmitting(false); // Reset submission state if no rating is provided
                return;
            }
            
            // Add the review to the Firestore collection
            await addDoc(gymReftoAddReview, {
                userName            : user.displayName,
                rating              : rating,
                review              : reviewText,
                timestamp           : new Date().toISOString(), // Add timestamp for reference
                verified            : isRecentGymUser ? true : false, // Set 'verified' based on user
                [reviewFieldName]   : gymName, // Dynamically set the field name
                userID              : user.uid,
                isAnonymous         : isAnonymous,
            });
            
            // Update the total number of reviews and the sum of all ratings
            await updateDoc(gymRefForReviewsCount, {
                NoOfReview: increment(1),
                SumOfRatings: increment(rating),
            });
            
            // Fetch updated gym data to calculate the new average rating
            const gymSnapshot = await getDoc(gymRefForReviewsCount);
            if (gymSnapshot.exists()) {
                const gymData = gymSnapshot.data();
                const newNoOfReviews = gymData.NoOfReview;
                const newSumOfRatings = gymData.SumOfRatings;
                
                // Calculate new average rating
                const newAvgRating = newSumOfRatings / newNoOfReviews;
                
                // Update the gym's average rating in Firestore
                await updateDoc(gymRefForReviewsCount, {
                    rating: newAvgRating,
                });
            }
            
            Alert("success", "Review submitted successfully!"); // Show success message
            setShowAddReviewModal(false); // Close the modal on successful submission
        } catch (error) {
            // Handle error while adding the review
            console.error("Error adding review: ", error);
            Alert("error", "Something went wrong. Please try again.");
        }
        setSubmitting(false); // Reset submitting state after the operation is done
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
            <div className="bg-tertiary p-6 rounded-lg w-[90vw] md:w-[80vw] lg:w-1/3">
                <form onSubmit={handleSubmit}>
                    <h2 className="text-2xl font-bold mb-4 text-primary">Add a Review</h2>

                    <label className="block mb-2 text-secondary text-xl font-bold text-left">Your Name</label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="border-4 border-secondary rounded-xl w-full p-2 mb-4 placeholder:text-primary font-bold bg-secondary"
                        placeholder={isAnonymous ? 'Anonymous' : user.displayName} // Show 'Anonymous' if the checkbox is checked
                        readOnly // Make the input read-only
                        required
                    />

                    <label className="block mb-2 text-secondary text-xl font-bold text-left">Rating</label>
                    <div className="flex justify-around items-center space-x-2 mb-4 w-full">
                        {[1, 2, 3, 4, 5].map((star) => (
                            <div
                                key={star}
                                onClick={() => handleStarClick(star)}
                                className={`text-3xl cursor-pointer ${
                                    star <= rating ? 'text-secondary' : 'text-gray-300'
                                }`}
                            >
                                <div>★</div>
                                <div className="text-secondary text-base font-semibold">{ratingDescriptions[star]}</div>
                            </div>
                        ))}
                    </div>

                    <label className="block mb-2 text-secondary text-xl font-bold text-left">Review</label>
                    <textarea
                        value={reviewText}
                        onChange={(e) => setReviewText(e.target.value)}
                        className="border-4 border-secondary rounded-xl w-full p-2 mb-4 bg-tertiary"
                        placeholder="Write your review"
                        required
                    />

                    <div className="flex items-center mb-4">
                        <input
                            type="checkbox"
                            id="isAnonymous"
                            checked={isAnonymous}
                            onChange={() => setIsAnonymous(!isAnonymous)} // Toggle the anonymous state
                            className="mr-2"
                        />
                        <label htmlFor="isAnonymous" className="text-secondary text-lg font-semibold">
                            Submit anonymously
                        </label>
                    </div>

                    <div className="flex justify-end space-x-4">
                        <button
                            onClick={() => setShowAddReviewModal(false)}
                            className="bg-primary text-tertiary px-4 py-2 rounded-md"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="bg-secondary text-tertiary px-4 py-2 rounded-md"
                            disabled={submitting}
                        >
                            {submitting ? 'Submitting...' : 'Submit'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddReviewModal;
