import { addDoc, collection, doc, getDoc, increment, limit, orderBy, setDoc, Timestamp, updateDoc } from "@firebase/firestore";
import { db } from "../config/firebase";
import { convertDDMMYYYYToISOTimestamp } from "./basicHelper";
import { getDocs } from "firebase/firestore";
import { classReceipts, trialList, userGymReceipts } from "../components/Databases";
import { query, where } from "@firebase/firestore";
import { storeReceiptinDatabase } from "./gymDatabaseHelper";

/**
 * Stores data in 'user' database
 * @param {Object} myData - User data including city, gymName, months, userID
 * @param {Object} response - Response object from Razorpay containing paymentID, orderID, signature
 * @returns {boolean} - True if save successful, false otherwise
 */
export const storeUserData = async (myData, response) => {

    try {
        const myUserRef = doc(db, 'user', myData.user.uid);
        const userDoc = await getDoc(myUserRef);
        
        // If no such user with userID exists
        if (!userDoc.exists()) {
            console.error('No User Found');
            return { status: false, endDate: '' };
        }
        
        // storing the startDate and endDate
        let startDate = myData.startDate// Default to current date

        const receiptsRef = collection(db, 'user', myData.user.uid, userGymReceipts);

        // if user is buying membership for 1 day only then let them take the class of the selected date, 
        // no need to check for going memberships
        if(myData?.months !== 0) {
            // Query to get the document with the latest timestamp for the given gymEmail
            // and membership was for more than 1 day
            const lastReceiptQuery = query(
                receiptsRef,
                where("gymEmail", "==", myData?.gymEmail), // Match gymEmail
                where("months", ">=", 1),                  // Ensure months is greater than or equal to 1
                orderBy("timestamp", "desc"),             // Order by timestamp in descending order
                limit(1)                                  // Limit the result to one document
            );
            
            // Execute the query
            const querySnapshot = await getDocs(lastReceiptQuery);
            
            // setting the startDate
            startDate = getStartDate(querySnapshot, startDate, myData?.gymEmail)
        }
        
        // Calculate endDate by adding months to startDate
        // if months are 0 (1 day membership), start date and end date should be same
        const endDate = myData?.months === 0 ? startDate : addMonthsToISODate(startDate, myData.months);

        
        // Update user doc
        // const userData = userDoc.data();

        // if(userData?.gymName === myData?.gymName) {
        //     if (userData?.membershipTill) {
        //         const membershipTillISO = !isNaN(Date.parse(userData.membershipTill))
        //         ? userData.membershipTill // Already in ISO format
        //         : convertDDMMYYYYToISOTimestamp(userData.membershipTill); // Convert from DD-MM-YYYY to ISO
        //         const startDateISO = startDate;
                
        //         // Use the later of the two dates
        //         startDate = membershipTillISO > startDateISO
        //         ? membershipTillISO
        //         : startDateISO;
        //     }
        // } else {
        //     startDate = myData?.startDate;
        // }
        
        // Add receipt to user -> {userID} -> gymMembershipReceipts collection
        // const gymMembershipRef = collection(db, 'user', myData.user.uid, 'gymMembershipReceipts');
        
        await storeReceiptinDatabase(receiptsRef, {
            ...myData,
            startDate   :   startDate,
            endDate     :   endDate,
        }, response.razorpay_payment_id);
        
        const userDocData = {
            // membershipTill  : endDate,
            gymCity         : myData.city,
            // Round to the nearest integer
            // Also deduct the amount of trex used
            trexCredits     : increment(
                - myData.trex 
                + Math.ceil(myData.amount * 0.0001)
            ),   // Automatically increments from 0, if undefined.
            // Add a gymMembershipID if necessary
        };
        
        // Conditionally add gymName if orderType is not 'studio'
        if (myData?.orderType !== 'studio') {
            userDocData.gymName = myData?.gymName;
        }
        
        // Update the document
        await setDoc(myUserRef, userDocData, { merge: true });

        // await setDoc(myUserRef, {
        //     membershipTill  : endDate,
        //     gymName         : myData.gymName,
        //     gymCity         : myData.city,
        //     // Round to the nearest integer
        //     // Also deduct the amount of trex used
        //     trexCredits     : increment(
        //         - myData.trex
        //         + (Math.ceil(myData.amount * 0.0001))
        //     )    // Automatically increments from 0, if undefined.
        //     // Add a gymMembershipID if necessary
        // }, { merge: true });
        
        return {
            status      : true,
            endDate     : endDate,
            startDate   : startDate,
        };

    } catch (e) {
        // console.log('Error in Store User Data : ', e);
        return { status: false, endDate: '', errorMessage : e };
    }
};

// Helper function to convert date strings of format 'DD-MM-YYYY' to a Date object
export const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('-');
    return new Date(`${year}-${month}-${day}`);
};

// Helper function to convert date strings of format 'DD-MM-YYYY' to a ISO Format timestamp
export const parseDateToISOFormat = (DDMMYYYY) => {
    const [day, month, year] = DDMMYYYY.split('-').map(Number);
    const dateObject = new Date(Date.UTC(year, month - 1, day));
    return dateObject.toISOString(); // Convert to ISO 8601 format
};


/**
 * Adds months to a given date
 * @param {string} dateStr - The date string in YYYY-MM-DD or DD-MM-YYYY format (or undefined)
 * @param {number} months - The number of months to add
 * @returns {string} - The new date string in DD-MM-YYYY format
 */
export const addMonthsToDate = (dateStr, months) => {
    let date;

    // Check if dateStr is undefined or not a valid date
    if (!dateStr || isNaN(Date.parse(convertToISOFormat(dateStr)))) {
        date = new Date(); // Use today's date if dateStr is undefined or invalid
    } else {
        date = new Date(convertToISOFormat(dateStr));
    }

    // Calculate the new month and year
    const newMonth = date.getMonth() + months;
    date.setMonth(newMonth);

    // Handle month overflow
    if (date.getMonth() !== (newMonth % 12)) {
        date.setDate(0);
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${day}-${month}-${year}`;
};

// converts date string to ISO format timestamp
export const addMonthsToISODate = (isoDate, months) => {
    const date = new Date(isoDate);

    if (months === 0) {
        // If months is 0, increment the date by 1 day
        date.setDate(date.getDate() + 1);
    } else {
        // Add the specified number of months
        date.setMonth(date.getMonth() + months);

        // Handle overflow (e.g., adding months to 31st)
        if (date.getDate() < new Date(isoDate).getDate()) {
            date.setDate(0); // Go back to the last valid date
        }
    }

    return date.toISOString();
};


/**
 * Converts a date string in DD-MM-YYYY format to YYYY-MM-DD format
 * @param {string} dateStr - The date string in DD-MM-YYYY format
 * @returns {string} - The date string in YYYY-MM-DD format
 */
const convertToISOFormat = (dateStr) => {
    const [day, month, year] = dateStr.split('-');
    return `${year}-${month}-${day}`;
};

// Function to retrieve user profile data from Firestore using a user ID
export const getUserProfile = async (userID) => {
    // Check if a user ID is provided; if not, throw an error
    if (!userID) {
        throw new Error('No user ID provided');
    }

    try {
        // Reference the Firestore document for the user based on the provided user ID
        const userRef = doc(db, 'user', userID);

        // Get the document snapshot from Firestore
        const userDoc = await getDoc(userRef);

        // Check if the document exists; if not, log an error and return null
        if (!userDoc.exists()) {
            console.error('User document does not exist.');
            return null;
        }

        // Return the data of the user document
        return userDoc.data();
    } catch (error) {
        // Log any errors that occur during the retrieval process
        console.error('Error retrieving user data:', error);
        // Rethrow the error to be handled by the calling function
        throw error;
    }
};


// Function to retrieve gym membership receipts for a specific user from Firestore
export const getUserMemberShipReceipts = async (userID) => {
    try {
        // Reference to the collection of gym membership receipts for the given user
        const receiptsRef = collection(db, 'user', userID, userGymReceipts);

        // Fetch the documents from the receipts collection
        const receiptsSnapshot = await getDocs(receiptsRef);

        // Map over the documents to extract their data and include document IDs
        const receipts = receiptsSnapshot.docs.map(doc => ({
            id: doc.id,         // Store the document ID
            ...doc.data()       // Spread the document data into the resulting object
        }));

        // Return the array of receipts
        return receipts;
    } catch (error) {
        return [];
    }
}

/**
 * Function to retrieve all the trial receipts of the user
 * 
 * @param {String} userID   -   uid
 * @returns {Object}        -   list of receipts
 */
export const getUserTrialReceipts = async (userID) => {
    try {
        // Reference to the collection of gym membership receipts for the given user
        const receiptsRef = collection(db, 'user', userID, trialList);

        // Fetch the documents from the receipts collection
        const receiptsSnapshot = await getDocs(receiptsRef);

        // Map over the documents to extract their data and include document IDs
        const receipts = receiptsSnapshot.docs.map(doc => ({
            id: doc.id,         // Store the document ID
            ...doc.data(),      // Spread the document data into the resulting object
            gymName     :   doc.data().gymDisplayName,
            startDate   :   doc.data().trialDate,
            amount      :   0,
            endDate     :   doc.data().trialDate,
        }));

        // Return the array of receipts
        return receipts;

    } catch (error) {
        return [];
    }
}

/**
 * Function to retrieve all the trial receipts of the user
 * 
 * @param {String} userID   -   uid
 * @returns {Object}        -   list of receipts
 */
export const getUserGymClassReceipts = async (userID) => {
    try {
        // Reference to the collection of gym membership receipts for the given user
        const receiptsRef = collection(db, 'user', userID, classReceipts);

        // Fetch the documents from the receipts collection
        const receiptsSnapshot = await getDocs(receiptsRef);

        // Map over the documents to extract their data and include document IDs
        const receipts = receiptsSnapshot.docs.map(doc => ({
            id: doc.id,         // Store the document ID
            ...doc.data(),      // Spread the document data into the resulting object
            gymName     :   doc.data().gymDisplayName || doc.data().gymName,
            startDate   :   doc.data().classDate,
            amount      :   0,
            endDate     :   doc.data().classDate,
        }));

        // Return the array of receipts
        return receipts;

    } catch (error) {
        return [];
    }
}

/**
 * Checks if a user exists in the database based on a specified credential.
 *
 * @param   {String}        userCred    - The user's credential to search by (e.g., email).
 * @param   {String}        typeOfMatch - The type of credential to match with (default: 'email').
 * @returns {Object|null}               - Returns user information if the user exists; otherwise, returns null.
 */
export const checkIfUserExists = async (userCred, typeOfMatch = 'email') => {
    try {
        const userRef       =   collection(db, 'user');  // Reference to the 'user' collection in Firestore
        const userQuery     =   query(userRef, where(typeOfMatch, '==', userCred), limit(1));  // Query to match the credential
        const userDocs      =   await getDocs(userQuery);  // Execute the query

        if (!userDocs.empty) {
            const doc = userDocs.docs[0];  // Get the first document in the snapshot
            const userData = doc.data();   // Retrieve user data from the document

            return {
                profilePic  :   userData.profilePic || null,
                age         :   userData.age,
                gender      :   userData.gender,
                phoneNumber :   userData.phoneNumber,
                name        :   userData.name,
                displayName :   userData.name,
                uid         :   doc.id,  // Access the document ID correctly
            };
        }
    } catch (error) {
        console.error('Error verifying if user exists:', error);
    }
    
    return null;  // Return null if no user is found or an error occurs
};

/**
 * Add newly created user to database
 * @param   {String} userID     -   ID of the user
 * @param   {Object} userInfo   -   The other details of the user signing in 
 * 
 * @return {Boolean}            -   Ture, if save successful; false, otherwise
 */
export const addNewUserToDatabase = async(userID, userInfo) => {
    try {
        const userRef = doc(db, 'user', userID);
        await setDoc(userRef, {
            ...userInfo,
            trexCredits     :   trexToGiveNewUsers(),
            joined          :   Timestamp.now(),
            ageRange        :   [18, 32],
        })
        return true;
    } catch (error) {
        return false;
    }
}

/**
 * Calculates age based on the provided date of birth (DOB).
 * 
 * @param {string} dob - The date of birth in 'DD-MM-YYYY' format.
 * @returns {number} - The calculated age.
 */
export const calculateAge = (dob) => {
    // Split the DOB string to extract day, month, and year
    const [day, month, year] = dob.split('-').map(Number);
    
    // Create a date object from the extracted values
    const dobDate = new Date(year, month - 1, day);  // Month is 0-indexed in JS (Jan = 0)
    const today = new Date();
    
    // Calculate the difference in years
    let age = today.getFullYear() - dobDate.getFullYear();
    
    // Adjust the age if the birthday hasn't occurred yet this year
    const hasBirthdayPassed = (
        today.getMonth() > dobDate.getMonth() || 
        (today.getMonth() === dobDate.getMonth() && today.getDate() >= dobDate.getDate())
    );
    
    if (!hasBirthdayPassed) {
        age--;
    }
    
    return age;
};


/**
 * Stores the class receipts in the user database.
 * @param   {Object} myData         -   Payment Data
 * @param   {Object} user           -   logged in user information
 * @param   {Object} response       -   razorpay information
 * @returns {Object}                -   status : true/false
 */
export const userClassReceipts = async (myData, response) => {
    try {
        const gymMembershipRef = collection(db, 'user', myData.user.uid, classReceipts);
        await addDoc(gymMembershipRef, {
            timestamp       : new Date().toISOString(),
            paymentID       : response.razorpay_payment_id                  || "Self",
            orderType       : myData?.orderType                             || 'class',
            months          : myData?.months                                || 0,
            gymName         : myData?.gymName,
            gymDisplayName  : myData?.gymDisplayName,
            gymContactNo    : myData?.gymContactNo,
            gymEmail        : myData?.gymEmail,
            city            : myData?.city,
            cityDisplayName : myData?.cityDisplayName,
            address         : myData?.address,
            amount          : myData?.amount,
            className       : myData?.className                             || null,
            classSchedule   : myData?.classSchedule                         || null,
            days            : myData?.days                                  || null,
            classTime       : myData?.classTime                             || null,
            classDate       : new Date(myData?.selectedDate).toISOString()  || null,
            startDate       : new Date(myData?.selectedDate).toISOString(),
            endDate         : new Date(myData?.selectedDate).toISOString(),
            user                :   
            myData.user ? {
                displayName     :   myData.user.displayName,
                phoneNumber     :   myData.user.phoneNumber,
                email           :   myData.user.email,
                gender          :   myData.user.gender,
            } : null,
        });

        const userRef = doc(db, 'user', myData.user.uid);
        await setDoc(userRef, {
            trexCredits: increment(
                - myData.trex
                + (Math.ceil(myData.amount * 0.0001))
            )    // Automatically increments from 0, if undefined.
        }, {merge : true});

        return {
            status : true,
        };
    } catch (error) {
        // console.log('error in userClassReceipts : ', error);
        return {
            status : false
        };
    }
}

/**
 * checks if the user is buying the membership of the same gym or studio
 * if yes, then start date is set to the next date of the end date if the membership is still active otherwise user chosen start date
 * if no, then returns start date only
 * @param {query result} querySnapshot - the result of the query we ran to find the receipt with the gymEmail === myData.gymEmail with latest timestamp
 * @param {string} startDate           - ISO format, initailly it is set to the selected start date by user
 * @param {string} email               - gym or studio's email 
 * @returns correct start date of the membership in ISO format
 */
export const getStartDate = (querySnapshot, startDate, email) => {
    if (!querySnapshot.empty) {
        const lastReceiptDoc = querySnapshot.docs[0];
        const lastReceiptData = lastReceiptDoc.data();

        if(lastReceiptData?.gymEmail === email) {
            if (lastReceiptData?.endDate) {
                const endDateISO = !isNaN(Date.parse(lastReceiptData.endDate))
                ? lastReceiptData.endDate // Already in ISO format
                : convertDDMMYYYYToISOTimestamp(lastReceiptData.endDate); // Convert from DD-MM-YYYY to ISO
                const startDateISO = startDate;
                
                // Use the later of the two dates  // passed months as 0 to increase start date by 1 day
                return endDateISO > startDateISO ? addMonthsToISODate(endDateISO, 0) : startDateISO;
            } else {
                return startDate;
            }
        }
    } else {
        return startDate;
    }
}

const getTrexToUse = () => {
    return 10;
}

/**
 * takes the user ID and decrement the trex by 10 if the user 
 * @param {string} userID - userID
 */
export const usedTrexForPlanRegeneration = async (userID) => {
    try {
      const userRef = doc(db, 'user', userID);
      const userDoc = await getDoc(userRef);
  
      if (userDoc.exists()) {
        const currentTrex = userDoc.data().trexCredits || 0;
        const updatedTrex = currentTrex - getTrexToUse();
  
        await updateDoc(userRef, { trexCredits: updatedTrex });
  
        // console.log(`Trex successfully updated to ${updatedTrex}`);
      } else {
        // console.log("User document does not exist.");
      }
    } catch (error) {
      console.error("Error updating Trex:", error);
    }
};

/**
 * @returns {Number}    -   the trex to be added to the db of new users
 * 
 */
const trexToGiveNewUsers = () => {
    return 100;
}

/*
 * Checks if user has a plan and returns user plan 
 * @param {String} userId - user's ID
 */
export const checkIfUserHasPlan = async (userId) => {
    try {
        const userDoc = await getDoc(doc(db, "user", userId, "plan", "details"));
        if (userDoc.exists()) {
            return userDoc.data().userDetails;
        } else {
            console.warn("No user details found.");
            return null;
        }
    } catch (error) {
        console.error("Error fetching user details:", error);
    }
}


/**
 * Adds 5 recently viewed gyms by user to user doc in the database
 * @param {string} userId  - user id
 * @param {object} gymData - entire gym data
 */
export const updateRecentlyViewedGymsByUser = async (userId, gymData) => {
    if(!userId || !gymData) return;
    const maxGyms = 5;

    try {
        const userDocRef = doc(db, 'user', userId);
        const userDocSnap = await getDoc(userDocRef);

        let recentlyViewedGyms = [];
        if (userDocSnap.exists()) {
            recentlyViewedGyms = userDocSnap.data()?.recentlyViewedGyms || [];
        }

        // Check if the gym already exists in the list by matching a unique identifier
        const gymExists = recentlyViewedGyms.some(gym => gym.gymName === gymData.gymName);
        if (gymExists) return;

        // Add the new gym with a timestamp
        const newGymData = { ...gymData, timestamp: new Date().toISOString() };

        // Maintain FIFO structure
        if (recentlyViewedGyms.length >= maxGyms) {
            recentlyViewedGyms.shift(); // Remove the oldest entry
        }
        recentlyViewedGyms.push(newGymData);

        // Update Firestore with the new list
        await setDoc(userDocRef, { recentlyViewedGyms }, { merge: true });

    } catch (error) {
        console.error("Error updating recently viewed gyms: ", error);
    }
}

/**
 * updates the user's missing necessary details in the database
 * @param {string} userId       - user's id
 * @param {object} userDetails  - phoneNumber, email, gender, name 
 */
export const updateUserDetails = async (userId, userDetails) => {
    try {
        // console.log("USERID ; ", userId)
        // console.log("Details : ", userDetails)
        const userRef = doc(db, "user", userId);
        await updateDoc(userRef, {
            ...userDetails,
            isUpdated   :   false,
        });
        console.log("User details updated successfully!");
    } catch (error) {
        console.error("Error updating user details:", error);
    }
};