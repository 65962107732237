import { useEffect, useState } from "react";
import { availOfferInPrices, getSellingPrice } from "../../helperFunctions/MarketplaceHelpers/gymDataHelpers/PriceHelpers";
import { getCurrencyToDisplay } from "../../helperFunctions/PaymentHelpers";

const planName = {
    1: 'single',
    2: 'couple',
    3: 'family',
};

/**
  * Displays membership prices of the gym
  * @param {object}   currGymData            -   contains all the information about the gym  
  * @param {function} toggleSummaryModal     -   function to open the modal where we can add coupons, referral code and make payment
  * @param {function} setSelectedCategory    -   to set the category as single, couple, family (maybe unnecessary but currently needed for PaymentConfirmationModal)
  * @param {Object} offer                    -   The special Offer from the gym
  * @param {Object} userGender               -   Gender of the user; for offer purposes
  * @returns {JSX.component} component displaying membership prices
  */
const GymMembershipPrices = ({ USR, currGymData, toggleSummaryModal, setSelectedCategory, offer, userGender }) => {
    const [planType, setPlanType] = useState(1); // 1 is single, 2 is couple, 3 is family
    const [prices, setPrices] = useState({});

    const hasCouple = currGymData ? currGymData?.prices?.couple && hasData(currGymData?.prices?.couple) : false;
    const hasFamily = currGymData ? currGymData?.prices?.family && hasData(currGymData?.prices?.family) : false;

    useEffect(() => {
        if (offer) {
            const tempPrices = availOfferInPrices(currGymData.prices, offer, userGender);
            setPrices(tempPrices);
        } else {
            setPrices(currGymData.prices);
        }
    }, [currGymData, offer, userGender]);

    useEffect(() => {
        setSelectedCategory(planName[planType]);
    }, [planType, setSelectedCategory]);

    return (
        <>
            <div className={`relative w-full h-fit  text-lg flex justify-center items-end mt-8 lg:mt-16`}>
                {prices?.single && (
                    <Prices 
                        USR={USR}
                        currGymData={{
                            country             : currGymData.country,
                            isExclusive         : currGymData.isExclusive,
                            gymName             : currGymData.gymName,
                            city                : currGymData.city,
                            commissionPercentage: currGymData.commissionPercentage,
                            freeTrial           : currGymData.freeTrial
                        }}
                        prices={prices}
                        planType={planType}
                        setPlanType={setPlanType}
                        hasCouple={hasCouple}
                        hasFamily={hasFamily}
                        toggleSummaryModal={toggleSummaryModal}
                    />
                )}

                <div className={`z-[2] absolute -top-3 lg:-top-2 left-8 rounded-2xl h-[10vh] w-[84%] ${USR >= 1.5 ? 'lg:left-28' : 'lg:left-28'} rotate-6 lg:rotate-[4deg] bg-[#e98c84]`}></div>
                {hasFamily && <div className={`z-[1] absolute -top-3 lg:-top-2 left-8 rounded-2xl h-[10vh] w-[84%] ${USR >= 1.5 ? 'lg:left-28' : 'lg:left-28'} -rotate-6 lg:-rotate-[4deg] bg-lightSecondary`}></div>}

                <div className={`absolute bottom-0 left-[45%]  lg:left-[48.5%] flex gap-2`}> 
                    {(hasCouple || hasFamily) && <div className={`h-2 w-2 rounded-full ${planType === 1 ? 'bg-primary' : 'bg-secondary'}`}></div>}
                    {hasCouple && <div className={`h-2 w-2 rounded-full ${planType === 2 ? 'bg-primary' : 'bg-secondary'}`}></div>}
                    {hasFamily && <div className={`h-2 w-2 rounded-full ${planType === 3 ? 'bg-primary' : 'bg-secondary'}`}></div>}
                </div>
            </div>
        </>
    );
};

export default GymMembershipPrices;

const Prices = ({ USR, currGymData, prices, planType, setPlanType, hasCouple, hasFamily, toggleSummaryModal }) => {
    return (
        <>
            <div className={`z-[3] rounded-2xl shadow-lightSecondary shadow-lg h-fit w-full bg-[#ffffff] px-6 py-4`}>
                <div className={`flex flex-col`}>
                    <div className={`flex justify-between items-center`}>
                        <div className={`font-semibold text-2xl`}>Membership Plan</div>
                        <button 
                            onClick={() => {
                                if (planType === 1) {
                                    setPlanType(2);
                                } else if (planType === 2) {
                                    if (hasFamily) setPlanType(3);
                                    else setPlanType(1);
                                } else if (planType === 3) {
                                    setPlanType(1);
                                }
                            }}
                            className={`px-4 py- bg-lightSecondary text-primary font-semibold text-xs rounded-full h-8 flex justify-center items-center `}
                        >
                            {planType === 1 ? 'Single' : planType === 2 ? 'Couple' : 'Family'} 
                            <span className={`${hasFamily ? "" : "hidden"} font-semibold text-xl pl-1`}> {" "}&gt;</span>
                        </button>
                    </div>

                    {console.log("AAAA : ", currGymData)}
                    <div className={`w-full flex flex-col mt-8`}>
                        {[currGymData.freeTrial === true && { duration: "Book A Free Trial", key: "freeTrial", months: 0 },
                            { duration: "1 Day", key: "dailyPrice", months: 0 },
                            { duration: "1 Month", key: "monthlyPrice", months: 1 },
                            { duration: "3 Months", key: "quarterlyPrice", months: 3 },
                            { duration: "6 Months", key: "halfYearlyPrice", months: 6 },
                            { duration: "12 Months", key: "yearlyPrice", months: 12 },
                        ]
                            .filter(Boolean)
                            .filter(({ key }) => {
                                const currentPlanKey = planName[planType];
                                const fallbackPlanKey = 'single';
                                const exclusiveKey = `exclusive${key.charAt(0).toUpperCase() + key.slice(1)}`;

                                const price = getSellingPrice(
                                    prices?.[currentPlanKey]?.[exclusiveKey] || prices?.[fallbackPlanKey]?.[exclusiveKey],
                                    currGymData?.isExclusive,
                                    currGymData?.gymName,
                                    currGymData?.city
                                );

                                if (key === "dailyPrice") {
                                    return price >= 100;
                                }

                                return price === undefined || price > 0 || (key === 'freeTrial' && currGymData?.freeTrial);
                            })
                            .map(({ duration, key, months }) => {
                                const currentPlanKey = planName[planType];
                                const fallbackPlanKey = 'single';
                                const mrp = prices?.[currentPlanKey]?.[key] || prices?.[fallbackPlanKey]?.[key];
                                const exclusiveKey = `exclusive${key.charAt(0).toUpperCase() + key.slice(1)}`;
                                const price = getSellingPrice(prices?.[currentPlanKey]?.[exclusiveKey] || prices?.[fallbackPlanKey]?.[exclusiveKey], currGymData?.isExclusive, currGymData?.gymName, currGymData?.city);
                                const currency = getCurrencyToDisplay(currGymData?.country);
                                const priceToPayGym = currGymData?.isExclusive === false
                                    ? currGymData.commissionPercentage
                                        ? prices?.[currentPlanKey]?.[exclusiveKey] * ((100 - currGymData.commissionPercentage) / 100) || prices?.[fallbackPlanKey]?.[exclusiveKey] * ((100 - currGymData.commissionPercentage) / 100)
                                        : prices?.[currentPlanKey]?.[exclusiveKey] * ((100 - 20) / 100) || prices?.[fallbackPlanKey]?.[exclusiveKey] * ((100 - 20) / 100)
                                    : prices?.[currentPlanKey]?.[exclusiveKey] || prices?.[fallbackPlanKey]?.[exclusiveKey];

                                return (
                                    <div key={key} className="flex flex-col mt-4">
                                        <div className="flex justify-between items-center">
                                            <div className={`font-semibold text-lg ${USR >= 1.5 ? 'lg:text-sm' : USR === 1 ? 'lg:text-xl' : USR === 1.25 ? 'lg:text-base' : ''} whitespace-nowrap`}>{duration}</div>

                                            <div className={`flex w-[60%] justify-end gap-2 text-lg ${USR >= 1.5 ? 'lg:text-sm' : USR === 1 ? 'lg:text-xl' : USR === 1.25 ? 'lg:text-sm' : 'lg:text-base'}`}>
                                                {price ? (
                                                    <div className={`line-through text-red bg-transparent scale-90 whitespace-nowrap mt-1`}>
                                                        {price ? `${currency}${mrp}` : `${currency}1`}
                                                    </div>
                                                ) : key === 'freeTrial' ? (
                                                    <div className={`line-through text-red bg-transparent scale-90 whitespace-nowrap mt-1`}>
                                                        {currency}1
                                                    </div>
                                                ) : null}
                                                <button
                                                    onClick={() => key === 'freeTrial' ? toggleSummaryModal(months, 0, 1, currentPlanKey, 0) : toggleSummaryModal(months, price, mrp, currentPlanKey, priceToPayGym)}
                                                    disabled={key === 'freeTrial' ? false : !price}
                                                    className={`${planType !== 2 ? 'bg-lightSecondary' : 'bg-[#e98c84] text-white'} relative rounded-full py-1 ${key === 'freeTrial' || price ? 'w-[45%]' : 'w-full lg:w-[45%]'}`}
                                                >
                                                    {key === 'freeTrial' ? `${currency}0` : price ? `${currency}${price}` : "No Price Available"}
                                                    {/* <div className={`${planType === 2 ? '' : 'hidden'} absolute -top-3 right-2 lg:-top-2 lg:right-5 rotate-[30deg]`}>🎀</div> */}
                                                </button>
                                            </div>
                                        </div>
                                        <hr className="text-secondary mt-2" />
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </>
    );
};

function hasData(prices) {
    return Object.values(prices).some(value => value !== "");
}
