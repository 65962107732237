import { useEffect, useState } from "react";
import useAuthStatus from "../../../hooks/clientAuth";
import LoadingSpinner from "../../LoadingSpinner";
import { addQuestionToDB } from "../../../helperFunctions/DiscussionForumHelpers/QuestionHelper";
import { addForumTag } from "../../../helperFunctions/DiscussionForumHelpers/ForumDatabaseHelpers";
import { getAllTags } from "../../../helperFunctions/DiscussionForumHelpers/GetAllTags";
import { useNavigate } from "react-router-dom";
import Alert from "../../customComponents/Alert";
import { removeSpecialCharacters } from "../../../helperFunctions/basicHelper";

/**
 * 
 * @param {function} setAddQuestionModal - to manage the visiblilty of the modal 
 * @returns a modal to add a forum
 */
const AddQuestion = ({ setAddQuestionModal, stateQuestion = '' }) => {
    const [tags         ,        setTags] = useState([]);
    const [loading      ,     setLoading] = useState(false);
    const [question     ,    setQuestion] = useState(stateQuestion || '');    // State to hold the question input
    const [customTag    ,   setCustomTag] = useState('');
    const [searchQuery  , setSearchQuery] = useState(""); // For the search bar input
    const [description  , setDescription] = useState(""); // State to hold the description input
    const [isAnonymous  , setIsAnonymous] = useState(false); // State to hold the checkbox value
    const [imageBase64  , setImageBase64] = useState(null);  // State to hold Base64 image string
    const [selectedTags ,setSelectedTags] = useState([]); // Tags selected by the user

    const { user } = useAuthStatus();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTags = async () => {
            const data = await getAllTags();
            setTags(data);
        }

        fetchTags();
    }, [])


    // Function to handle image input
    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onloadend = () => {
            setImageBase64(reader.result); // Convert image to Base64
        };
        reader.readAsDataURL(file);
    };

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent page reload
        setLoading(true);

        if(!user) {
            Alert('error', "Please login to submit your question")
            return ;
        }
    
        // Basic validation
        if (question.trim() === "") {
            Alert('info', "Please enter a valid question.");
            setLoading(false);
            return;
        }
    
        if (selectedTags?.length === 0) {
            Alert('error', "Please select at least one tag.");
            setLoading(false);
            return;
        }
    
        // Prepare payload
        const payload = {
            question,
            description,
            user,
            isAnonymous,
            image: imageBase64,
            tags: selectedTags.map((tag) => tag.tag), // Extract tag identifiers
        };
    
        const response = await addQuestionToDB(payload);
    
        if (response.success) {
            Alert('success', "Question added successfully!");
    
            // Reset the form
            setQuestion("");
            setDescription("");
            setIsAnonymous(false);
            setImageBase64(null);
            setSelectedTags([]); // Clear selected tags
            setLoading(false);
    
            // Close the modal
            setAddQuestionModal(false);
    
            navigate(`/forum/${removeSpecialCharacters(question)}`, {state : response.questionDoc}); // Redirect to the forum page
        } else {
            Alert('error', "There was an error submitting your question. Please try again later!");
            setAddQuestionModal(false);
            setLoading(false);
        }
    };

    // Filter the top 5 most-used tags
    const topTags = tags
        .slice()
        .sort((a, b) => b.usedCount - a.usedCount) // Sort by usedCount
        .slice(0, 5);

    // Filter tags based on the search query
    const filteredTags = tags.filter(
        (tag) =>
            tag.tag.toLowerCase().includes(searchQuery.toLowerCase()) &&
            !selectedTags.some((selected) => selected.tag === tag.tag) // Exclude already selected tags
    );

    // Add or remove tags when clicked
    const toggleTagSelection = (tag) => {
        if (selectedTags.some((selected) => selected.tag === tag.tag)) {
            setSelectedTags(selectedTags.filter((selected) => selected.tag !== tag.tag)); // Remove if already selected
            setSearchQuery("")
        } else {
            setSelectedTags([...selectedTags, tag]); // Add new tag
            setSearchQuery("")
        }
    };

    const handleCustomTag = async (e) => {
        let tagToStore = customTag.trim();
        e.preventDefault(); // Prevent form from reloading the page
        if (tagToStore === '') {
            Alert('error', 'Please enter a tag name before submitting.');
            return;
        }
    
        const success = await addForumTag(
            tagToStore[0] === '#'
            ? tagToStore.substring(1)
            : tagToStore
        );
        if (success) {
            Alert('success', "Tag added successfully!");
    
            // Add the new custom tag to the selectedTags state
            const newTag = { tag: customTag.trim(), usedCount: 0 };
            setSelectedTags([...selectedTags, newTag]);
    
            setCustomTag(""); // Reset input field
        } else {
            Alert("Failed to add tag. Please try again.");
        }
    };

    return (
        <div className={`h-[100vh] w-[100vw] fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center`}>
            <form
                onSubmit={handleSubmit}
                className={`relative h-auto w-[95vw] lg:w-[40vw] ${loading ? "z-40" : "z-50"} ${window.location.href.includes('forum') ? '' : 'mt-20 scale-[95%]'} bg-lightSecondary p-6 rounded-2xl flex flex-col justify-between gap-4`}
            >
                <button
                    type="button"
                    onClick={() => setAddQuestionModal(false)}
                    className="absolute top-4 right-4 h-7 w-7 lg:h-10 lg:w-10 bg-secondary text-tertiary rounded-xl text-base md:text-lg hover:shadow-xl hover:scale-110 transition-all duration-500"
                >
                    X
                </button>
                {/* Question Section */}
                <div>
                    <label
                        htmlFor="question"
                        className="text-left text-primary text-xl md:text-3xl mb-2 block"
                    >
                        Your Question?
                    </label>
                    <textarea
                        id="question"
                        name="question"
                        value={question}
                        required
                        onChange={(e) => setQuestion(e.target.value)}
                        className="h-20 w-full rounded-2xl border-primary p-4 align-text-top"
                        placeholder="Write your question here"
                    />
                </div>
    
                {/* Description Section */}
                <div>
                    <label
                        htmlFor="description"
                        className="text-left text-primary text-lg mb-2 block"
                    >
                        Description
                    </label>
                    <textarea
                        id="description"
                        name="description"
                        value={description}
                        required
                        onChange={(e) => setDescription(e.target.value)}
                        className={`${window.location.href.includes('forum') ? 'h-40' : 'h-28'} w-full rounded-2xl border-primary p-4 align-text-top`}
                        placeholder="Describe the problem and what things you did trying to fix it"
                    />
                </div>
    
                {/* Image Upload Section */}
                <div className="flex flex-col md:flex-row gap-2">
                    <label
                        htmlFor="image"
                        className="text-left text-primary md:text-lg w-fit whitespace-nowrap"
                    >
                        Upload Image (optional)
                    </label>
                    <input
                        type="file"
                        id="image"
                        accept="image/*"
                        onChange={handleImageUpload}
                        className="block w-full text-sm text-tertiary file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-tertiary file:text-secondary file:cursor-pointer"
                    />
                </div>
    
                {/* Tag Selection Section */}
                <div className="flex relative justify-between">
                    <div className="flex flex-col items-start">
                        <label className="font-bold text-primary text-lg mb-2 text-left">Select Tags:</label>
                        <div className="flex gap-2 mb-2">
                            {/* Search Bar */}
                            <input
                                type="text"
                                placeholder="Search tags..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="text-left w-32 px-2 py-1 text-sm lg:text-base rounded-lg border-none "
                            />
                            <div className="flex">
                                <input
                                    placeholder="New Tag?"
                                    value={customTag}
                                    type="text"
                                    onChange={(e) => setCustomTag(e.target.value)} // Properly update state
                                    className="border border-gray-300 text-sm lg:text-base rounded-lg px-2 py-1 w-28"
                                />
                                <button
                                    onClick={handleCustomTag}
                                    className="bg-secondary ml-2 text-sm lg:text-base text-tertiary font-semibold px-4 py-1  rounded-full"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>

                        <div className="flex flex-col gap-2 items-start">
                            <div className="font-semibold text-primary">Popular tags:</div>
                            <div className="flex flex-wrap gap-3 mb-2">
                                {topTags.map((tag) => (
                                    <span
                                        key={tag.tag}
                                        onClick={() => toggleTagSelection(tag)}
                                        className={`cursor-pointer text-sm px-2 font-semibold py-1 rounded-full ${
                                            selectedTags.some((selected) => selected.tag === tag.tag)
                                                ? "bg-primary text-secondary scale-110"
                                                : "bg-primary text-white"
                                        } transition-all duration-300`}
                                    >
                                        #{tag.tag}
                                    </span>
                                ))}
                            </div>
                        </div>
        
                        {/* Filtered Tags */}
                        {searchQuery && searchQuery?.length > 0 && <div className="absolute left-28 lg:left-40 top-2 h-40 w-60 flex flex-col gap-2 border-2 border-primary bg-primary bg-opacity-95 rounded-r-2xl border-l-0 justify-between p-3 overflow-y-scroll overflow-hidden-scrollbar">
                            {(filteredTags && filteredTags?.length > 0) ? filteredTags.map((tag) => (
                                <div
                                    key={tag.tag}
                                    onClick={() => toggleTagSelection(tag)}
                                    className="cursor-pointer text-tertiary hover:text-secondary transition-all duration-300 flex justify-between items-center w-full"
                                >
                                    <div>#{tag.tag}</div>
                                    <div className="text-sm">{tag.usedCount} posts</div>
                                </div>
                            )) : <div className="text-tertiary font-semibold">No Tags Found</div>}

                        </div>}

                        
                    </div>
                    {/* Display Selected Tags
                    <div className="w-[38%] ">
                        {selectedTags.length > 0 && (
                            <div className="mt-4 flex flex-col items-start">
                                <h3 className="font-bold text-tertiary text-lg mb-2">Selected Tags:</h3>
                                <div className="flex flex-wrap gap-2">
                                    {selectedTags.map((tag) => (
                                        <span
                                            key={tag.tag}
                                            className="px-3 py-1 bg-primary text-white rounded-full font-semibold text-sm"
                                        >
                                            #{tag.tag}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div> */}
                </div>

    
                {/* Submit Button */}
                <div className="flex justify-between items-center">
                    <div className={`flex gap-2 items-center`}>
                        <div onClick={() => setIsAnonymous(!isAnonymous)} className={`cursor-pointer w-12 h-6 rounded-full ${isAnonymous ? 'bg-secondary' : 'bg-tertiary'} transition-all duration-500`}>
                            <div className={`w-6 h-6 rounded-full border-[1px] ${isAnonymous ? 'translate-x-full bg-tertiary border-secondary' : 'bg-lightSecondary'} transition-all duration-500`}></div>
                        </div>
                        <div>Submit as anonymous user</div>
                    </div>
                    <div className="flex items-center gap-4">
                        <button
                            type="submit"
                            className="px-2 py-1 lg:px-4 lg:py-2 bg-tertiary rounded-xl text-base md:text-lg hover:shadow-xl hover:scale-110 transition-all duration-500"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </form>
    
            {loading && (
                <div className="h-[100vh] w-[100vw] flex justify-center items-center fixed inset-0 z-50 bg-black bg-opacity-65">
                    <LoadingSpinner />
                </div>
            )}
        </div>
    );
};    


export default AddQuestion;