/**
 * ClassPaymentHandler.js
 * Handles the shopping of class memberships
 */

import { storeClasssReceiptInGymDatabase } from "../gymDatabaseHelper";
import { userClassReceipts } from "../userDatabaseHelper";

/**
 * handles the payment process for classes
 * Calls the functions to store the receipts in the respective databases
 * @param   {Object} paymentData        -   Payment Information + user and gym Data
 * @param   {Object} response           -   Razorpay information
 * @returns {Boolean}                   -   True, if successful; false, otherwise
 */
export const ClassPaymentHandler = async(paymentData, response) => {
    try {
        // console.log("ClassPayemntHandler : ", paymentData)
        const userResponse = userClassReceipts(paymentData, response);
        const gymResponse  = storeClasssReceiptInGymDatabase(paymentData, response);

        if (userResponse.status && gymResponse.status)
            return true;

        return false;
    } catch (error) {
        // console.log('error in ClassPaymentHandler : ', error);
        return false;
    }
}