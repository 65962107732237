import { convertTo12HourFormat } from "../../basicHelper";
/**
 * Groups days by their unique timing slots and formats them for display.
 * Includes missing or explicitly empty days as "Closed."
 * @param {Object} timeSlots - An object where keys are days of the week, and values are arrays of time slots.
 * @returns {Array} - An array of grouped days with their timings or "Closed."
 */
export const groupDaysByTimings = (timeSlots) => {
    const daysOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const groupedTimings = {};

    // Group days by their timing structure
    for (const [day, slots] of Object.entries(timeSlots)) {
        // If slots are empty or contain empty strings, mark the day as "Closed"
        const isClosed = slots.every(slot => !slot.openingTime && !slot.closingTime);
        const timingsKey = isClosed ? "Closed" : JSON.stringify(slots.map(slot => `${slot.openingTime}-${slot.closingTime}`));

        if (!groupedTimings[timingsKey]) {
            groupedTimings[timingsKey] = [];
        }
        groupedTimings[timingsKey].push(day);
    }

    // Add missing days explicitly as "Closed"
    const missingDays = daysOrder.filter(day => !Object.keys(timeSlots).includes(day));
    if (missingDays.length > 0) {
        if (!groupedTimings["Closed"]) {
            groupedTimings["Closed"] = [];
        }
        groupedTimings["Closed"].push(...missingDays);
    }

    // Format grouped timings for display
    return Object.entries(groupedTimings).map(([timingsKey, days]) => {
        const sortedDays = days.sort((a, b) => daysOrder.indexOf(a) - daysOrder.indexOf(b)); // Sort days
        const timings = timingsKey === "Closed" ? ["Closed"] : JSON.parse(timingsKey).map(timing => timing.replace("-", " to "));

        // If all 7 days are grouped and not "Closed," display "Monday to Sunday"
        if (timingsKey !== "Closed" && sortedDays.length === 7) {
            return {
                days: "Monday to Sunday",
                timings,
            };
        }

        // If some days are grouped, display "Monday to Friday" or similar
        return {
            days: sortedDays.length === 1 ? sortedDays[0] : `${sortedDays[0]} to ${sortedDays[sortedDays.length - 1]}`,
            timings,
        };
    });
};

// Function to group consecutive days with identical timings
export const groupConsecutiveDaysByTimings = (timeSlots) => {
    // Helper function to get day order
    const dayOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    
    const dayTimings = dayOrder.map(day => {
        const slot = timeSlots[day]?.[0];
        if (slot) {
            return { day, timingKey: `${slot.openingTime}-${slot.closingTime}`, openingTime: slot.openingTime, closingTime: slot.closingTime };
        }
        return { day, timingKey: null };
    });

    const groupedTimings = [];
    let currentGroup = [];

    // Iterate through days to group consecutive days with the same timings
    for (let i = 0; i < dayTimings.length; i++) {
        const current = dayTimings[i];
        const previous = dayTimings[i - 1];

        if (current.timingKey && (!previous || current.timingKey !== previous.timingKey)) {
            // Start a new group if timings change
            if (currentGroup.length) {
                groupedTimings.push({ days: formatDays(currentGroup), ...currentGroup[0] });
            }
            currentGroup = [current];
        } else if (current.timingKey) {
            // Continue grouping if timings are the same
            currentGroup.push(current);
        }
    }

    // Add the last group if it exists
    if (currentGroup.length) {
        groupedTimings.push({ days: formatDays(currentGroup), ...currentGroup[0] });
    }

    return groupedTimings;
};

// Helper function to format days as "Monday to Friday" or "Monday" if single day
const formatDays = (daysArray) => {
    if (daysArray.length === 1) {
        return daysArray[0].day;
    }
    return `${daysArray[0].day} to ${daysArray[daysArray.length - 1].day}`;
};

/**
 * Formats an array of time slots into a readable 12-hour format string.
 * 
 * @param {Array} slots - Array of objects containing openingTime and closingTime.
 * @returns {string} - Formatted time slots or "Closed" if no valid slots exist.
 */
export const formatTimeSlots = (slots) => {
    if (!slots || slots.length === 0) return "Closed";
    
    return slots.map(slot => {
      if (!slot?.openingTime || !slot?.closingTime) {
        return "Closed";
      }
      return `${convertTo12HourFormat(slot.openingTime)} to ${convertTo12HourFormat(slot.closingTime)}`;
    }).join(", ");
};


// Compares two arrays of time slots and returns true if they are identical.
const areTimingSlotsEqual = (slots1, slots2) => {
    if (!slots1 || !slots2) return false;
    if (slots1.length !== slots2.length) return false;
    
    return slots1.every((slot1, index) => {
      const slot2 = slots2[index];
      return slot1.openingTime === slot2.openingTime && 
             slot1.closingTime === slot2.closingTime;
    });
};

/**
 * Processes a time slot object or array and groups consecutive days with identical time slots.
 * 
 * @param {Array|Object} timeSlots - Array of time slots or an object with days as keys and time slots as values.
 * @returns {Array} - Grouped time slots with days and their respective slots.
 */
export const processTimings = (timeSlots) => {
    if (!timeSlots) return [];
    
    // Handle the case where timeSlots is an object with days as keys
    if (typeof timeSlots === 'object' && !Array.isArray(timeSlots)) {
      const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const groups = [];
      let currentGroup = {
        days: [],
        slots: null
      };
  
      // Process weekdays first
      weekDays.forEach((day) => {
        const daySlots = timeSlots[day];
        
        if (!currentGroup.slots) {
          currentGroup.days.push(day);
          currentGroup.slots = daySlots;
        } else if (areTimingSlotsEqual(currentGroup.slots, daySlots)) {
          currentGroup.days.push(day);
        } else {
          if (currentGroup.days.length > 0) {
            groups.push({
              day: currentGroup.days.length > 1 
                ? `${currentGroup.days[0]} to ${currentGroup.days[currentGroup.days.length - 1]}`
                : currentGroup.days[0],
              slots: currentGroup.slots
            });
          }
          currentGroup = {
            days: [day],
            slots: daySlots
          };
        }
      });
  
      // Add the last weekday group
      if (currentGroup.days.length > 0) {
        groups.push({
          day: currentGroup.days.length > 1 
            ? `${currentGroup.days[0]} to ${currentGroup.days[currentGroup.days.length - 1]}`
            : currentGroup.days[0],
          slots: currentGroup.slots
        });
      }
  
      // Add Sunday separately if it exists
      if (timeSlots['Sunday']) {
        groups.push({
          day: 'Sunday',
          slots: timeSlots['Sunday']
        });
      }
  
      return groups;
    }
    
    // Handle the case where timeSlots is an array
    if (Array.isArray(timeSlots)) {
      return [{
        day: 'Monday to Saturday',
        slots: timeSlots
      }];
    }
    
    return [];
  };