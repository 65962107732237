import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "./firebase";
import toast from "react-hot-toast";

// Initialize Firebase authentication and provider
const provider = new GoogleAuthProvider();

/**
 * GoogleSignIn component handles Google authentication.
 * 
 * @returns {Boolean}   -   true; if successfully logged in, false; otherwise
 */
export const handleGoogleLogin = async () => {
    try {
        const result = await signInWithPopup(auth, provider);
        toast.success("Sign In Successful!");
        return {
            status  :   true,
            ...result,
        }
    } catch (error) {
        toast.error("Error during sign-in:", error);
        return {
            status  :   false,
            user    :   {}
        }
    }
};