import { useEffect, useState } from "react";
import { availOfferInPrices, getSellingPrice } from "../../helperFunctions/MarketplaceHelpers/gymDataHelpers/PriceHelpers";
// import { getCurrencyToDisplay } from "../../helperFunctions/PaymentHelpers";
// import { useNavigate } from "react-router-dom";
import GiftBox from '../../assets/Website_content/GiftBox.svg'
import ValentineCard from '../../assets/Website_content/GymValentineCard.svg'
import SmallValentineCard from '../../assets/Website_content/GymValentineCardSmall.svg'

/**
 * Displays gift membership prices of the gym
 * @param {object}   currGymData            -   contains all the information about the gym  
 * @param {function} toggleSummaryModal     -   function to open the modal where we can add coupons, referral code and make payment
 * @param {Object} offer                    -   The special Offer from the gym
 * @param {Object} userGender               -   Gender of the user; for offer purposes
 * @returns {JSX.component} component displaying gift membership prices
 */
const GiftMembershipPrices = ({ USR, currGymData, toggleSummaryModal, offer, userGender }) => {
    const [prices, setPrices] = useState({});

    /**
     * gets the new exclusive prices after the offers
     */
    useEffect(() => {
        if(offer) {
            const tempPrices = availOfferInPrices(currGymData.prices, offer, userGender);
            setPrices(tempPrices);
        }
        else 
            setPrices(currGymData.prices);

    }, [currGymData, offer, userGender])
    
    /**
     * gets the new exclusive prices after the offers
     */
    useEffect(() => {
        if(offer) {
            const tempPrices = availOfferInPrices(currGymData.prices, offer, userGender);
            setPrices(tempPrices);
        } else 
            setPrices(currGymData.prices);

    }, [currGymData, offer, userGender])

    const priceToPayGym = currGymData.isExclusive === false
                        ? currGymData.commissionPercentage
                            ? prices?.single?.exclusiveMonthlyPrice * ((100 - currGymData.commissionPercentage) / 100)
                            : prices?.single?.exclusiveMonthlyPrice * ((100 - 20) / 100)
                        : prices?.single?.exclusiveMonthlyPrice;


    const handleBuyNowButton = () => {
        const price = getSellingPrice(prices?.single?.exclusiveMonthlyPrice, currGymData.isExclusive, currGymData.gymName, currGymData.city);
        toggleSummaryModal(1, price, prices?.single?.monthlyPrice, 'gift', priceToPayGym)
    }

    return (
        <>
            {/* <div className={`relative w-full h-fit pb-14 text-lg flex justify-center items-end mt-10`}>
                {prices?.single &&
                    <Prices 
                        USR={USR} 
                        currGymData={{
                            country                 :   currGymData.country, 
                            isExclusive             :   currGymData.isExclusive,
                            gymName                 :   currGymData.gymName,
                            city                    :   currGymData.city,
                            commissionPercentage    :   currGymData.commissionPercentage,
                        }} 
                        prices={prices} 
                        toggleSummaryModal={toggleSummaryModal}
                    />
                }
            </div> */}

            <div className="mt-8 lg:mt-14 relative flex w-full">
                {/* Large Screens */}
                <img src={ValentineCard} alt='Valentine' className={`hidden lg:block h-full w-[94%] rounded-xl `} />
                
                {/* Small Screens */}
                <img src={SmallValentineCard} alt='Valentine' className={`lg:hidden h-full w-[94%] rounded-xl`} />
                <img src={GiftBox} alt='GiftBox' className={`absolute -right-4 -bottom-4 h-[110%] w-fit`} />
                <button
                    onClick={() => handleBuyNowButton()}
                    className={`absolute left-[20%] top-[62%] lg:left-[29%] lg:top-[73%] text-[#E27B8F] font-semibold text-xs lg:text-4xl bg-tertiary px-6 lg:px-8 py-1 lg:py-2 rounded-full`}>
                        Buy Now
                    </button>
            </div>
        </>
    )
}

export default GiftMembershipPrices;

/**
 * The Prices component for gift memberships
 */
// const Prices = ({USR, currGymData, prices, toggleSummaryModal}) => {
//     return (
//         <>
//             <div className={`z-[3] rounded-2xl shadow-lightSecondary shadow-lg h-fit w-full bg-[#ffffff] px-6 py-4`}>
//                 <div className={`flex flex-col`}>
//                     {/* Header */}
//                     <div className={`flex justify-between items-center`}>
//                         <div className={`font-semibold text-2xl`}>Gift Membership</div>
//                         <div className={`px-4 py-2 bg-lightSecondary text-primary font-semibold text-xs rounded-full`}>
//                             Single Month
//                         </div>
//                     </div>

//                     {/* Price */}
//                     <div className={`w-full flex flex-col mt-8`}>
//                         <div className="flex flex-col mt-4">
//                             <div className="flex justify-between items-center">
//                                 <div className={`font-semibold text-lg ${USR >= 1.5 ? 'lg:text-sm' : USR === 1 ? 'lg:text-xl' : USR === 1.25 ? 'lg:text-base' : ''} whitespace-nowrap`}>
//                                     1 Month Gift
//                                 </div>

//                                 <div className={`flex w-[60%] justify-end gap-2 text-lg ${USR >= 1.5 ? 'lg:text-sm' : USR === 1 ? 'lg:text-xl' : USR === 1.25 ? 'lg:text-sm' : 'lg:text-base'}`}>
//                                     {prices?.single?.monthlyPrice && (
//                                         <div className={`line-through text-red bg-transparent scale-90 whitespace-nowrap mt-1`}>
//                                             {`${getCurrencyToDisplay(currGymData.country)}${prices.single.monthlyPrice}`}
//                                         </div>
//                                     )}
//                                     <button
//                                         onClick={() => {
//                                             const price = getSellingPrice(
//                                                 prices?.single?.exclusiveMonthlyPrice,
//                                                 currGymData.isExclusive,
//                                                 currGymData.gymName,
//                                                 currGymData.city
//                                             );
//                                             const priceToPayGym = currGymData.isExclusive === false
//                                                 ? currGymData.commissionPercentage
//                                                     ? prices?.single?.exclusiveMonthlyPrice * ((100 - currGymData.commissionPercentage) / 100)
//                                                     : prices?.single?.exclusiveMonthlyPrice * ((100 - 20) / 100)
//                                                 : prices?.single?.exclusiveMonthlyPrice;
                                            
//                                             toggleSummaryModal(1, price, prices?.single?.monthlyPrice, 'gift', priceToPayGym);
//                                         }}
//                                         disabled={!prices?.single?.exclusiveMonthlyPrice}
//                                         className={`bg-lightSecondary rounded-full py-1 ${prices?.single?.exclusiveMonthlyPrice ? 'w-[45%]' : 'w-full lg:w-[45%]'}`}
//                                     >
//                                         {prices?.single?.exclusiveMonthlyPrice 
//                                             ? `${getCurrencyToDisplay(currGymData.country)}${getSellingPrice(
//                                                 prices.single.exclusiveMonthlyPrice,
//                                                 currGymData.isExclusive,
//                                                 currGymData.gymName,
//                                                 currGymData.city
//                                             )}`
//                                             : "No Price Available"
//                                         }
//                                     </button>
//                                 </div>
//                             </div>
//                             <hr className="text-secondary mt-2" />
//                         </div>
//                     </div>

//                     {/* Gift message */}
//                     <div className="mt-4 text-sm text-gray-600">
//                         * Gift membership is valid for one month and can be gifted to a single user only
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };