import { ExternalLink, MapPin } from "lucide-react";
import { TRVerified } from "../../assets/Website_content/svg";
import StarRating from "../StarRating";
import { getNavigationLink } from "../../helperFunctions/MarketplaceHelpers/MapHelpers/DistanceAndLocationHelpers";

const StudioInfo = (props) => {
    return (
        <>
            <div className={`w-full flex justify-between`}>
                <div className="flex flex-col text-left">
                    <div className="flex items-center gap-1 lg:gap-2">
                        <div className={`text-2xl font-bold text-primary`}>{props?.studioDisplayName || props.name}</div>
                        {props?.trainRexVerified && <TRVerified height='28px'/>}
                        <div className="flex">
                            {props?.isWomensOnly && (
                                <TRVerified height='28px' />
                            )}
                        </div>
                    </div>
                    <div className={`text-left text-primary font-semibold mt-2 flex  gap-2`}>
                        <MapPin className="scale-150"/> 
                        <span>{props?.address}, {props?.locality}</span>
                    </div>
                    {props?.mapLink ? (
                        <p className="text-sm md:text-lg font-bold text-complementPurple">
                            <a
                                href={props?.mapLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="group relative flex items-center hover:text-primary transition-colors duration-300 gap-2"
                            >
                                {/* Icon */}
                                <ExternalLink className="w-5 h-5 md:w-6 md:h-6" /> 
                                <span className='pt-1' >
                                    Get Directions
                                </span>
                            </a>
                        </p>
                    ) : props.latitude && (
                        <a
                            href={getNavigationLink(parseFloat(props.latitude), parseFloat(props.longitude))}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="group relative flex items-center bg-transparent rounded-xl text-complementPurple font-bold hover:scale-110 transition-all duration-300"
                        >
                            {/* Icon */}
                            <ExternalLink className="w-5 h-5 md:w-6 md:h-6" /> 
                                <span className='pt-1' >
                                    Get Directions
                                </span>
                        </a>
                    )}
                </div>
                <div><StarRating rating={props?.rating} NoOfReview={props?.NoOfReview} color={'secondary'} /></div>
            </div>

            
            {/* Timings */}
            {/* <div className={`p-4 border-[1px] border-gray-500 rounded-2xl`}>
                <div className={`font-semibold text-xl text-left`}>Business Hours</div>
                <div className="flex flex-col mb-2 mt-4">
                    {props?.timeSlots && typeof props.timeSlots === 'object' && !Array.isArray(props.timeSlots) ? (
                        // Handle the third format (grouped days with timings)
                        groupDaysByTimings(props.timeSlots).map((group, index) => (
                            <div key={index} className="text-sm md:text-lg font-bold flex justify-between">
                                <div className="text-primary">{group.days}</div> {" "}
                                <div className="text-primary">: {group.timings.join(", ")}</div>
                            </div>
                        ))
                    ) : props?.timeSlots?.length > 0 ? (
                        // Handle the second format (array of time slots)
                        props.timeSlots.map((slot, index) => (
                            <div key={index} className="text-sm md:text-lg font-bold text-primary flex justify-between">
                                <div>Monday to Saturday</div>
                                <div>: {slot.openingTime} to {slot.closingTime}</div>
                            </div>
                        ))
                    ) : (
                        // Fallback: Single opening and closing time
                        <div className="text-sm md:text-lg font-bold text-primary flex justify-between">
                            <div>Monday to Saturday</div>
                            <div>: {props?.openingTime} to {props?.closingTime}</div>
                        </div>
                    )}
                </div>
            </div> */}
        </>
    )
}

export default StudioInfo;