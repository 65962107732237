import { useState } from 'react';
import { Arrow } from '../../assets/Website_content/svg'
import { useEffect } from 'react';
import { getAllForums } from '../../helperFunctions/DiscussionForumHelpers/ForumDatabaseHelpers';
import { useNavigate } from 'react-router-dom';
import AddQuestion from '../Modals/Forum/AddQuestion';

/**
 * User can write a question and if a forum of that topic is available then the user is redirected to that forum page
 * @param {number} USR - screen resolution , 1 === 100%, 1.25 = 125%, 1.5 = 150% resolution
 * @returns {JSX.Element}
 */
const ForumQuestion = ({ USR }) => {
    const [forums           ,             setForums]    =   useState();
    const [question         ,           setQuestion]    =   useState('');
    const [addQuestionModal ,   setAddQuestionModal]    =   useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchForums = async () => {
            const data = await getAllForums();
            setForums(data);
        }

        fetchForums();
    }, [])

    const handleNavigation = (forum) => {       
        navigate(`/forum/${forum?.URLPrompt}/`, {state : forum});
    }

    return (
        <div className='flex flex-col justify-between h-full'>
            <div className={`flex-1 relative w-full max-h-fit`}>
                <div className={`inverted-radius-forum bg-lightOlive lg:bg-lightSecondary  px-4 pt-4 pb-2 flex flex-col gap-2 lg:gap-6  ${USR >= 1.5 ? 'lg:max-h-[20vh] lg:w-full' : USR <= 1 ? 'lg:h-[17vh] lg:w-full' : USR === 1.25 ? 'lg:h-[16vh] lg:w-full' : 'lg:h-[15vh] lg:w-full'}`}>
                    <div className={`text-secondary lg:text-primary font-semibold text-lg ${USR >= 1.5 ? '' : USR <= 1 ? 'lg:text-2xl mt-2' : USR === 1.25 ? '' : ''}`}>
                        Confused? Add your query and enter the discussion forum!
                    </div>

                    <input 
                        className={`rounded-full p-2 px-4 w-[80%] lg:w-[95%] text-secondary`} 
                        placeholder="Type your query ..." 
                        value={question} 
                        onChange={(e) => setQuestion(e.target.value)} 
                    />
                </div>
                
                {/* <div className={`absolute  bottom-1 right-0 flex justify-center items-center font-bold text-2xl bg-purple text-tertiary rounded-full h-12 w-12 rotate-45`}>
                    <Arrow color='#ffffff' height='20px' />
                </div> */}
                <button 
                    onClick={() => setAddQuestionModal(true)}
                    className={`absolute bottom-1 right-0 flex justify-center items-center font-bold text-2xl bg-purple text-tertiary rounded-full h-12 w-12 rotate-45 transition-transform hover:scale-110 focus:outline-none`}
                    aria-label="Next"
                >
                    <Arrow color='#ffffff' height='20px' />
                </button>

            </div>
            
            {/* Top Three Forums */}
            {forums && forums?.length > 3 &&
            <div className={`hidden lg:flex flex-col mt-8`}>
                <div className={`font-bold text-2xl `}>Popular Topics: </div>
                {forums.slice(0,3).map((forum, index) => (
                    <div
                        key={index}
                        onClick={() => handleNavigation(forum)}
                        className={`cursor-pointer rounded-3xl p-4 bg-lightSecondary mt-4`}
                    >
                        {/* heading */}
                        <div className={`flex justify-between items-center mb-1`}>
                            <div className={`font-semibold text-xl`}>{forum?.question}</div>
                            <div className={`whitespace-nowrap`}>{forum?.isAnonymous ? 'Anonymous' : forum?.authorName}</div>
                        </div>

                        <hr />

                        {/* Stats */}
                        <div className={`mt-2 flex gap-2`}>
                            <div>{forum?.views} Views |</div>
                            <div>{forum?.repliesCount} Replies |</div>
                            <div>{forum?.upVotes} Upvotes |</div>
                            <div>{forum?.followers?.length} People follow this</div>
                        </div>

                        {/* Tags */}
                        <div className={`flex flex-wrap gap-2 mt-2`}>
                            {forum?.tags && forum?.tags?.length > 0 && forum?.tags.map((tag, index) => (
                                <div key={index} className={`rounded-full border-2 px-4 py-1 ${index % 2 === 0 ? 'border-purple bg-lightPurple text-purple' : 'border-secondary bg-lightSecondary text-secondary'}`}>
                                    #{tag}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>}
            {addQuestionModal &&
                <AddQuestion setAddQuestionModal={setAddQuestionModal} stateQuestion={question} />
            }
        </div>
    )
}

export default ForumQuestion;