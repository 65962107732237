import { handlePaymentFailure, paymentSuccessHandler } from "./MembershipHelper";
import { getCurrency } from "./PaymentHelpers";
import { themeColors } from "../theme";
import icon from "../assets/Icon.webp"
import { updateBillInfo } from "./MarketplaceHelpers/PaymentHelpers/BillGeneration";
import { handleReferralCodeProcessing } from "./MarketplaceHelpers/PaymentHelpers/ReferralCodeHelpers";
import {sendConfirmationToGym, sendEmailToUserRoute } from "./MessagesAndEmails/messageHelper";
// import { sendConfirmationSMS } from "./messageHelper";
import Alert from "../components/customComponents/Alert";
import { buyMembershipErrorEmail } from "./CommunicationHelpers/ErrorEmailToTrainRex";
import { ClassPaymentHandler } from "./ClassesHelpers/ClassPaymentHandler";
import { addTrexToWallet } from "./UserProfile/addTrexToWallet";
import toast from "react-hot-toast";

/**
 * Generates a razorpay link
 * @returns Razorpay window script
 */
const loadRazorpayScript = () => {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.onload = () => resolve(true);
        script.onerror = () => resolve(false);
        document.body.appendChild(script);
    });
};

// return true if any of the conditions is true
export const isThisTest = (myData) => {
    return (process.env.NODE_ENV === 'development' || myData?.gymName === 'notlisted')
}

/**
 * Handles Main Razorpay payment method
 * @param {Object} myData                       - Contains the data required for process the payment
 * @param {Object} allMembers                   - Detials of all other (family) members
 * @param {Function} setIsPaymentProcessing     - Function to diplay loading
 * @param {Function} setBillInfo                - Sets the Bill Generation Info (to be used in Success Modal)
 * @returns 
 */
export const handlePayment = async (myData, allMembers, setIsPaymentProcessing, setBillInfoArray) => {
    const res = await loadRazorpayScript();
    const isTest = isThisTest(myData);

    if (!res) {
        Alert('error', 'Razorpay SDK failed to load. Are you online?');
        return false; // Return false if Razorpay fails to load
    }

    return new Promise((resolve) => {
        const options = {
            key         : isTest ? 'rzp_test_xWMw9b7Eg3ntwK' : 'rzp_live_PjuEiFCgxSVySz',
            amount      : myData.amountToPay, // Amount in currency subunits (50000 refers to ₹500.00)
            currency    : getCurrency(),
            name        : 'Train Rex',
            description : myData.orderType,
            image       : icon,
            handler: async function (response) {
                let res = true;

                // Handler for adding trex to wallet
                if (myData.orderType === 'addingTrex') {
                    await addTrexToWallet(myData.amountToPay, allMembers[0].uid);
                }
                // Handling Classes 
                else if (myData.orderType === 'class')  {
                    setIsPaymentProcessing(true);


                    const result = ClassPaymentHandler(myData, response);
                    if (!result) {
                        setBillInfoArray([...myData])
                        res = false;
                        Alert('error', "There was a problem processing payment for you!");
                    }
                }
                // Handling Gym Memberships
                else {
                    // We will run a loop as this function is already made for members
                    // If payment is successful, add data to firestore
                    for (const [index, member] of allMembers.entries()) {
                        // Set amount to original amount for the first member, 0 for others
                        const amountForMember = index === 0 ? myData.amount : 0;
                        setIsPaymentProcessing(true);
                        
                        // Call paymentSuccessHandler with updated amount for each member
                        const memberResult = await paymentSuccessHandler(
                            { 
                                ...myData, 
                                amount  :   amountForMember, 
                                user    :   member 
                            },
                            response,
                            (newBillInfo) => updateBillInfo(index, newBillInfo, setBillInfoArray)
                        );
                    
                        // Check the status and handle any errors
                        if (!memberResult.status) {
                            res = false;
                            Alert('error', `There was a problem processing payment for ${memberResult.userName}`);
                        }
                    }
                }

                if (res) {
                    if(myData.orderType === 'addingTrex') {
                        toast.success('trex added successfully');
                        resolve(true);
                        setIsPaymentProcessing(false);
                        return;
                    }
                    if(myData.referralGiver) {
                        handleReferralCodeProcessing(myData.referralGiver.userID, myData.totalMemberships);      
                    }
                    setIsPaymentProcessing(false);
                    resolve(true); // Resolve the promise with true on success
                    let emailResponseFromUser   =   {}
                    let emailResponseFromGym    =   {}
                    try {
                        for (const member of allMembers) {
                            // Send confirmation email to the member & gym
                            emailResponseFromUser   =   await sendEmailToUserRoute(member, myData, 'confirmation');
                            emailResponseFromGym    =   await sendConfirmationToGym(member, myData);

                            // await sendConfirmationEmail('email', member, 'client_confirmation', myData);
                            // await sendConfirmationEmail('email', member, 'gym_notification', myData);

                            // Send confirmation SMS to the member & gym
                            // await sendConfirmationSMS('sms',  member, 'client_confirmation', myData);
                            // await sendConfirmationSMS('sms',  member, 'gym_notification', myData);
                        }
                    } catch (error) {
                        buyMembershipErrorEmail({
                            ...myData,
                            user        :   allMembers[0]
                        }, `${error} -> Unable to send email : ${emailResponseFromUser.status ? emailResponseFromGym.status ? 'Dont Know' : emailResponseFromGym.errorMsg : emailResponseFromUser.errorMsg }`);
                    }
                } else {
                    setIsPaymentProcessing(false);
                    resolve(false); // Resolve with false on failure
                }
            },
            prefill: {
                name    : allMembers[0].displayName,
                email   : allMembers[0].email,
                contact : allMembers[0].phoneNumber,
            },
            notes: {
                userID  : allMembers[0].uid,
            },
            theme: {
                color   : themeColors.fg,
            },
            modal       : {
                ondismiss: function () {
                    handlePaymentFailure(setIsPaymentProcessing);
                    resolve(false); // Resolve with false if the modal is dismissed
                },
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    });
};

