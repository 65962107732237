import { BadgeIndianRupee } from "lucide-react";
import { useEffect, useState } from "react";

/**
 * a component displaying the available offers for the gyms
 * @param {object} offer 
 * @returns {JSX.Element} - a box showing the available offers for the gyms
 */
const SpecialOffersSection = ({ offer }) => {
    const [timeLeft, setTimeLeft] = useState(null);
    const [showCountdown, setShowCountdown] = useState(false);

    useEffect(() => {
        if (!offer?.validity) return;
    
        const validityDate = new Date(offer.validity); // Directly use the timestamp to create a Date object
        const currentDate = new Date();
    
        // Check if the offer is already expired
        const timeDifference = validityDate.getTime() - currentDate.getTime();
        const oneWeekInMs = 7 * 24 * 60 * 60 * 1000;
    
        if (timeDifference <= 0) {
            setShowCountdown(false);
            setTimeLeft("Expired");
            return;
        }
    
        if (timeDifference <= oneWeekInMs) {
            setShowCountdown(true);
    
            const interval = setInterval(() => {
                const now = new Date();
                const remainingTime = validityDate.getTime() - now.getTime();
    
                if (remainingTime <= 0) {
                    clearInterval(interval);
                    setTimeLeft("Expired");
                } else {
                    const hours = Math.floor(remainingTime / (1000 * 60 * 60));
                    const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
                    setTimeLeft(`${hours}h : ${minutes}m : ${seconds}s`);
                }
            }, 1000);
    
            return () => clearInterval(interval); // Clean up the interval on component unmount
        } else {
            setShowCountdown(false);
        }
    }, [offer?.validity]);
    

    const offerType = offer?.offerType === 'discount' ? 'FURTHER DISCOUNTED PRICES' : offer?.offerType === 'newPrices' ? (offer?.isWomensOnly ? 'DISCOUNTED PRICES FOR WOMEN' : 'NEW DISCOUNTED PRICES') : 'FREEBIES OFFER!'

    return (
        <>
            <div className="flex justify-center items-center mt-8">
                <div className="flex flex-col w-full lg:w-1/2 bg-purple rounded-2xl px-6 py-2 text-tertiary items-center">
        
                    <div className="text-xl font-semibold flex items-center justify-center">
                        <BadgeIndianRupee height="20px" className="mr-2" /> Special offer<BadgeIndianRupee height="20px" className="ml-2" />
                    </div>
                    <div className="flex flex-col font-semibold italic mt-2">
                        <div className="text-sm">{offerType}</div>
                        {offer?.offerType !== 'newPrices' && 
                            <div>
                                <span className='text-4xl'>{offer?.discountValue}</span>
                                {offer?.discountType === "fixed" ? " Rs" : "%"} Off!
                            </div>
                        }
                        <div>{offer?.addedInformationOnOfferType}</div>
                        <div className="not-italic mt-2 text-lg">
                            {showCountdown && timeLeft ? timeLeft : ""}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SpecialOffersSection;