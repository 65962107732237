import { useState, useEffect } from "react";
import { onAuthStateChanged, signInWithCustomToken } from "firebase/auth";
import { auth, db } from "../config/firebase"; // Adjust the import according to your Firebase setup
import { cloud_url } from "../assets/links";
import { doc, getDoc, updateDoc } from "@firebase/firestore";

/**
 * Custom hook to manage Firebase authentication status and user information.
 * 
 * This hook performs the following:
 * 1. Fetches a custom authentication token from the backend for a given UID.
 * 2. Signs in the user using the custom token.
 * 3. Tracks the authentication state of the user.
 * 4. Posts the user ID token to a React Native WebView.
 *
 * @returns {Object} An object containing `isUserPresent`, `user`, and `loading` states.
 */
const useAuthStatus = () => {
    const [isUserPresent, setIsUserPresent] = useState(false);
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);

    /**
     * Fetches a custom token from the backend for a specific UID.
     * @param {string} uid - The user ID for which to generate the token.
     * @returns {Promise<string>} The custom token for authentication.
     * @throws Will throw an error if the token cannot be fetched.
     */
    const fetchCustomToken = async (uid) => {
        try {
            const response = await fetch(`${cloud_url}/generate-token`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ uid }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            return data.customToken;
        } catch (error) {
            console.error("Error fetching custom token:", error);
            throw error;
        }
    };

    /**
     * Effect to handle signing in with a custom token when the component mounts.
     */
    useEffect(() => {
        const authenticateUser = async () => {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                const uid = urlParams.get("uid");

                if (!uid) {
                    setLoading(false);
                    return;
                }

                const token = await fetchCustomToken(uid);
                await signInWithCustomToken(auth, token);
            } catch (error) {
                console.error("Error during authentication:", error);
            } finally {
                setLoading(false);
            }
        };

        authenticateUser();
    }, []);

    /**
     * Effect to track the authentication state of the user.
     * Posts the user's UID or ID token to the React Native WebView.
     */
    useEffect(() => {
        // Check for user in localStorage first
        const localStorageUser = JSON.parse(localStorage.getItem("user"));

        if (localStorageUser && localStorageUser.phoneNumber && !localStorageUser.isUpdated) {
            setIsUserPresent(true);
            setUser(localStorageUser);
            setLoading(false);
            return; // Skip Firebase auth tracking if localStorage has a valid user
        }

        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                setIsUserPresent(true);
                // Return the user details from the collection and not the authentication
                const userData = await getUserFromCollection(currentUser.uid);
                setUser(userData);

                // Store the user in local storage
                if (userData) {
                    localStorage.setItem("user", JSON.stringify(userData));
                }

                if (window.ReactNativeWebView && typeof window.ReactNativeWebView.postMessage === "function") {
                    window.ReactNativeWebView.postMessage(
                        JSON.stringify({ uid: currentUser.uid })
                    );
                } else {
                    console.warn("ReactNativeWebView is not available.");
                }
    
        
            } else {
                setIsUserPresent(false);
                setUser(null);
            }
            setLoading(false);
        });
    
        return () => unsubscribe();
    }, []);

    return { isUserPresent, user, loading };
};

export default useAuthStatus;

/**
 * Fetches a user document from the Firestore database by user ID, 
 * excluding unwanted fields from the response.
 * 
 * @param   {String} userID             -   The ID of the user to fetch.
 * @param   {Boolean} storeInStorage    -   Boolean to check whether the user wants to store the user in localStorage or not
 * @returns {Promise<object|null>}      -   A promise resolving to the user data (without unwanted fields) or null if the user does not exist.
 */
const getUserFromCollection = async (userID, storeInStorage) => {
    try {
        // Create a reference to the user document
        const userRef = doc(db, 'user', userID);

        await updateDoc(userRef, {
            isUpdated : true,
        })
        
        // Fetch the document snapshot
        const userDoc = await getDoc(userRef);
        
        // Check if the document exists
        if (!userDoc.exists()) return null;

        // Extract user data
        const userData = userDoc.data();
        
        // Define fields to exclude
        const excludeFields = [
            'expoPushToken', 'occupation', 'recentlyViewedGyms',
            'unseen_messages', 'userType', 'week', 'ageRange', 'deletedWeek',
            'profilePicture',
        ];

        // Remove unwanted fields
        const filteredData = Object.keys(userData)
            .filter(key => !excludeFields.includes(key))
            .reduce((obj, key) => {
                obj[key] = userData[key];
                return obj;
            }, {});

        // Return cleaned user data with some additional default fields
        const dataToStore = {
            ...filteredData,
            uid         :   userDoc.id,
            displayName :   userData.name || 'User',
            name        :   userData.name,
            isUpdated   :   true
        };

        if (storeInStorage) localStorage.setItem("user", JSON.stringify(dataToStore));

        return dataToStore;

    } catch (error) {
        console.error('Error fetching user:', error);
        throw error;
    }
};
