import { useEffect, useRef, useState } from 'react';
import { fetchAllBlogs } from '../../helperFunctions/MarketplaceHelpers/MarketplaceHelper';
import { useNavigate } from 'react-router-dom';
import { removeSpacesAndConvertLowercase } from '../../helperFunctions/basicHelper';
const TRLogo = 'https://media.istockphoto.com/id/1313456479/photo/man-and-soul-yoga-lotus-pose-meditation-on-nebula-galaxy-background.jpg?s=612x612&w=0&k=20&c=jJ0pVed-sHjDBtomrO7KmR4qtIfH8OaNhjmEsXvJmAI=';

const Blogs = (props) => {
    const [blogList, setBlogList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentSlide, setCurrentSlide] = useState(0);
    const slideIntervalRef = useRef(null);
    const [dragStart, setDragStart] = useState(null);
    const [dragOffset, setDragOffset] = useState(0);

    useEffect(() => {
        setLoading(true);
        const fetchBlogs = async () => {
            const blogsList = await fetchAllBlogs();
            setBlogList(blogsList);
            setLoading(false);
        };
        fetchBlogs();
    }, [props.selectedCity]);

    useEffect(() => {
        startSlideTimer();

        return () => clearInterval(slideIntervalRef.current);
    }, );

    const startSlideTimer = () => {
        clearInterval(slideIntervalRef.current);
        slideIntervalRef.current = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % blogList?.length);
        }, 7000);
    };

    const navigate = useNavigate();

    const handleNavigate = (blog) => {
        const blogSlug = removeSpacesAndConvertLowercase(blog.gymDisplayName);
        navigate(`/blogs/${blogSlug}`, { state: blog });
    };

    const handleTouchStart = (e) => {
        setDragStart(e.touches[0].clientX);
        setDragOffset(0);
    };

    const handleTouchMove = (e) => {
        if (dragStart !== null) {
            const currentTouch = e.touches[0].clientX;
            setDragOffset(currentTouch - dragStart);
        }
    };

    const handleTouchEnd = () => {
        if (dragOffset > 50) {
            prevSlide();
        } else if (dragOffset < -50) {
            nextSlide();
        }
        setDragStart(null);
        setDragOffset(0);
    };

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % blogList?.length);
        startSlideTimer();
    };

    const prevSlide = () => {
        setCurrentSlide((prev) => (prev - 1 + blogList?.length) % blogList?.length);
        startSlideTimer();
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!blogList || blogList?.length === 0) {
        return <div>No blogs available</div>;
    }

    return (
        <div
            className="p-8 bg-lightOlive relative overflow-hidden rounded-2xl min-h-[90vw] lg:min-h-full"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <div className="absolute inset-2 lg:inset-6 flex transition-transform duration-500 ease-in-out" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                {blogList.map((blog, index) => (
                    <div
                        key={index}
                        className="flex flex-col lg:flex-row justify-center gap-4 lg:gap-x-4 min-w-full px-6 lg:px-6"
                        onClick={() => handleNavigate(blog)}
                    >
                        <div className="flex flex-col">
                            <div className="font-bold text-2xl text-primary">
                                {`${blog.gymDisplayName.slice(0, 58)} ${blog.gymDisplayName?.length > 58 ? '...' : ''}`}
                            </div>
                            <div className="font-semibold text-secondary mt-4">
                                By {blog.locality}
                            </div>
                            <img
                                src={blog?.image || TRLogo}
                                alt="Logo"
                                className="mt-4 rounded-2xl object-cover overflow-hidden max-h-40 lg:max-h-none"
                            />
                        </div>
                    </div>
                ))}
            </div>

            {/* Circles indicating the current slide */}
            <div className="lg:hidden absolute pb-1 bottom-1 md:bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                {blogList.map((_, index) => (
                    <div
                        key={index}
                        className={`w-2 h-2 rounded-full ${currentSlide === index ? 'bg-secondary' : 'bg-gray-400'}`}
                        style={{ transition: 'background-color 0.3s ease' }}
                    ></div>
                ))}
            </div>
        </div>
    );
};

// const stripHtml = (html) => {
//     const tmp = document.createElement("div");
//     tmp.innerHTML = html;
//     return tmp.textContent || tmp.innerText || "";
// };


    // const truncateContent = (content) => {
    //     let length;
    //     if (screenWidth <= 640) {
    //         length = 150;
    //     } else if (screenWidth <= 1024) {
    //         length = 195;
    //     } else {
    //         length = 240;
    //     }
    //     return content?.length > length ? content.substring(0, length) + "..." : content;
    // };

export default Blogs;
