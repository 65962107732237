import { useEffect, useState } from "react";
import { addForumTag, isTagAlreadyInDatabase, updateForum } from "../../../helperFunctions/DiscussionForumHelpers/ForumDatabaseHelpers";
import { getAllTags } from "../../../helperFunctions/DiscussionForumHelpers/GetAllTags";
import Alert from "../../customComponents/Alert";

/**
 * Takes in all the data of the forum document and 
 * only the fields that the user wants to edit are pushed to the update function
 * and on save the function is called and chnages are saved in database
 * 
 * @param {object}   forum            - the all forum details 
 * @param {function} setShowEditModal - To open and close the edit modal 
 * @param {function} setRefreshKey    - function to refresh the forum once edit is done 
 * @param {number}   USR              - User screen resolution
 * @returns {JSX.Element} a modal to edit the existing forum within 1 hour of making the forum
 */
export const EditForumModal = ({ forum, setShowEditModal, setRefreshKey, USR}) => {
    const [tags         ,                 ] = useState(forum?.tags || []);
    const [image        ,         setImage] = useState(forum?.image);
    const [allTags      ,       setAllTags] = useState();
    const [question     ,      setQuestion] = useState(forum?.question);
    const [customTag    ,     setCustomTag] = useState("");
    const [description  ,   setDescription] = useState(forum?.description);
    const [isAnonymous  ,   setIsAnonymous] = useState(forum?.isAnonymous);
    const [searchQuery  ,   setSearchQuery] = useState("");
    const [selectedTags ,  setSelectedTags] = useState([...tags]);

    useEffect(() => {
        const fetchTags = async () => {
            const data = await getAllTags();
            setAllTags(data);
        }

        fetchTags();
    }, [])

    // Convert image to Base64 string
    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result); // Base64 string
            };
            reader.readAsDataURL(file); // Convert to Base64
        }
    };

    // Filter tags based on search query and exclude already selected tags
    const filteredTags = allTags ? allTags.filter(
        ({ tag, tagDisplayName }) =>
            (tag.toLowerCase().includes(searchQuery.toLowerCase()) ||
                tagDisplayName.toLowerCase().includes(searchQuery.toLowerCase())) &&
            !selectedTags.includes(tag)
    ): null;

    const toggleTagSelection = (tag) => {
        if (selectedTags.includes(tag)) {
            setSelectedTags(selectedTags.filter((selected) => selected !== tag)); // Remove tag
        } else {
            setSelectedTags([...selectedTags, tag]); // Add tag
        }
        setSearchQuery("");
    };

    const handleCustomTag = async (e) => {
        e.preventDefault();
    
        const trimmedTag = customTag.trim();
        if (!trimmedTag || selectedTags.includes(trimmedTag)) {
            setCustomTag(""); // Clear the input field
            return; // Exit if the tag is invalid or already selected
        }
    
        try {
            // Check if the tag exists in the database
            const isTagExists = await isTagAlreadyInDatabase(trimmedTag.toLowerCase());
    
            if (!isTagExists) {
                // Add the tag to the database
                const isSuccess = await addForumTag(trimmedTag.toLowerCase());
                if (isSuccess) {
                    Alert('success', "Tag Added Successfully")
                } else {
                    Alert('error', "Failed to add the tag to the database");
                }
            }
    
            // Add the tag to the selectedTags state
            setSelectedTags([...selectedTags, trimmedTag]);
        } catch (error) {
            console.error("Error while adding custom tag:", error);
            Alert('error', "An error occurred while adding the tag. See console for details.");
        } finally {
            setCustomTag(""); // Clear the input field
        }
    };

    const handleSave = async () => {
        const updatedFields = {};
        
        // Compare each field with its initial value and add to updatedFields only if it has changed
        if (question !== forum?.question) {
            updatedFields.question = question;
        }
        if (description !== forum?.description) {
            updatedFields.description = description;
        }
        if (isAnonymous !== forum?.isAnonymous) {
            updatedFields.isAnonymous = isAnonymous;
        }
        if (image !== forum?.image) {
            updatedFields.image = image || null; // Handle case where image is cleared
        }
        if (JSON.stringify(selectedTags) !== JSON.stringify(forum?.tags)) {
            updatedFields.tags = selectedTags; // Only add tags if they've changed
        }
    
        // If no fields have been updated, notify the user and return early
        if (Object.keys(updatedFields)?.length === 0) {
            Alert('info', "No changes detected to save.");
            return;
        }
        
        try {
            await updateForum(forum.id, updatedFields);
            Alert('success', "Forum updated successfully!");
            setRefreshKey(1); // Refresh forum data
            setShowEditModal(false); // Close modal
        } catch (error) {
            console.error("Failed to update forum:", error);
            Alert('error', "Failed to update forum. See console for details.");
        }
    };

    return (
        <div className="z-50 fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className={`bg-secondary rounded-2xl p-6 shadow-lg w-[90vw] md:w-[80vw] ${USR <= 1 ? 'lg:w-[40vw]' : USR === 1.25 ? 'lg:w-[55vw] lg:scale-[80%]' : USR === 1.5 ? 'lg:w-[80vw] lg:scale-[70%]' : 'lg:w-[50vw] lg:scale-[90%]'}`}>
                <h2 className="text-xl font-bold mb-4">Edit Forum</h2>

                {/* Question */}
                <label className="block mb-2 font-semibold">Question</label>
                <input
                    type="text"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    className="w-full px-3 py-2 border rounded-xl mb-4"
                />

                {/* Description */}
                <label className="block mb-2 font-semibold">Description</label>
                <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="w-full px-3 py-2 border rounded-xl mb-4"
                    rows={4}
                />

                {/* Tags */}
                <label className="block mb-2 font-semibold">Tags</label>
                <div className="flex flex-wrap gap-2 mb-2">
                    {selectedTags?.map((tag) => {
                        const tagDisplayName = allTags?.find(
                            (t) => t.tag === tag
                        )?.tag || tag;
                        return (
                            <div
                                key={tag}
                                className="flex gap-1 items-center px-3 py-1 bg-primary rounded-full text-tertiary"
                            >
                                <div>#{tagDisplayName}</div>
                                <div
                                    onClick={() => toggleTagSelection(tag)}
                                    className="-mr-2 scale-75 px-2 rounded-full font-bold bg-secondary text-primary cursor-pointer"
                                >
                                    x
                                </div>
                            </div>
                        );
                    })}
                </div>

                <input
                    type="text"
                    placeholder="Search tags..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full px-3 py-2 border rounded-xl mb-2"
                />
                {searchQuery && (
                    <div className="flex flex-wrap gap-2 mb-2">
                        {filteredTags && filteredTags?.length > 0 && filteredTags?.map(({ tag, tagDisplayName }) => (
                            <span
                                key={tag}
                                onClick={() => toggleTagSelection(tag)}
                                className="px-3 py-1 bg-tertiary rounded-xl cursor-pointer"
                            >
                                #{tag}
                            </span>
                        ))}
                    </div>
                )}

                <div className="flex gap-4 mb-4">
                    <input
                        type="text"
                        placeholder="New tag"
                        value={customTag}
                        onChange={(e) => setCustomTag(e.target.value)}
                        className="w-[80%] px-3 py-2 border rounded-xl"
                    />
                    <button
                        onClick={handleCustomTag}
                        className="px-4 py- bg-primary text-tertiary rounded-full"
                    >
                        Add Tag
                    </button>
                </div>

                {/* Image */}
                <div className="flex justify-between mb-4">
                    <div>
                        <label className="block mb-2 font-semibold">Upload Image:</label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            className="block w-full text-sm text-tertiary file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-tertiary file:text-secondary file:cursor-pointer"
                        />
                    </div>
                    {forum?.image && <div className="flex flex-col gap-2">
                        <div className="block font-semibold">Current Image</div>
                        <img src={forum?.image} alt='none' className="w-72 h-48 object-cover rounded-xl" />
                    </div>}

                </div>

                {/* Is Anonymous */}
                <label className="flex items-center mb-4">
                    <input
                        type="checkbox"
                        checked={isAnonymous}
                        onChange={(e) => setIsAnonymous(e.target.checked)}
                        className="mr-2"
                    />
                    Post Anonymously
                </label>

                {/* Actions */}
                <div className="flex justify-end gap-2">
                    <button
                        onClick={handleSave}
                        className="px-4 py-2 bg-tertiary text-primary font-semibold rounded-full hover:scale-110 hover:shadow-xl transition-all duration-500"
                    >
                        Save
                    </button>
                    <button
                        onClick={() => setShowEditModal(false)}
                        className="px-4 py-2 bg-tertiary text-primary font-semibold rounded-full hover:scale-110 hover:shadow-xl transition-all duration-500"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};