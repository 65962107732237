import { useState } from "react";
import emailjs from 'emailjs-com';
import { emailChecker } from "../../helperFunctions/ConstraintsChecker";
import { GetInTouchVisual } from "../../assets/Website_content/svg";
import { CheckBoxComponent } from "../HeadlessUI";
import { sendEmailToUserRoute } from "../../helperFunctions/MessagesAndEmails/messageHelper";
import Alert from "../customComponents/Alert";
// import { CallIcon } from '../../assets/Website_content/svg'

/**
 * For user to contact Trainrex
 * @param {number} USR - screen resolution , 1 === 100%, 1.25 = 125%, 1.5 = 150% resolution
 * @param {bool} isLandingPage - true if the url is landingPage
 * @returns {JSX.Element} Form where user canwrite to Train Rex about anything
 */
const GetInTouch = ({ USR, isLandingPage = false}) => {
    const [loading ,  setLoading] = useState(false);
    const [formData, setFormData] = useState({name: '', email: '', locality: '', phoneNumber: '', message: '', category: 0});
    const [isMobile,            ] = useState(window.innerWidth <= 470)

    /**
     * Updates the form data state based on user input.
     * @param {Event} e - The input change event.
     */
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    /**
     * Handles checkbox selection for category.
     * Only one category can be selected at a time.
     * @param {number} checkboxValue - The numeric value of the category.
     * @param {boolean} checked - Checkbox checked status.
     */
    const handleCheckboxChange = (checkboxValue, checked) => {
        setFormData((prev) => ({
            ...prev,
            category: checked ? checkboxValue : 0,  // If checked, set the category; otherwise, reset to 0
        }));
    };


    /**
   * Handles the form submission, validates inputs, and sends an email if validation passes.
   * @param {Event} e - The form submission event.
   */
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        const { name, email, locality, phoneNumber, message, category } = formData;

        if (!emailChecker(email)) {
        Alert('error', 'Please enter a valid email address.');
        setLoading(false);
        return;
        }

        if (category?.length === 0) {
        Alert('error', 'Please select at least one category.');
        setLoading(false);
        return;
        }

        if (!name || !email || !message || !locality || !phoneNumber) {
        Alert('error', 'Please fill out all the fields.');
        setLoading(false);
        return;
        }

        const templateParams = {
            from_name   : `${name} | ${locality} | ${phoneNumber}`,
            to_name     : 'Train Rex',
            message     : message,
            category    : category === 1 ? 'Dietitian' : category === 2 ? 'PT Trainer' : category === 3 ? 'Gym Member' : 'Not Selected',
            email       : email,
        };

        // Sends Email to Train Rex
        sendEmail(templateParams, setLoading);

        // Send Automated Email to the user
        sendEmailToUserRoute({email : email}, {userName : name}, 'getInTouch');

        setFormData({name: '', email: '', locality: '', phoneNumber: '', message: '', category: 0});
    };

    const dinosaurImageHeight = isMobile ? '34vw' : '200px';
    // const dinosaurImageWidth  = isMobile ? '580px' : '200px';

    return (
        <>
            <div className={`${isLandingPage ? 'mt-8 lg:mx-0 lg:w-full' : ''} flex-1`}>
                {/* <div className={`font-bold text-2xl ${isLandingPage ? 'hidden' : ''} ${USR >= 1.5 ? '' : USR <= 1 ? 'lg:text-4xl' : USR === 1.25 ? '' : ''}`}>
                    Get in <span className={`text-secondary`}>touch</span>
                </div> */}
                {/* Form Box */}
                <div className={`bg-lightOlive p-4 pb-4 pt-8 rounded-3xl rounded-bl-none shadow-md text-primary ${isLandingPage ? 'w-full' : ''}`}>
                    {/* Dino Box */}
                    {!isLandingPage && 
                        <div className={`relative flex justify-center items-end h-40 lg:h-56 w-[90%] lg:w-[95%] rounded-2xl bg-[#ffffff] mx-4 shadow-md font-semibold text-primary`}>
                            <GetInTouchVisual className='absolute -left-6' height={dinosaurImageHeight} />
                            <div className={`absolute left-4 -top-4 lg:-top-5 text-lightOlive bg-secondary text-[15px] lg:text-xl font-bold rounded-full px-6 py-[6px]`}>Get In Touch</div>
                            <div className={`absolute -right-7 bottom-14 lg:bottom-20 text-lightOlive bg-secondary text-[10px] lg:text-xl font-semibold rounded-full px-4 py-[6px]`}>Hello, I am Rex!</div>
                            <div className={`absolute -right-12 lg:-right-12 bottom-5 text-lightOlive bg-secondary text-[10px] lg:text-xl font-semibold rounded-full px-6 py-[6px]`}>How may I help?</div>
                        </div>
                    }

                    <div className={`grid grid-cols-2 gap-x-4 gap-y-2 w-full`}>
                        <div className="mt-4 w-full">
                            <div className="text-sm font-semibold lg:text-xl mb-1">Your Name</div>
                            <input
                                className="px-2 py-3 text-sm w-full rounded-xl"
                                placeholder="Eg. John Doe"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mt-4 w-full">
                            <div className="text-sm font-semibold lg:text-xl mb-1">Your Email</div>
                            <input
                                className="px-2 py-3 text-sm w-full rounded-xl"
                                placeholder="Eg. abc@gmail.com"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mt-2 w-full">
                            <div className="text-sm font-semibold lg:text-xl mb-1">Your Locality</div>
                            <input
                                className="px-2 py-3 text-sm w-full rounded-xl"
                                placeholder="Eg. Saket"
                                name="locality"
                                value={formData.locality}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mt-2 w-full">
                            <div className="text-sm font-semibold lg:text-xl mb-1">Your Phone Number</div>
                            <input
                                className="px-2 py-3 text-sm w-full rounded-xl"
                                placeholder="XXXXXXXXXX"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className={`mt-4`}>
                        <div className={`font-semibold lg:text-xl mb-1`}>I am a?</div>
                        <div className={`mt-2 flex gap-4 text-sm font-semibold text-primary whitespace-nowrap`}>
                            <div className={`flex w-full justify-between lg:text-lg`}>
                                <label className="flex items-center gap-2">
                                    Dietitian
                                    <CheckBoxComponent
                                        enabled={formData.category === 1}
                                        setEnabled={(checked) => handleCheckboxChange(1, checked)}
                                    />
                                </label>

                                <label className="flex items-center gap-2">
                                    PT Trainer
                                    <CheckBoxComponent
                                        enabled={formData.category === 2}
                                        setEnabled={(checked) => handleCheckboxChange(2, checked)}
                                    />
                                </label>

                                <label className="flex items-center gap-2">
                                    Gym Member
                                    <CheckBoxComponent
                                        enabled={formData.category === 3}
                                        setEnabled={(checked) => handleCheckboxChange(3, checked)}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="mt-4">
                        <div className="font-semibold mb-1">Your Message</div>
                        <textarea
                            className="h-28 w-full rounded-2xl p-2"
                            placeholder="Type your message here..."
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className={`flex w-full mt-[-1px] `}>
                    <div className={`bg-lightOlive rounded-b-3xl shadow-md pb-6 px-4 w-[70%] lg:w-[80%] pt-2 flex gap-4`}>
                        <div className={`whitespace-nowrap px-6 lg:px-6 py-2 rounded-full bg-lightSecondary font-semibold w-fit text-left text-[3.3vw] md:text-base`}>Get The Best Discounts!</div>
                        <div className={`whitespace-nowrap px-6 lg:px-10 py-2 rounded-full bg-lightSecondary font-semibold w-fit hidden lg:block`}>25% Off!</div>
                        {/* <div className={`px-0 lg:px-10 py-2 rounded-full bg-lightSecondary font-semibold w-fit hidden lg:block`}>Claim Now!</div> */}
                    </div>
                    <div className="relative w-[30%] lg:w-[20%]">
                        <div className={`getintouchbox left-[-1px] absolute bg-lightOlive flex justify-center items-center w-10 h-10 shadow-md`}></div>
                        <div className={`absolute flex justify-between items-center w-full h-full  rounded-tl-[50px] pl-4  text-tertiary `}>
                            <button onClick={handleSubmit} disabled={loading} className={`bg-purple font-semibold rounded-full w-full py-2 text-[3.5vw] md:text-base ${loading ? 'opacity-70' : ''}`}>
                                {loading ? "Sending..." : "Submit"}
                            </button>
                            {/* <button className={`bg-purple font-semibold rounded-full h-11 w-11 text-xl flex justify-center items-center`}>
                                <CallIcon color='#ffffff'  height='18px'/>
                            </button> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GetInTouch;

/**
 * Sends an email using EmailJS with provided parameters.
 *
 * @param {Object} params - Parameters for the email template.
 * @param {Function} setLoading - Function to toggle loading state.
 * @param {Function} setAlertMessage - Function to set alert message.
 * @param {Function} setShowAlert - Function to toggle alert visibility.
 */
const sendEmail = (params, setLoading) => {
    const serviceId = "service_bifr90d"; // Replace with your EmailJS service ID
    const templateId = "template_5iuoinr"; // Replace with your EmailJS template ID
    const userId = "UghyKy_Cpzm5G5PLp"; // Replace with your EmailJS user ID

    emailjs
        .send(serviceId, templateId, params, userId)
        .then(() => {
            Alert('success', "Message sent successfully!");
            setLoading(false);
        })
        .catch((error) => {
            console.error("Failed to send email:", error);
            Alert('error', "Failed to send your message. Please try again later.");
            setLoading(false);
        });
};
