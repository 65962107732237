import React, { useState } from 'react';
import { coupons } from '../../../assets/Coupons';
import { checkDiscountCodeValidity } from './DiscountHelpers';
import { checkReferralCodeValidity } from './ReferralCodeHelpers';
import Alert from '../../../components/customComponents/Alert';
import { Search } from 'lucide-react';

/**
 * CouponModal
 * Displays a modal for users to enter a coupon or referral code, or select an available coupon.
 * Includes functionality to validate codes, show success marks, and unselect conflicting selections.
 */
const CouponModal = ({ handleReferralCode, handleCouponSelection, setShowCouponModal, duration, gymData }) => {
    // States
    const [discountCode       ,        setDiscountCode] = useState(''); // User-entered discount code
    const [referralCode       ,        setReferralCode] = useState(''); // User-entered referral code
    const [selectedCoupon     ,      setSelectedCoupon] = useState(null); // Selected coupon from the list
    const [referralCodeDetails, setReferralCodeDetails] = useState({}); // Valid referral code details
    const [discountCodeDetails, setDiscountCodeDetails] = useState({}); // Valid discount code details
    const [isMobile           ,                       ] = useState(window.innerWidth <= 430)

    // Filter available coupons based on membership duration
    const filteredCoupons = coupons.filter(coupon => {
        return (
            coupon.minDuration <= duration &&
            (coupon.maxDuration === undefined || duration <= coupon.maxDuration)
        );
    });

    /**
     * Handles the submission of a discount code by validating it.
     * If valid, applies the discount and unselects any selected coupon.
     */
    const handleDiscountCodeSubmit = async () => {
        if(discountCode.trim() === '') {
            Alert('error', 'Please enter a discount code.')
            return;
        }

        const discountCoupon = await checkDiscountCodeValidity(discountCode.toUpperCase());
        if (Object.keys(discountCoupon).length > 0) {
            setDiscountCodeDetails(discountCoupon); // Store valid coupon details
            setSelectedCoupon(null); // Unselect any selected coupon
            handleCouponSelection(discountCoupon); // Apply the coupon
        } else {
            Alert('error', `This code doesn't exist or is expired.`);
        }
    };

    /**
     * Handles the submission of a referral code by validating it.
     * If valid, stores the details; otherwise, shows an error.
     */
    const handleReferralCodeSubmit = async () => {
        if(referralCode.trim() === '') {
            Alert('error', 'Please enter a referral code.')
            return;
        }
        
        const details = await checkReferralCodeValidity(referralCode);
        if (details?.isValid) {
            setReferralCodeDetails(details);
            handleReferralCode(details);
        } else {
            Alert('error', 'This referral code does not exist.');
        }
    };

    /**
     * Determines whether the "Done" button should be displayed.
     * It appears if the user has successfully added a referral code, selected a coupon, or entered a valid coupon code.
     */
    const shouldShowDoneButton =
        Object.keys(referralCodeDetails).length > 0 || Object.keys(discountCodeDetails).length > 0 || selectedCoupon;

    return (
        <div className="fixed inset-0 -top-8 flex items-center justify-center bg-black bg-opacity-60 z-50">
            <div className="relative bg-secondary text-tertiary p-4 sm:p-6 md:p-8 rounded-3xl shadow-lg w-10/12 lg:w-[40.5%] mx-auto">
                {/* Close Button */}
                <button
                    onClick={() => setShowCouponModal(false)}
                    className="absolute top-3 sm:top-4 right-3 sm:right-4 text-tertiary text-3xl sm:text-4xl hover:text-primary transition"
                >
                    &times;
                </button>

                {/* Coupons List */}
                <h2 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold text-center mb-3 sm:mb-4 md:mb-6">
                    {gymData.isExclusive ? 'Available Coupons' : 'Referral Code'}
                </h2>
                {gymData.isExclusive && (
                    <div className="space-y-3 sm:space-y-4 md:space-y-6">
                        {filteredCoupons.length > 0 ? (
                            filteredCoupons.map((coupon) => (
                                <div
                                    key={coupon.code}
                                    className={`border-2 ${
                                        selectedCoupon?.code === coupon.code
                                            ? 'border-tertiary bg-primary text-tertiary'
                                            : 'border-secondary bg-tertiary text-black'
                                    } p-3 sm:p-4 md:p-6 rounded-2xl flex flex-col sm:flex-row justify-between items-center cursor-pointer hover:bg-primary hover:text-tertiary transition`}
                                    onClick={() => {
                                        setSelectedCoupon(coupon); // Highlight the selected coupon
                                        setDiscountCodeDetails({}); // Unset discount code if a coupon is selected
                                        handleCouponSelection(coupon);
                                    }}
                                >
                                    <div className="text-center sm:text-left">
                                        <p className="font-bold text-base sm:text-lg md:text-xl">{coupon.description}</p>
                                        <p className="text-xs sm:text-sm md:text-base text-logoGray">Code: {coupon.code}</p>
                                    </div>
                                    <p className="text-lg sm:text-xl md:text-2xl text-secondary font-bold mt-2 sm:mt-0">
                                        {coupon.type === 'percentage' ? `${coupon.value}%` : `₹${coupon.value}`} off
                                    </p>
                                </div>
                            ))
                        ) : (
                            <p className="text-center text-logoGray text-sm sm:text-base md:text-lg">
                                No coupons available for this duration.
                            </p>
                        )}
                    </div>
                )}

                {/* Coupon Code Input */}
                {gymData.isExclusive && (
                    <div className="mt-4 sm:mt-6 md:mt-8 flex flex-row items-center justify-between space-x-2 md:space-x-4">
                        <label htmlFor="couponCode" className="text-base lg:text-xl whitespace-nowrap">
                            Have a coupon code?
                        </label>
                        <div className="flex items-center gap-1 md:gap-2 w-full">
                            <input
                                type="text"
                                id="couponCode"
                                className="w-full border border-tertiary rounded-2xl px-3 py-2 text-sm md:text-xl text-black"
                                value={discountCode}
                                onChange={(e) => setDiscountCode(e.target.value)}
                            />
                            {Object.keys(discountCodeDetails).length > 0 ? (
                                <div className="flex items-center gap-1 -ml-4 md:ml-0 max-h-12">
                                    {/* Success Checkmark */}
                                    <div className="success-checkmark scale-[30%] md:scale-[40%] pt-4">
                                        <div className="check-icon a">
                                            <span className="icon-line line-tip"></span>
                                            <span className="icon-line line-long"></span>
                                            <div className="icon-circle"></div>
                                            <div className="icon-fix"></div>
                                        </div>
                                    </div>
                                    {/* Remove Coupon Button */}
                                    <button
                                        onClick={() => {
                                            setDiscountCode('');
                                            setDiscountCodeDetails({});
                                        }}
                                        className="bg-red-500 text-white rounded-full -ml-4 md:ml-0 lg:px-1 text-lg md:text-3xl transition"
                                    >
                                        ×
                                    </button>
                                </div>
                            ) : (
                                <button
                                    onClick={handleDiscountCodeSubmit}
                                    className="bg-primary text-tertiary rounded-2xl px-2 py-2 md:px-6 md:py-2 text-sm md:text-xl hover:bg-tertiary hover:text-primary transition"
                                >
                                    {isMobile ? <Search /> : 'Check'}
                                </button>
                            )}
                        </div>
                    </div>
                )}

                {/* Referral Code Input */}
                <div className="mt-4 sm:mt-6 md:mt-8 flex flex-row items-center justify-between space-x-2 md:space-x-4">
                    <label htmlFor="referralCode" className="text-base lg:text-xl whitespace-nowrap">
                        Have a Referral code?
                    </label>
                    <div className="flex items-center gap-1 md:gap-2 w-full">
                        <input
                            type="text"
                            id="referralCode"
                            className="w-full border border-tertiary rounded-2xl px-3 py-2 text-sm md:text-xl text-black"
                            value={referralCode}
                            onChange={(e) => setReferralCode(e.target.value)}
                        />
                        {Object.keys(referralCodeDetails).length > 0 ? (
                            <div className="flex items-center gap-1 -ml-4 md:ml-0">
                                {/* Success Checkmark */}
                                <div className="success-checkmark scale-[30%] md:scale-[40%] pt-4">
                                    <div className="check-icon">
                                        <span className="icon-line line-tip"></span>
                                        <span className="icon-line line-long"></span>
                                        <div className="icon-circle"></div>
                                        <div className="icon-fix"></div>
                                    </div>
                                </div>
                                {/* Remove Referral Button */}
                                <button
                                    onClick={() => {
                                        setReferralCode('');
                                        setReferralCodeDetails({});
                                    }}
                                    className="bg-red-500 text-white rounded-full -ml-4 md:ml-0 lg:px-1 text-lg md:text-3xl hover:bg-red-600 transition"
                                >
                                    ×
                                </button>
                            </div>
                        ) : (
                            <button
                                onClick={handleReferralCodeSubmit}
                                className="bg-primary text-tertiary rounded-2xl px-2 py-2 md:px-6 md:py-2 text-sm md:text-xl hover:bg-tertiary hover:text-primary transition"
                            >
                                {isMobile ? <Search /> : 'Check'}
                            </button>
                            )}
                    </div>
                </div>

                {/* Done Button */}
                {shouldShowDoneButton && (
                    <div className="mt-6 flex justify-center">
                        <button
                            onClick={() => setShowCouponModal(false)}
                            className="bg-primary text-tertiary rounded-2xl px-6 py-2 text-base sm:text-lg md:text-xl hover:bg-tertiary hover:text-primary transition"
                        >
                            Done
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CouponModal;
