import React, { useRef, useState } from "react";
// import { useLocation } from "react-router-dom";
import Header from "../../helperFunctions/ResultsPageHelper/Header";
import DaySelector from "../../helperFunctions/ResultsPageHelper/DaySelector";
import SectionNav from "../../helperFunctions/ResultsPageHelper/SectionNav";
import DietPlan from "../../helperFunctions/ResultsPageHelper/DietPlan";
import ExercisePlan from "../../helperFunctions/ResultsPageHelper/ExcercisePlan";
import Recommendations from "../../helperFunctions/ResultsPageHelper/Recommendations";
import RegeneratePopup from "../../helperFunctions/ResultsPageHelper/RegeneratePopup";
import { useEffect } from "react";
import { fetchAllGyms } from "../../helperFunctions/GymInfoHelpers/FetchGymsList";
import FitnessMetrics from "../../helperFunctions/ResultsPageHelper/FitnessMetrics";
import { cloud_url } from "../../assets/links";
import { doc, getDoc, updateDoc } from "@firebase/firestore";
import { auth, db } from "../../config/firebase";
import UserFitnessForm from "../../helperFunctions/AIandMore/UserFitnessForm/UserFitnessForm";
import Alert from "../../components/customComponents/Alert";

const ResultsPage = ({showUnlockGym, setShowUnlockGym}) => {
    // Fetch navigation and data passed via location state
    // const location = useLocation();
    // const details = location.state || {};
    const [USR             ,              setUSR] = useState(window.devicePixelRatio);
    const [                ,          setLoading] = useState(false);     
    // State for weekly plan, selected day, active section, and regeneration popup
    const [weeklyPlan      ,       setWeeklyPlan] = useState([]);
    const [listOfGyms      ,       setListOfGyms] = useState([]);
    const [userDetails     ,      setUserDetails] = useState(null);
    const [activeSection   ,    setActiveSection] = useState("diet");
    const [selectedDayIndex, setSelectedDayIndex] = useState(0);

    const [regeneratePopup ,  setRegeneratePopup] = useState({
        visible: false,
        itemType: null,
        mealKey: null,
        userPrompt: "",
    });

    // References for scrolling to specific sections
    const dietRef = useRef(null);
    const exerciseRef = useRef(null);
    const recommendationsRef = useRef(null);
    const refs = {
        diet: dietRef,
        exercise: exerciseRef,
        recommendations: recommendationsRef,
    };

    // Resize
    useEffect(() => {
        const handleResize = () => {
            const newResolution = window.devicePixelRatio;
            setUSR((prevResolution) => {
                if (prevResolution !== newResolution) {
                    return newResolution;
                }
                return prevResolution;
            });
        };

        // console.log("Device Pixel Ratio: ", devicePixelRatio);
        // console.log("Screen Width (Physical Pixels): ", window.screen.width);
        // console.log("Viewport Width (DIPs): ", window.innerWidth);
        // console.log("${USR >= 1.5 ? '' : USR === 1 ? '' : USR === 1.25 ? '' : ''}")
        // Add event listener for resize
        window.addEventListener("resize", handleResize);

        // Cleanup listener on unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const [, setUserDetailsLoading] = useState(false);

    // Fetches user plan if available
    useEffect(() => {
        const fetchUserDetails = async (userId) => {
            try {
                const userDoc = await getDoc(doc(db, "user", userId, "plan", "details"));
                if (userDoc.exists()) {
                    setUserDetails(userDoc.data().userDetails);
                    setWeeklyPlan(userDoc.data().weeklyPlan || []);
                } else {
                    console.warn("No user details found.");
                }
            } catch (error) {
                console.error("Error fetching user details:", error);
            } finally {
                setUserDetailsLoading(false);
            }
        };
    
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setUserDetailsLoading(true);
                fetchUserDetails(user.uid);
            } else {
                setUserDetails(null);
            }
        });
    
        return () => unsubscribe();
    }, []);

    // Fteches Gyms and classes
    useEffect(() => {
        setLoading(true);
        const fetchGymsAndClasses = async () => {
            // const classes = await getAllClasses(selectedCity);
            // setClassList(classes);
            if (userDetails !== null) {
                const allGyms = await fetchAllGyms(userDetails.area);
                setListOfGyms(allGyms);
                setLoading(false);
            }
        };
        fetchGymsAndClasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDetails?.area]);

    const regeneratePlanItem = async ({
        dayName,
        itemType,
        mealKey,
        userData,
        weeklyPlan,
        userPrompt,
    }) => {
        try {
            const response = await fetch(`${cloud_url}/regenerate_plan_item`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    dayName,
                    itemType,
                    mealKey,
                    userData,
                    weeklyPlan,
                    userPrompt,
                }),
            });

            if (!response.ok) {
                return { status: "error", message: "Network error or server returned an error." };
            }

            return await response.json();
        } catch (error) {
            console.error("Regenerate error:", error);
            return { status: "error", message: "Failed to call the server." };
        }
    };

    const handleRegenerate = async (itemType, userPrompt, mealKey) => {
        const dayName = weeklyPlan[selectedDayIndex]?.day;

        try {
            const result = await regeneratePlanItem({
                dayName,
                itemType,
                mealKey,
                userData: userDetails,
                weeklyPlan,
                userPrompt,
            });

            if (result.status === "success") {
                const updatedPlan = [...weeklyPlan];
                if (itemType === "meal" && mealKey) {
                    updatedPlan[selectedDayIndex].meals[mealKey] = result.newPlan;
                }
                
                setWeeklyPlan(updatedPlan);

                 // Update Firestore with the new weekly plan
                const userPlanRef = doc(db, "user", auth.currentUser.uid, "plan", "details");
                await updateDoc(userPlanRef, { weeklyPlan: updatedPlan });

            } else {
                Alert('error', "Failed to regenerate: " + result.message);
            }
        } catch (error) {
            console.error("Error during regeneration:", error);
        } finally {
            setRegeneratePopup({ visible: false, itemType: null, mealKey: null, userPrompt: "" });
        }
    };

    const handleScrollToSection = (sectionRef, sectionName) => {
        if (sectionRef?.current) {
            // Get the element's position relative to the viewport
            const sectionTop = sectionRef.current.getBoundingClientRect().top + window.scrollY;
            
            // Scroll smoothly to 300px above the section
            window.scrollTo({
                top: sectionTop - 160,
                behavior: "smooth",
            });
    
            // Optionally update active section in parent component
            setActiveSection(sectionName);
        }
    };

    return (
        <div className={`bg-gradient-to-b from-[#AF9FCA] to-transparent pt-[120px] lg:pt-24 min-h-screen ${USR >= 1.5 ? 'lg:px-6 ' : USR <= 1 ? 'lg:px-44 ' : USR === 1.25 ? 'lg:px-20 ' : 'lg:px-40 '}`}>
            {/* Render Header */}
            <Header />

            {/* {console.log(123, userDetails, auth.currentUser?.uid)} */}
            {userDetails === null ? (
                <div className="flex justify-center items-center h-screen">
                    Loading ...
                </div>
            ) : (
                <>
                    {/* Fitness Metrics */}
                    <FitnessMetrics userDetails={userDetails} />

                    {/* Render Day Selector */}
                    <DaySelector
                        weeklyPlan={weeklyPlan}
                        selectedDayIndex={selectedDayIndex}
                        setSelectedDayIndex={setSelectedDayIndex}
                    />

                    {/* Render Section Navigation */}
                    <SectionNav
                        activeSection={activeSection}
                        handleScrollToSection={handleScrollToSection}
                        refs={refs}
                    />

                    {/* Render Diet Plan */}
                    <DietPlan
                        ref={dietRef}
                        weeklyPlan={weeklyPlan}
                        selectedDayIndex={selectedDayIndex}
                        setRegeneratePopup={setRegeneratePopup}
                    />

                    {/* Render Exercise Plan */}
                    <ExercisePlan
                        ref={exerciseRef}
                        weeklyPlan={weeklyPlan}
                        selectedDayIndex={selectedDayIndex}
                    />

                    {/* Render Recommendations */}
                    <Recommendations ref={recommendationsRef}  listOfGyms={listOfGyms} USR={USR} />

                    {/* Render Regenerate Popup if visible */}
                    {regeneratePopup.visible && (
                        <RegeneratePopup
                            regeneratePopup={regeneratePopup}
                            setRegeneratePopup={setRegeneratePopup}
                            handleRegenerate={handleRegenerate}
                        />
                    )}
                </>
            )}

            {/* Inside the modal, we show the multi-step form */}
            <UserFitnessForm
                isModalOpen={showUnlockGym}
                onClose={() => setShowUnlockGym(false)}   // so we can close the modal from inside the form if needed
                
            />
        </div>
    );
};

export default ResultsPage;
