import { useNavigate } from 'react-router-dom';
import Card from '../../components/Card';
import { forwardRef } from 'react';

/**
 * Shows List of all top 3 gyms of the city
 * @param {number} USR - screen resolution , 1 === 100%, 1.25 = 125%, 1.5 = 150% resolution
 * @returns {JSX.Element} 
 */
const ExploreGyms = forwardRef(({ listOfGyms, USR }, ref) => {    // Get the top 3 gyms from the list
    const topGyms = listOfGyms.slice(0, 3);

    const navigate = useNavigate(); // Initialize the navigate function

    const city = listOfGyms[0]?.city;

    return (
        <div ref={ref} className='flex flex-col mt-4 mb-12 lg:mt-20 gap-1 lg:gap-4'>
            <div className={` text-xl ${USR >= 1.5 ? 'lg:text-2xl' : USR <= 1 ? 'lg:text-4xl' : USR === 1.25 ? 'lg:text-2xl' : 'lg:text-3xl'} font-bold`}>
                Explore <button disabled={city === undefined} onClick={() => {
                    if (listOfGyms.length > 0) {
                        navigate(`/${city}/gyms`, { state: listOfGyms });
                    } else {
                        navigate(`/${city}/gyms`);
                    }
                }} className='text-secondary'>Gyms</button> near you
            </div>
            <div className={`gap-10 flex`}>
            </div>
            { topGyms.length > 0 &&
                <div className={`pl-4 lg:pl-0 overflow-x-scroll lg:overflow-x-visible flex justify-between gap-4  ${USR >= 1.5 ? '' : USR <= 1 ? 'lg:gap-20' : USR === 1.25 ? 'lg:gap-10' : 'lg:gap-16'}`}>
                    {topGyms.map((gym, index) => (
                        <Card key={gym.gymID || index} cardData={gym} USR={USR} />
                    ))}
                </div>
            }
        </div>
    );
});

export default ExploreGyms;
