/**
 * classModal.js
 * Modal to book classes
 *
 * This component renders a modal for booking classes at a gym. It displays a calendar
 * highlighting the days the class is scheduled and allows the user to select a date
 * and proceed with the purchase. The modal layout adapts for mobile screens, showing
 * the calendar on top and the class details with buttons below.
 */

import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './../../../CustomCss.css';
import Alert from '../../customComponents/Alert';

/**
 * ClassModal Component
 * @param   {Object} classDeets         -   Details about the class (name, schedule, days, price)
 * @param   {Object} gymDeets           -   Details about the gym offering the class
 * @param   {Function} onClose          -   Function to close the modal
 * @param   {Function} handleClassBuy   -   Function to tackle buying the class
 * @returns {JSX.Element}               -   Class booking modal
 */
export const ClassModal = ({ classDeets = {}, gymDeets = {}, onClose, handleClassBuy }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    
    // Destructure class details with fallback values to avoid destructuring errors
    const { className = '', days = [], price = 0, classSchedule = {} } = classDeets;

    /**
     * Handles date selection from the calendar
     * @param {Date} date - Selected date
     */
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    /**
     * Handles the "Buy Now" button click
     * - Alerts the selected date and closes the modal
     * - Prompts user if no date is selected or invalid
     */
    const handleBuyNow = () => {
        const isValidDate = tileClassName({ date: selectedDate }, days);
        if (selectedDate && isValidDate) {
            handleClassBuy({
                selectedDate, 
                classTime       :   classSchedule[selectedDate.toLocaleDateString('en-US', { weekday: 'long' })],
                amount          :   price,    //  Storing Amount in Paise
                className       :   className,
                weekdays        :   days,
                classSchedule   :   classSchedule,
            });
            onClose();
        } else {
            Alert('error', 'Please select a valid date for the class.');
        }
    };

    return (
        <div className="fixed -inset-10 z-50 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-secondary text-primary border-complementPurple border-2 rounded-lg shadow-xl p-10 flex flex-col lg:flex-row w-fit h-auto lg:h-fit relative">
                <button 
                    className="absolute top-2 right-4 text-white hover:text-primary text-4xl"
                    onClick={onClose}
                >
                    &times;
                </button>
                {/* Left Section - Calendar */}
                <div className="w-full lg:w-1/2 p-4">
                    <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        inline
                        calendarClassName="custom-calendar bg-secondary text-primary border-complementPurple border-2 rounded-lg shadow-lg"
                        dayClassName={(date) => tileClassName({ date }, days) ? "custom-day bg-complementPurple text-white rounded-full" : "custom-day text-gray-800 hover:bg-primary hover:text-white rounded-full"}
                        headerClassName="custom-header bg-tertiary text-white font-bold py-2 rounded-t-lg"
                    />
                </div>
                
                {/* Right Section - Class Details */}
                <div className="w-full lg:w-1/2 p-4 flex flex-col justify-between">
                    <div>
                        <h2 className="text-tertiary font-bold text-2xl mb-4">{className} at {gymDeets.gymDisplayName || 'Gym'}</h2>
                        <p className="text-lg font-bold mb-4">Price: Rs {price}</p>
                        {selectedDate ? (
                            <p className="text-md text-primary font-bold">
                                Class Time: {classSchedule[selectedDate.toLocaleDateString('en-US', { weekday: 'long' })] || 'No Classes On This Day'}
                            </p>
                        ) : (
                            <p className="text-md text-primary">Please select a date to view class time.</p>
                        )}
                    </div>
                    <div className="flex flex-col gap-4 items-center mt-6">
                    <button className="w-full bg-tertiary text-primary px-6 py-2 rounded-lg hover:bg-complementPurple hover:text-white" onClick={handleBuyNow}>Buy Now</button>
                    <button className="w-full bg-tertiary text-primary px-6 py-2 rounded-lg hover:bg-complementPurple hover:text-white" onClick={onClose}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

/**
 * Highlights the calendar tiles corresponding to class days
 * @param {Object} param - React Calendar tile props
 * @param {Date} param.date - Date of the tile
 * @param {Array} days - Array of class days (e.g., ['Tuesday', 'Thursday'])
 * @returns {string|null} - CSS class to highlight the tile if it matches a class day
 */
export const tileClassName = ({ date }, days) => {
    /**
     * Returns the local day of the week instead of UTC
     * @type {Array}
     */
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayOfWeek = dayNames[date.getDay()]; // Use getDay() for local time instead of getUTCDay()

    return days.includes(dayOfWeek) ? 'highlight' : null;
};
