export const checkIfUserHasNecessaryData = (user) => {
    const requiredFields = ['phoneNumber', 'gender', 'email', 'displayName'];
  
    const fieldStatus = requiredFields.reduce((status, field) => {
      status[field] = Boolean(user?.[field] && user[field] !== '');
      return status;
    }, {});
  
    // Add `showDetailsModal` based on any missing fields
    fieldStatus.showGetUserMissingDetailsModal = Object.values(fieldStatus).includes(false);
  
    return fieldStatus;
};
  
