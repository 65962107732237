import React from "react";

const DaySelector = ({ weeklyPlan, selectedDayIndex, setSelectedDayIndex }) => (
    <div className="flex overflow-x-auto px-4 py-2 mb-4 gap-2 lg:justify-center lg:max-w-[90vw]">
        {/* Iterate through the days in the weekly plan */}
        {weeklyPlan.map((day, index) => (
            <button
                key={index}
                onClick={() => setSelectedDayIndex(index)}
                className={`px-4 py-2 rounded-xl ${
                    selectedDayIndex === index ? "bg-secondary text-white" : "bg-gray-200 text-black"
                } transition hover:bg-secondary hover:text-white`}
            >
                {/* Display day name */}
                {day.day}
            </button>
        ))}
    </div>
);

export default DaySelector;
