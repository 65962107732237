import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllTags } from "../../helperFunctions/DiscussionForumHelpers/GetAllTags";
import { sortPills } from "../../helperFunctions/basicHelper";

// const PillsContent = [
//     {content: 'motivation'},
//     {content: 'lifestyle'},
//     {content: 'mindfulness'},
//     {content: 'exercise'},
//     {content: 'meditation'},
//     {content: 'health'},
//     {content: 'training'},
//     {content: 'yoga'},
//     {content: 'muscle building'},
//     {content: 'weightloss'},
//     {content: 'cardio'},
//     {content: 'hypertrophy'},
// ]

const Pills = () => {
    const navigate = useNavigate();
    const [pills, setPills] = useState([]);
    const fetchAndFormatPills = async () => {
            try {
                const data = await getAllTags();
                const sortedData = sortPills(data);
                const formattedPills = sortedData
                    .slice(0, 12)
                    .map((tag, index) => ({
                        color:
                            index % 2 === 0
                                ? "text-purple bg-lightPurple border-purple"
                                : "text-secondary bg-lightSecondary border-secondary",
                        text: tag.tag,
                        usedCount: tag.usedCount
                    }));
                setPills(formattedPills);
            } catch (error) {
                console.error("Error fetching tags:", error);
                setPills([]);
            }
        };

        useEffect(() => {
            fetchAndFormatPills();
        }, [])

    return (
        <>
            <div className={`p-4 lg:px-8 lg:pb-8 rounded-3xl w-full h-full text-center bg-lightOlive shadow-md flex flex-col justify-between`}>
                <div className={`font-semibold text-2xl lg:text-5xl lg:pt-4`}>Looking for something in<br />particular?</div>
                
                {/* Pills */}
                <div className={`flex flex-wrap gap-1 lg:gap-4 mt-8`}>
                    {pills.map((pill, index) => (
                        <div onClick={() => navigate(`/forum`, { state: pill?.text })} key={index} className={`cursor-pointer px-3 py-[2px] lg:px-4 lg:py-2 rounded-full text-sm lg:text-xl font-semibold border-2 ${index%2 === 0 ? 'text-purple bg-lightPurple border-purple' : 'text-secondary bg-lightSecondary border-secondary'}`}>
                            {pill?.text}
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Pills;

// const Pills = () => {
//     return (
//         <>
//             <div className="text-center my-10">Pills</div>
//         </>
//     )
// }

// export default Pills;