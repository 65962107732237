import Modal from "react-modal";
import { useState } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { addNewUserToDatabase } from "../helperFunctions/userDatabaseHelper";
import { getCountryCode } from "../config/countryCodes";
import { sendEmailToUserRoute } from "../helperFunctions/MessagesAndEmails/messageHelper";

/**
 * GoogleSignUpForm Component
 * 
 * A premium-styled modal component for users who sign in with Google.
 * Users can enter their date of birth, gender, and fitness level. If a phone number
 * is not provided by Google, they can enter it manually.
 * 
 * @param {boolean} isOpen - Determines if the modal is open.
 * @param {Function} onClose - Callback function to close the modal.
 * @param {Object} user - The signed-in user object from Google.
 * @param {Function} onSubmit - Function to handle form submission.
 * @param {Function} resetForm - Function to reset the Form
 * 
 * @returns {JSX.Element} - Rendered GoogleSignUpForm modal.
 */
const GoogleSignUpForm = ({ isOpen, onClose, user, googleDetails, resetForm }) => {
    const [dob, setDob] = useState("");
    const [gender, setGender] = useState("");
    const [fitnessLevel, setFitnessLevel] = useState("");
    const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || "");

    /**
     * Handles form submission.
     * Gathers input data and stores it in the database.
     * 
     * @param {Event} e - Form submission event.
     */
    const handleSubmit = async (e) => {
        e.preventDefault();
        const success = await addNewUserToDatabase(user.uid, {
            dob,
            gender,
            fitnessLevel,
            phoneNumber     : `${getCountryCode()}${phoneNumber}`,
            email           : googleDetails?.email,
            name            : googleDetails?.displayName,
            displayName     : googleDetails?.displayName,
            profilePic      : googleDetails?.photoUrl,
        });
        if (success) {
            toast.success("Sign Up Successful!");
            sendEmailToUserRoute(user, user.displayName, 'signUp');
        }
        else toast.error("Sign Up Failure!");
        onClose();
        resetGoogleForm();
        resetForm();
    };

    /**
     * Resets the form fields.
     */
    const resetGoogleForm = () => {
        setDob("");
        setFitnessLevel("");
        setGender("");
        setPhoneNumber(user?.phoneNumber || "");
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Google Sign Up"
            className="fixed inset-0 flex items-center justify-center z-50"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-50"
        >
            <div className="bg-secondary rounded-2xl shadow-2xl max-w-lg w-full p-8 relative border-2 border-purple">
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 text-purple hover:text-purple transition text-2xl"
                >
                    &times;
                </button>
                <h2 className="text-2xl font-bold text-center text-black mb-6">Complete Your Profile</h2>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <input
                        type="text"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                        placeholder="Date of Birth (DD-MM-YYYY)"
                        pattern="\d{2}-\d{2}-\d{4}"
                        required
                        className="w-full p-3 rounded-3xl border border-purple bg-white focus:outline-none focus:ring-2 focus:ring-purple"
                    />
                    <select
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                        required
                        className="w-full p-3 rounded-3xl border border-purple bg-white focus:outline-none focus:ring-2 focus:ring-purple"
                    >
                        <option value="" disabled>Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                    </select>
                    <select
                        value={fitnessLevel}
                        onChange={(e) => setFitnessLevel(e.target.value)}
                        required
                        className="w-full p-3 rounded-3xl border border-purple bg-white focus:outline-none focus:ring-2 focus:ring-purple"
                    >
                        <option value="" disabled>Fitness Level</option>
                        <option value="Beginner">Beginner</option>
                        <option value="Intermediate">Intermediate</option>
                        <option value="Advanced">Advanced</option>
                    </select>
                    {!user?.phoneNumber && (
                        <input
                            type="text"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            placeholder="Enter Phone Number"
                            required
                            className="w-full p-3 rounded-3xl border border-purple bg-white focus:outline-none focus:ring-2 focus:ring-purple"
                        />
                    )}
                    <button
                        type="submit"
                        className="w-full py-3 rounded-3xl bg-purple text-white font-semibold hover:bg-complementPurple transition-all"
                    >
                        Submit
                    </button>
                </form>
            </div>
        </Modal>
    );
};

GoogleSignUpForm.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default GoogleSignUpForm;
