import { collection, getDocs } from "@firebase/firestore";
import { removeSpacesAndConvertLowercase } from "../basicHelper";
// import { sortGyms } from "../MarketplaceHelpers/GymsSorting";
import { db } from "../../config/firebase";
import { cityList, gymList } from "../../components/Databases";
import { getGymProps } from "./getGymProps";
import { sortBySmartCriteria } from "../AIandMore/GymScoringAlgorithm";

/**
 * Defined Variables
 */
const filteredGyms = [
    '21fitness',
    'notlisted',
    'arnavgym',
]
// Predefined list of NCR cities to be iterated over when city is 'ncr'
const NCR_CITIES = ['ghaziabad', 'noida', 'delhi'];

/**
 * Fetch all gyms based on the provided city input.
 * 
 * - If the city is 'ncr', the function will iterate over predefined NCR cities and fetch their gyms.
 * - If the city is not 'ncr', it will fetch gyms only for the specified city.
 * 
 * @param {string} city                 -   The city for which gym data needs to be fetched. If 'ncr', gyms from all NCR cities are fetched.
 * @param {Location} userLocation       -   Location of the user for sorting purposes
 * @returns {Promise<Array>}            -   Returns a promise that resolves to an array of gym objects.
 */
export const fetchAllGyms = async (city, userLocation) => {
    
    // get city name in the firebase form
    city = removeSpacesAndConvertLowercase(city);
    const gymListData = []; // Initialize an empty array to store gym data

    // If city is 'ncr', fetch gyms for all cities in the NCR region
    if (city === 'ncr') {
        for (const ncrCity of NCR_CITIES) {
            await fetchCityGyms(ncrCity, gymListData);
        }
    } else {
        // Otherwise, fetch gyms for the specified city
        await fetchCityGyms(city, gymListData);
    }

    // return sortGyms(gymListData); // Return the list of fetched gyms
    return sortBySmartCriteria(gymListData, {budget : [0, 100000]}, userLocation);
    
};

/**
 * Helper function to fetch gym data for a specific city and add it to the provided array.
 * 
 * @param {string} cityName          - The name of the city for which gyms are being fetched.
 * @param {Array} gymListData        - The array where fetched gym data will be added.
 * @returns {Promise<void>}          - Returns a promise that resolves when gym data has been fetched.
 */
const fetchCityGyms = async (cityName, gymListData) => {
    try {

        const cityGymRef = collection(db, cityList, cityName, gymList);
        const cityGymSnapshot = await getDocs(cityGymRef);

        // Loop through each document in the snapshot (each representing a gym)
        for (const gym of cityGymSnapshot.docs) {
            const gymData = await gym.data();

            // Filtered gyms will skipped if its not development moode
            if (process.env.NODE_ENV !== 'development') {
                if (filteredGyms.includes(gymData.gymName)) continue;
            }
            
            // Add gym data to the gymListData array
            gymListData.push(await getGymProps(gymData));
            
        }
    } catch (error) {
        console.log('error in fetchCityGyms : ', error);
    }
};