import { useState } from "react";
import { updateUserDetails } from "../helperFunctions/userDatabaseHelper";
import Alert from "../components/customComponents/Alert";
import { getCountryCode } from "../config/countryCodes";

const GetUserMissingDetailsModal = ({missingDetails, setShowGetUserMissingDetailsModal, userId}) => {
    const [phoneNumber, setPhoneNumber] = useState(getCountryCode());
    const [gender     , setGender     ] = useState('');
    const [email      , setEmail      ] = useState('');
    const [displayName, setDisplayName] = useState('');
    
    const handleSubmit = async () => {
        const userDetails = {};
        const countryCode = getCountryCode();
      
        // Ensure phone number includes the country code
        if (missingDetails?.phoneNumber === false) {
          userDetails.phoneNumber =
            phoneNumber.length === 10 ? `${countryCode}${phoneNumber}` : phoneNumber;
        }
      
        if (missingDetails?.gender === false) userDetails.gender = gender;
        if (missingDetails?.email === false) userDetails.email = email;
        if (missingDetails?.displayName === false) userDetails.displayName = displayName;
      
        if (Object.keys(userDetails).length > 0) {
            await updateUserDetails(userId, userDetails);
            Alert('success', 'Your details have been saved successfully!');
            
            // Delay reloading slightly to let the alert render
            setTimeout(() => {
              window.location.reload();
            }, 500);
          }
      
        setShowGetUserMissingDetailsModal(false);
      };

    console.log("Missing Details : ", missingDetails)
    
    return (
        <div className="fixed z-[80] inset-0 flex items-center justify-center bg-black bg-opacity-30">
            <div className="bg-white p-8 rounded-2xl shadow-lg w-full max-w-lg">
                <h2 className="text-xl font-semibold text-gray-800 mb-4">Complete Your Profile</h2>
            
                <div className="space-y-4">
                    {missingDetails?.phoneNumber === false && (
                        <div>
                        <label className="block text-sm font-medium text-gray-700">Phone Number</label>
                        <input
                            type="tel"
                            value={phoneNumber}
                            placeholder="Eg. +91XXXXXXXXXX"
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
                        />
                        </div>
                    )}
                    {missingDetails?.gender === false && (
                        <div>
                        <label className="block text-sm font-medium text-gray-700">Gender</label>
                        <select
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
                        >
                            <option value="">Select Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>
                        </div>
                    )}
                    {missingDetails?.email === false && (
                        <div>
                        <label className="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            type="email"
                            value={email}
                            placeholder="Eg. abc@gmail.com"
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
                        />
                        </div>
                    )}
                    {missingDetails?.displayName === false && (
                        <div>
                        <label className="block text-sm font-medium text-gray-700">Display Name</label>
                        <input
                            type="text"
                            value={displayName}
                            placeholder="Eg. John Doe"
                            onChange={(e) => setDisplayName(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
                        />
                        </div>
                    )}
                    </div>
            
                    <div className="mt-6 flex justify-end gap-4">
                    <button
                        onClick={() => setShowGetUserMissingDetailsModal(false)}
                        className="px-4 py-2 bg-gray-300 text-black rounded-md"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleSubmit}
                        className="px-4 py-2 bg-primary text-white rounded-md"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};
    
export default GetUserMissingDetailsModal;