import { convertTo12HourFormat } from "../../helperFunctions/basicHelper";

const StudioTimings = ({ classesSchedule, duration }) => {
    const weekdayOrder = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ];

    // Sort days based on the weekdayOrder
    const sortedDays = Object.keys(classesSchedule).sort(
        (a, b) => weekdayOrder.indexOf(a.charAt(0).toUpperCase() + a.slice(1)) - 
                  weekdayOrder.indexOf(b.charAt(0).toUpperCase() + b.slice(1))
    );

    return (
        <div className="flex flex-col border-2 rounded-3xl border-black text-left overflow-hidden">
            {sortedDays.map((day, index) => (
                <div
                    key={day}
                    className={`${index % 2 === 0 ? "bg-gray-200" : ""} mt-1 flex flex-col py-3 px-2`}
                >
                    <h2 className="text-xl font-bold">
                        {day.charAt(0).toUpperCase() + day.slice(1)}
                    </h2>
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-2 lg:gap-x-60 text-sm lg:text-base">
                        {Object.keys(classesSchedule[day]).map((className) => (
                            <div key={className} className="flex flex-col mt-2">
                                <h3 className="font-semibold whitespace-nowrap">
                                    {className.charAt(0).toUpperCase() + className.slice(1)}
                                    <span className="text-sm font-normal text-gray-600 ml-2">
                                        ({duration[className] || "N/A"} mins)
                                    </span>
                                </h3>
                                <div className="flex justify-between w-[80%] lg:w-full rounded-full">
                                    {classesSchedule[day][className].map((time, index) => (
                                        <div key={index} className="">
                                            {convertTo12HourFormat(time)}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default StudioTimings;
