import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchStudio } from "../../helperFunctions/StudioClassesHelper/StudioClassesHelper";
import { getDailyTips } from "../../helperFunctions/MarketplaceHelpers/MarketplaceHelper";
import StudioPortfolio from "../../components/StudioPageComponents/StudioImages";
import StudioInfo from "../../components/StudioPageComponents/StudioInfo";
import StudioTimings from "../../components/StudioPageComponents/StudioTimings";
import { gymConstraintsChecker } from "../../helperFunctions/ConstraintsChecker";
import useAuthStatus from "../../hooks/clientAuth";
import { removeSpacesAndConvertLowercase } from "../../helperFunctions/basicHelper";
import StudioMembershipPrices from "../../components/StudioPageComponents/StudioMembershipPrices";
import StudioRatingAndReviews from "../../components/StudioPageComponents/StudioRatingsAndReviews";
import { UserLoginModal } from "../../helperFunctions/ModalHelpers/UserCreds";
import GymDetailsComponent from "../../helperFunctions/MarketplaceHelpers/gymDataHelpers/GymDetailsComponent";
import Alert from "../../components/customComponents/Alert";
import { handlePayment } from "../../helperFunctions/RazorpayHelper";
import PaymentLoadingModal from "../../components/PaymentLoadingModal";
import PaymentConfirmationModal from "../../Modals/PaymentConfirmationModal";
import { rsSymbol } from "../../assets/Symbols";
import { specialOffersOfGym } from "../../helperFunctions/GymPageHelpers/SpecialGyms";
import LoadingSpinner from "../../components/LoadingSpinner";
import { ArrowLeft } from "lucide-react";

const StudioPage = () => {
    const location                          = useLocation();
    const navigate                                = useNavigate();
    const {user            ,       isUserPresent} = useAuthStatus();
    const {city            ,          studioSlug} = useParams();
    const [myData          ,           setMyData] = useState();
    const [signInModal     ,      setSignInModal] = useState();
    const [loading         ,          setLoading] = useState(true);
    const [isLoading       ,        setIsLoading] = useState(false);
    // setting class data in the state if it came from anywhere in the website
    const [currStudioData  ,   setCurrStudioData] = useState(location?.state?.props || {});
    const [USR             ,              setUSR] = useState(window.devicePixelRatio);
    const [selectedCategory, setSelectedCategory] = useState()

    //Payment stuff
    const [membershipDetails  , setMembershipDetails  ] = useState(null);   // Stores membership details for the summary modal
    const [billInfoArray      , setBillInfoArray      ] = useState({});
    const [totalMemberships   , setTotalMemberships   ] = useState(1);
    const [priceToPayGym      , setPriceToPayGym      ] = useState(0);
    const [summaryModal       , setSummaryModal       ] = useState(false);
    const [pendingPayment     , setPendingPayment     ] = useState();
    const [successModal       , setSuccessModal       ] = useState();
    const [isPaymentProcessing, setIsPaymentProcessing] = useState();
    const params = useParams();

    // to fetch the data if the data did not come through props
    useEffect(() => {
        if(currStudioData?.country) {
            setLoading(false);
        } else {
            setLoading(true);
            const fetch = async () => {
                const data = await fetchStudio(params?.studioSlug, params?.city);
                setCurrStudioData(data);
                setLoading(false);
            }
            fetch();
        }
    }, [currStudioData?.country, params?.studioSlug, params?.city])

    

    const toggleSummaryModal = async (months, amountToPay, mrp, selectedClass, selectedCategory, exclusivePrice = 0) => {
        // This function has access to the class private states
        // Thus shouldnt be taken out
        if (currStudioData?.isWomensOnly) {
            // If it is women only than access the user Details
            setIsLoading(true);
            let userDataForConstraints = await gymConstraintsChecker(user.uid);
            
            // Now check if the gender is female
            if (removeSpacesAndConvertLowercase(userDataForConstraints.gender) === 'female')
                setLoading(false);
            else {
                Alert('error', 'Sorry! This is a women\'s only Gym');
                setIsLoading(false);
                return;
            }
        }

        setPriceToPayGym(exclusivePrice);
        // If the user is booking a trial,
        // Open the trial modal
        if (amountToPay === 0) {
            // setTrialModal(true);
        } // Else open the payment confirmation modal
        else {
            setMembershipDetails({ months, amountToPay, mrp, selectedClass });
            setSummaryModal(true); // Show the summary modal
            if(selectedCategory === 'single') {
                setTotalMemberships(1);
            } else if(selectedCategory === 'couple') {
                setTotalMemberships(2);
            } else (
                setTotalMemberships(4)
            )
        }
    };

      
    // handles screen resolution
    useEffect(() => {
        const handleResize = () => {
            const newResolution = window.devicePixelRatio;
            setUSR((prevResolution) => {
                if (prevResolution !== newResolution) {
                    return newResolution;
                }
                return prevResolution;
            });
        };

        // Add event listener for resize
        window.addEventListener("resize", handleResize);

        // Cleanup listener on unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    /**
     * handleMembershipButton
     * Handles the membership button click event, prepares payment data, and initiates the payment process.
     * 
     * @param {Object} props                     - Properties required for payment processing.
     * @param {Number} props.months               - Duration of the selected membership in months.
     * @param {Number} props.amount               - Amount to be paid for the membership, in the primary currency unit (e.g., rupees).
     * @param {Number} props.mrp                  - Maximum Retail Price (MRP) for the membership.
     * @param {Array}  props.validMembers         - Array of valid member objects, includes main user and additional members if applicable.
     * @param {Object} props.referralGiver        - Object containing referral giver details, if any.
     * @param {Number} [props.trex=0]             - Trex credits to be used, defaulting to 0 if not provided.
     * 
     * @returns {Promise<void>} Initiates the payment process.
     */
    const handleMembershipButton = async (props) => {
        setSummaryModal(false); // Close the summary modal
        const newBillArray = Array(totalMemberships ? totalMemberships : 1).fill({})
        await setBillInfoArray(newBillArray); // Initialize bill information array for each member

        // Determine if there are multiple members; if so, include user and valid members, else just the user.
        const allMembers = totalMemberships > 1
            ? [{ ...user }, ...(props.validMembers || [])]
            : [{ ...user }];

        // Prepare gym data for payment processing
        // Also Checks if that gym is providing any special discount of its own
        const gymDataForPayment = specialOffersOfGym({
            gymName             :   currStudioData?.studioName,
            city                :   currStudioData?.city,
            months              :   props.months,
            // If paying at the counter
            // Only Take 1 Rs as confirmation
            amount              :   (props.amount * 100).toFixed(2),
            image               :   currStudioData?.logo,
            gymContactNo        :   currStudioData?.studioContactNo ? currStudioData.studioContactNo : '',
            address             :   currStudioData?.address,
            cityDisplayName     :   currStudioData?.cityDisplayName,
            gstin               :   currStudioData?.gstin || '',
            gymDisplayName      :   currStudioData?.studioDisplayName,
            gymEmail            :   currStudioData?.email,
            mrp                 :   props.mrp,
            referralGiver       :   props.referralGiver       || {}, // Default to empty object if no referral giver
            trex                :   props.trex                || 0, // Default to 0 if no Trex credits are applied
            totalMemberships    :   totalMemberships          || 1,
            startDate           :   props.startDate,
            amountToPay         :   props.payAtTheCounter ? 100 : (props.amount * 100).toFixed(2), // Convert to smallest currency unit (e.g., cents)
            payAtTheCounter     :   props.payAtTheCounter? props.payAtTheCounter : false,
            priceToPayGym       :   priceToPayGym,
            orderType           :   'studio',
            className           :   props.className,
            selectedShift       :   props.selectedShift
        });

        if (props.payAtTheCounter) Alert('info', `Confirm your spot for just ${rsSymbol}1! \nTrain Rex has got the rest covered for you!`);

        // Check if the user is signed in
        if (!isUserPresent) {
            // If not signed in, show sign-in modal and store payment data for post-login processing
            setSignInModal(true);             // Display sign-in modal
            setMyData(gymDataForPayment);     // Store payment data for later processing
            setPendingPayment(true);          // Set pending payment flag
        } else {
            // User is signed in, initiate the payment process
            const response = await handlePayment(
                gymDataForPayment, 
                allMembers, 
                setIsPaymentProcessing,
                setBillInfoArray
            );
            setSuccessModal(response);
        }
    };


    /**
     * Navigates to Payment Successful Page if payment is completed
     * And BillInfoArray is righteously set
     * 
     * @param {Boolean} successModal    -   boolean to navigate to the next page
     * @param {Array} billInfoArray     -   Bill Information
     * @navigates                       -   PaymentSuccessfulPage
     */
    useEffect(() => {
        if (successModal && billInfoArray[0] !== undefined) {
            setSuccessModal(false);
            // Navigvate to next page
            navigate('/PaymentSuccessfulPage', { state: { 
                billInfo        :   JSON.parse(JSON.stringify(billInfoArray)),
                classesSchedule :   currStudioData?.classesSchedule,
            } });
        }

    }, [successModal, billInfoArray, navigate, currStudioData]);


    // Automatically handles payment after user signs in if payment is pending.
    useEffect(() => {
        if (pendingPayment && isUserPresent) {
            handlePayment({ ...myData, user }, setIsPaymentProcessing, setSuccessModal, setBillInfoArray);
            setPendingPayment(false); // Reset the pending payment flag after payment
        }
    }, [isUserPresent, myData, pendingPayment, user, billInfoArray, navigate]);

    // creates an object of Class : true/false showing which classes are present in the studio
    const studioClasses = !loading && Object.fromEntries(
        Object.entries(currStudioData?.duration).map(([className, durationValue]) => [className, durationValue.trim() !== ""])
    );
    
    if (loading) {
        return  (
            <div className="h-[100vh] flex justify-center items-center flex-col">
                <div className="loader mb-6 mt-14 text-8xl font-extrabold scale-125">Train Rex</div>
                <div className="fill-text text-3xl lg:text-4xl m-4 font-semibold">{getDailyTips()}</div>;
            </div>
        )
    }

    return (
        <>
            {/* SEO */}
                <Helmet>
                    <title>{`Explore ${currStudioData?.studioDisplayName} in ${currStudioData?.cityDisplayName} | Train Rex`}</title>
                    <meta
                        name="description"
                        content={`Discover ${currStudioData?.studioDisplayName}, located in ${currStudioData?.cityDisplayName}. Check out membership plans, facilities, reviews, and book your session now.`}
                    />
                    <meta
                        name="keywords"
                        content={`classes in ${currStudioData?.cityDisplayName}, ${currStudioData?.studioDisplayName}, fitness classes, membership plans, personal trainers`}
                    />
                    <link rel="canonical" href={`https://trainrexofficial.com/${city}/class/${studioSlug}`} />
                </Helmet>
                
                {currStudioData ? (
                    <div className={`bg-tertiary flex flex-col px-5 ${USR >= 1.5 ? 'lg:px-10' : USR === 1 ? 'lg:px-44' : USR === 1.25 ? 'lg:px-20' : 'lg:px-40'} pt-40 md:pt-32 lg:pt-36 space-y-8 w-full pb-16 min-h-screen`}>
                        {/* Photos */}
                        {window.ReactNativeWebView && 
                            // only visible on app, takes user to the last page before the current one
                            <button 
                                onClick={() => navigate(-1)}
                                className="mb-3"> 
                                <ArrowLeft  className='text-secondary'/>
                            </button>
                        }

                        
                        {currStudioData?.Portfolio && currStudioData?.Portfolio[0] !== '' && 
                            <StudioPortfolio Portfolio={currStudioData?.Portfolio} />}


                        {/* Login Modal */}
                        <UserLoginModal modalIsOpen={signInModal} setModalIsOpen={setSignInModal} myData={myData} showGuest={false} />
                        

                        {/* Studio Name, Address, etc */}
                        <StudioInfo 
                            studioDisplayName={currStudioData?.studioDisplayName}
                            address          ={currStudioData?.address}
                            locality         ={currStudioData?.locality}
                            city             ={currStudioData?.city}
                            latitude         ={currStudioData?.latitude}
                            longitude        ={currStudioData?.longitude}
                            rating           ={currStudioData?.rating}
                            NoOfReview       ={currStudioData?.NoOfReview}
                            trainRexVerified ={currStudioData?.trainRexVerified}
                            mapLink          ={currStudioData?.mapLink}
                            isWomensOnly     ={currStudioData?.isWomensOnly}
                            name             ={currStudioData?.name}
                        />

                        {/* Timings */}
                        <StudioTimings 
                            classesSchedule={currStudioData?.classesSchedule}
                            duration       ={currStudioData?.duration}
                        />


                        {/* Offers */}


                        {/* Prices */}
                        <StudioMembershipPrices
                            USR                 ={USR}
                            studioName          ={currStudioData?.studioName}
                            city                ={currStudioData?.city}
                            isExclusive         ={currStudioData?.isExclusive}
                            prices              ={currStudioData?.prices}
                            toggleSummaryModal  ={toggleSummaryModal}
                            setSelectedCategory ={setSelectedCategory} 
                        /> 


                        {/* Reviews */}
                        <StudioRatingAndReviews 
                            setSignInModal      = {setSignInModal}
                            isUserPresent       = {isUserPresent}
                            currStudioData      = {currStudioData}
                            user                = {user}
                        />


                        {/* Amenities and facilities */}
                        <GymDetailsComponent 
                            isGymPage={false}
                            data={{ 
                                facilities: currStudioData?.facilities, 
                                classes: studioClasses 
                            }} 
                        />


                        {/* Payment Processing Modal */}
                        <PaymentLoadingModal isOpen={isPaymentProcessing} totalMemberships={totalMemberships}/>


                        {/* Payment Modal */}
                        {summaryModal && (
                            <PaymentConfirmationModal
                                receiptInfo={{
                                    gymName         : currStudioData?.studioDisplayName,
                                    months          : membershipDetails?.months,
                                    amountToPay     : membershipDetails?.amountToPay,
                                    mrp             : membershipDetails?.mrp - 1,
                                    className       : membershipDetails?.selectedClass,
                                    classesSchedule : currStudioData?.classesSchedule
                                }}
                                onClose={() => setSummaryModal(false)}
                                handleMembershipButton={handleMembershipButton}
                                totalMemberships={totalMemberships}
                                selectedCategory={selectedCategory}
                                isUserPresent={isUserPresent}
                                user={user}
                                setSignInModal={setSignInModal}
                                gymData={currStudioData}
                                USR={USR}
                            />
                        )}


                        {isLoading && <LoadingSpinner /> }



                    </div>) 
                    :
                    null
                }
        </>
    )
}

export default StudioPage;