import { ExternalLink, MapPin } from "lucide-react";
import StarRating from "../StarRating";
import { TRVerified } from "../../assets/Website_content/svg";
import { getNavigationLink } from "../../helperFunctions/MarketplaceHelpers/MapHelpers/DistanceAndLocationHelpers";
import { convertTo12HourFormat } from "../../helperFunctions/basicHelper";
import { formatTimeSlots } from "../../helperFunctions/MarketplaceHelpers/gymDataHelpers/TimingHelper";
import { processTimings } from "../../helperFunctions/MarketplaceHelpers/gymDataHelpers/TimingHelper";

const GymInfoSection = ({ currGymData }) => {
  const timingsList = processTimings(currGymData?.timeSlots);

  return (
    <>
      <div className="w-full flex justify-between mt-4">
        <div className="flex flex-col text-left">
          <div className="flex items-center gap-1 lg:gap-2">
            <div className="text-2xl font-bold text-primary">
              {currGymData?.gymDisplayName || currGymData.name}
            </div>
            {currGymData?.trainRexVerified && <TRVerified height="28px" />}
            <div className="flex">
              {currGymData?.isWomensOnly && <TRVerified height="28px" />}
            </div>
          </div>

          <div className="text-left text-primary font-semibold mt-2 flex gap-2">
            <MapPin className="scale-150 lg:scale-100" />
            <span>
              {currGymData?.address}, {currGymData?.locality}
            </span>
          </div>

          {(currGymData?.mapLink || currGymData.latitude) && (
            <a
              href={
                currGymData?.mapLink ||
                getNavigationLink(
                  parseFloat(currGymData.latitude),
                  parseFloat(currGymData.longitude)
                )
              }
              target="_blank"
              rel="noopener noreferrer"
              className="group relative flex items-center bg-transparent rounded-xl text-complementPurple font-bold hover:text-complementPurple transition-all duration-300"
            >
              <ExternalLink className="w-5 h-5 md:w-6 md:h-6" />
              <span className="pt-1">Get Directions</span>
            </a>
          )}
        </div>

        <div>
          <StarRating
            rating={currGymData?.rating}
            NoOfReview={currGymData?.NoOfReview}
            color="secondary"
          />
        </div>
      </div>

      <div className="p-4 md:p-6 border border-gray-500 rounded-2xl mt-4">
        <div className="font-semibold text-lg md:text-xl text-left">
          Business Hours
        </div>

        <div className="flex flex-col gap-3 mt-4">
          {timingsList.length > 0 ? (
            timingsList.map(({ day, slots }, index) => (
              <div
                key={index}
                className="text-sm md:text-lg font-bold text-primary flex flex-col sm:flex-row justify-between sm:items-center"
              >
                <div>{day}</div>
                <div className="sm:ml-4">{formatTimeSlots(slots)}</div>
              </div>
            ))
          ) : (
            // Fallback for single opening and closing time
            <div className="text-sm md:text-lg font-bold text-primary flex flex-col sm:flex-row justify-between sm:items-center">
              <div>Monday to Saturday</div>
              <div className="sm:ml-4">
                {currGymData?.openingTime && currGymData?.closingTime 
                  ? `${convertTo12HourFormat(currGymData.openingTime)} to ${convertTo12HourFormat(currGymData.closingTime)}`
                  : "Closed"}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default GymInfoSection;