import React from "react";
import { TrexIcon } from "../../assets/Website_content/svg";

const DietPlan = React.forwardRef(({ weeklyPlan, selectedDayIndex, setRegeneratePopup }, ref) => {
    const meals = weeklyPlan[selectedDayIndex]?.meals || {};

    return (
        <section ref={ref} className="pt-4 px-4">
            <h2 className="text-xl font-semibold mb-4 text-primary">Diet Plan</h2>
            {["breakfast", "lunch", "dinner"].map((mealType) => (
                <div key={mealType} className="mb-4 bg-gray-100 p-4 rounded-lg shadow-md">
                    <div className="flex justify-between items-center">
                        <h3 className="font-semibold capitalize">{mealType}</h3>
                        <button
                            onClick={() =>
                                setRegeneratePopup({ visible: true, itemType: "meal", mealKey: mealType })
                            }
                            className="gap-x-2 flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-purple to-primaryComplement rounded-xl"
                        >
                            <div className="flex gap-1">
                                <span className="font-semibold">10</span>
                                <TrexIcon height='16px' color='#ffffff'/>
                            </div>
                            <div>Regenerate</div>
                        </button>
                    </div>
                    <ul className="mt-2">
                        {(meals[mealType] || []).map((item, idx) => (
                            <li
                                key={idx}
                                className="text-sm text-gray-700 border-b py-1 flex justify-between"
                            >
                                <span>{item.item}</span>
                                <span>
                                    {item.amount} - {item.calories} kcal
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </section>
    );
});

export default DietPlan;
