import React, { createContext, useState, useEffect } from "react";
import { fetchAllGyms } from "../helperFunctions/GymInfoHelpers/FetchGymsList";
import { fetchStudios } from "../helperFunctions/StudioClassesHelper/StudioClassesHelper";
import { cityList } from "../components/Databases";
import { db } from "../config/firebase";
import { doc, getDoc } from "@firebase/firestore";
import { fetchCities } from "../helperFunctions/MarketplaceHelpers/MarketplaceHelper";

export const GymContext = createContext();

export const GymProvider = ({ children }) => {
    const [allGyms, setAllGyms] = useState(() => {
        const storedGyms = localStorage.getItem("allGyms");
        return storedGyms ? JSON.parse(storedGyms) : [];
    });

    const [allStudios, setAllStudios] = useState(() => {
        const storedStudios = localStorage.getItem("allStudios");
        return storedStudios ? JSON.parse(storedStudios) : [];
    });

    const [allCities, setAllCities] = useState();

    const [loading, setLoading] = useState(false);
    const [selectedCity, setSelectedCity] = useState(() => {
        return (
            localStorage.getItem("selectedCity") ||
            (window.location.href.includes("ghaziabad") && "Ghaziabad") ||
            (window.location.href.includes("ncr") && "NCR") ||
            (window.location.href.includes("noida") && "Noida") ||
            "Delhi"
        );
    });

    useEffect(() => {
        const fetchGymsAndClasses = async () => {
            try {
                // LocalStorage Data
                const localGyms    = JSON.parse(localStorage.getItem("allGyms")) || [];
                const localStudios = JSON.parse(localStorage.getItem("allStudios")) || [];
                const localCities  = JSON.parse(localStorage.getItem("allCities")) || [];
                // console.log("Selected City: ", selectedCity)
                // console.log("City on gyms : ", localGyms?.[0]?.city)

                // If no local gyms, set loading for the initial fetch
                if (localGyms?.length === 0) setLoading(true);
                
                // Fetching all the cities
                if (localCities?.length === 0 ) {
                    const cities = await fetchCities();
                    setAllCities(cities);
                    localStorage.setItem("allCities" , JSON.stringify(cities))
                } else {
                    setAllCities(localCities)
                }

                // Check for city mismatch
                if (localGyms?.[0]?.city !== selectedCity) {
                    // console.log("City mismatch, fetching gyms and studios...");
                    setLoading(true);
                    const gyms      = await fetchAllGyms(selectedCity);
                    const classes   = await fetchStudios(selectedCity);
                    
                    // Save to state and local storage
                    setAllGyms(gyms);
                    setAllStudios(classes);
                    localStorage.setItem("allGyms"   , JSON.stringify(gyms));
                    localStorage.setItem("allStudios", JSON.stringify(classes));
                    setLoading(false);
                    return;
                }
    
                // Firestore references
                const cityGymRef = doc(db, cityList, selectedCity);
                const studiosRef = doc(db, cityList, selectedCity);
    
                // Firestore document counts
                const gymSnapshot    = await getDoc(cityGymRef);
                const studioSnapshot = await getDoc(studiosRef);
    
                const gymCountInFirestore = gymSnapshot.data()?.NoOfActiveGyms;
                const studioCountInFirestore = studioSnapshot.data()?.NoOfActiveStudios;

                // console.log("Gym ; ", gymCountInFirestore)
                // console.log("Studios ; ", studioCountInFirestore)
    
                // Check for length mismatch
                const shouldFetchGyms    = localGyms?.length !== gymCountInFirestore;
                const shouldFetchStudios = localStudios?.length !== studioCountInFirestore;
    
                if (shouldFetchGyms || shouldFetchStudios) {
                    setLoading(true);
                    const gyms = await fetchAllGyms(selectedCity);
                    const classes = await fetchStudios(selectedCity);
    
                    // Save to state and local storage
                    setAllGyms(gyms);
                    setAllStudios(classes);
                    localStorage.setItem("allGyms", JSON.stringify(gyms));
                    localStorage.setItem("allStudios", JSON.stringify(classes));
                }
    
                setLoading(false);
            } catch (error) {
                console.error("Error fetching gyms and classes:", error);
                setLoading(false);
            }
        };
    
        // Fetch only if local storage data is unavailable or outdated
        fetchGymsAndClasses();
    }, [selectedCity]);    

    useEffect(() => {
        // Save selectedCity to local storage when it changes
        localStorage.setItem("selectedCity", selectedCity);
    }, [selectedCity]);

    return (
        <GymContext.Provider value={{ allGyms, allStudios, allCities, loading, setLoading, selectedCity, setSelectedCity }}>
            {children}
        </GymContext.Provider>
    );
};
