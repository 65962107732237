import { ArrowLeft } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";

const Header = () => {
    const navigate = useNavigate();

    return (
    <header className="sticky top-0 text-white p-4 flex justify-between items-center">
        <div className="flex gap-1">
            {window.ReactNativeWebView && 
                // only visible on app, takes user to the last page before the current one
                <div 
                    onClick={() => window.history.back()}>
                    <ArrowLeft  className='text-secondary'/>
                </div>
            }
            {/* Page Title */}
            <h1 className="text-xl lg:text-4xl font-bold text-black">
                My <span className="text-tertiary">Fitness</span> Plan
            </h1>
        </div>

        {/* Animated Button */}
        <button
            className="px-2 py-2 bg-secondary text-white font-medium rounded-lg animate-bounce"
            onClick={() => navigate("/")}
        >
            Explore Marketplace
        </button>
    </header>
)};

export default Header;
