import { Timestamp } from "@firebase/firestore";
import { SHA256 } from "crypto-js";

/**
 * @return  :   current time
 */
export const getTime = () => {
    const now = new Date();

    const isoString = now.toISOString();
    return isoString.slice(0, -1) + 'Z'; // Remove the last character (Z) and append it again to ensure consistent format;
}

/**
 * Returns today's date
 * @format  : YYYY-MM-DD
 * @return  : Today's Date
 */
export const getDate2 = () => {
    const now = new Date();

    // Get Today's Date 
    return now.toISOString().split('T')[0];  // Outputs: '2024-05-22'
}

/**
 * Returns a Date object for today's date in DD-MM-YYYY format with the current time
 * @return {Date} - Date object
 */
export const getDate3 = () => {
    const now = new Date();

    // Extract the current date components using toLocaleString
    const day = String(now.toLocaleString('en-GB', { day: '2-digit' }));
    const month = String(now.toLocaleString('en-GB', { month: '2-digit' }));
    const year = now.toLocaleString('en-GB', { year: 'numeric' });

    // Extract current time components using native methods
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const milliseconds = now.getMilliseconds();

    // Create a Date object with the current date and time
    const timestamp = new Date(year, parseInt(month) - 1, parseInt(day), hours, minutes, seconds, milliseconds);

    return timestamp; // Returns Date object with the current date and time
};

/**
 * Returns today's date
 * @format  : DD-MM-YYYY
 * @return  : Today's Date
 */
export const getDate = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = now.getFullYear();

    return `${day}-${month}-${year}`;  // Outputs: '22-05-2024'
}

/**
 * Returns today's date in ISO format
 * @format  : ISO
 * @return  : Today's Date
 */
export const getDateInISO = () => {
    return new Date().toISOString();
};


/**
 * Gets Year Month and Date individually
 * @param   : void
 * @return  : Year, Month and Date
 */
export const getCalendar = () => {
    const now = new Date();
    
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const date = String(now.getDate()).padStart(2, '0');

    // Combine year, month, and date
    return {year, month, date};
}

/**
 * Returns the current Year
 * @param {void}
 * @returns {String} - Current Year
 */
export const getCurrentYear = () => {
    const currentYear = new Date().getFullYear();
    return currentYear.toString();
}

/**
 * Gets the current month in a string format (e.g., "August").
 * @returns {string} The current month.
 */
export const getCurrentMonth = () => {
    const months = [
        "January", "February", "March", "April", "May", "June", 
        "July", "August", "September", "October", "November", "December"
    ];
    const currentMonthIndex = new Date().getMonth();  // getMonth() returns 0-11
    return months[currentMonthIndex];
};

/**
 * Returns the current month in the format MM-YYYY
 * @returns {string} - The current month in MM-YYYY format
 */
export const getCurrentMonthAndYear = () => {
    const date = new Date();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns 0-11
    const year = date.getFullYear();
    return `${month}-${year}`;
};

/**
 * Refines the Data according to the database
 */
export const refineData = (myData) => {
    const refinedData = { ...myData }; // Create a shallow copy of myData

    if (myData.city) {
        refinedData.city = myData.city.toLowerCase().replace(/\s+/g, '');
    }
    if (myData.gymName) {
        refinedData.gymName = myData.gymName.toLowerCase().replace(/\s+/g, '');
    }
    return refinedData;
};

/**
 * Removes spaces and converts everything to lowercase
 */
export const removeSpacesAndConvertLowercase = (dataToConvert) => {
    return dataToConvert.toLowerCase().replace(/\s+/g, '');
}

/**
 * Capitalises first letter of every word
 * @param {String} word
 * @returns {String} Capitalised Word
 */
export const capitalizeFirstLetter = (word) => {
    return word.replace(/\b\w/g, char => char.toUpperCase());
}

/**
 * Formats everyword by deleting spaces and converting to lowercase letters
 * @param {String} word
 * @returns {String} formatted word
 */
export const formatWordToLowerCase = (word) => {
    return word.toLowerCase().replace(/\s+/g, '');
}

export const truncateText = (text, maxLength) => {
    if (text?.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + '...';
};

/**
 * Generates a random string
 *
 * @param {number} [length=20] - The length of the generated string. Default is 20 characters.
 * @returns {string} - A randomly generated string.
 */
export const generateRandomStringId = (length = 12) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters?.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
};


/**
 * Checks if the given date in 'DD-MM-YYYY' format is today or in the future.
 * 
 * @param   {string}    myDate  -   The date string in 'DD-MM-YYYY' format.
 * @returns {boolean}           -   Returns true if the date is today or in the future, otherwise false.
 */
export const compareDateToToday = (myDate) => {
    // Split the date string into day, month, and year
    const [day, month, year] = myDate.split('-');

    // Create a new Date object from the given date string
    const inputDate = new Date(`${year}-${month}-${day}`);
    
    // Get today's date
    const today = new Date();
    
    // Set the time to 00:00:00 for comparison (ignore time portion)
    today.setHours(0, 0, 0, 0);
    inputDate.setHours(0, 0, 0, 0);

    // Return true if the date is today or in the future, false otherwise
    return inputDate >= today;
};


// Extract facilities and sort by priority
export const getTopFacilities = (facilities) => {
    const availableFacilities = Object.keys(facilities || {}).filter(
        (facility) => facilities[facility]
    );

    // Priority order
    const priorities = [
        { keywords: ["wall"], priority: 0 },
        { keywords: ["valet", "parking"], priority: 1 },
        { keywords: ["spa", "sauna"], priority: 2 },
        { keywords: ["air"], priority: 3 },
    ];

    // Assign priority to each facility
    const facilityWithPriority = availableFacilities.map((facility) => {
        const priority = priorities.find((p) =>
            p.keywords.some((keyword) =>
                facility.toLowerCase().includes(keyword)
            )
        );
        return {
            name: facility,
            priority: priority ? priority.priority : 4, // Default priority for others
        };
    });

    // Sort by priority
    facilityWithPriority.sort((a, b) => a.priority - b.priority);

    // Return sorted facility names
    return facilityWithPriority.map((item) => item.name);
};

/**
 * Generates shorter versions of the facilities like Fully Air Conditioned to Fully AC
 * @param {string} word  - any facility name
 * @returns 
 */
export const getFacilityNamesForSmallerScreens = (word) => {
    if (word.includes('Fully')) {
        return 'Fully AC';
    } else if (word.includes('Valet')) {
        return 'Valet';
    } else if (word.includes('Air')) {
        return 'AC';
    } else if (word.includes('Lounge')) {
        return 'Lounge'
    } else if (word.includes('Tennis')) {
        return 'Table Tennis'
    }
    return word; // Default case if no conditions are met
}

/**
 * Used to check which browser is using to navigate through the website
 * @returns browser name
 */
export const getBrowserName = () => {
    const userAgent = navigator.userAgent;

    if (userAgent.includes("Chrome")) return "Chrome";
    if (userAgent.includes("Firefox")) return "Firefox";
    if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) return "Safari";
    if (userAgent.includes("Edge")) return "Edge";
    if (userAgent.includes("MSIE") || userAgent.includes("Trident")) return "Internet Explorer";

    return "Unknown";
};

/**
 * Generates a random alphanumeric document ID, optionally starting with 
 * the first letter of a provided word (in lowercase).
 * 
 * @param {string} [word] - Optional word to use the first letter as a prefix.
 * @param {number} [length=10] - Length of the document ID to generate (excluding the prefix).
 * @returns {string} - A random alphanumeric string prefixed with the first letter of the word.
 */
export const generateDocumentID = (word = '', length = 10) => {
    const prefix = word ? word[0].toLowerCase() : ''; // Use the first letter of the word in lowercase or an empty string
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'; // Alphanumeric characters
    let result = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters?.length);
        result += characters[randomIndex];
    }
    return `${prefix}${result}`; // Combine the prefix with the random alphanumeric string
};

/**
 * Hashes the user ID + Forum Document ID
 * @param   {String} userID     -   The user ID of the user
 * @param   {String} docID      -   The document ID of the forum/discussion
 * @returns {String}            -   The hashed string
 */
export const generateHashedDocID = (userID, docID) => {
    const concatenatedIDs = [userID, docID].join('-');
    const chatID = SHA256(concatenatedIDs).toString();
    return chatID;
};

/**
 * 
 * @param   {string} input - Any string 
 * @returns {string}       - removes all the special characters from the string and returns the new string
 */
export const removeSpecialCharacters = (input) => {
    return input.replace(/[^a-zA-Z0-9 ]/g, ''); // Removes anything that's not a letter, number, or space
}


/**
 * Formats a given ISO date string into a human-readable relative time.
 * The function handles time differences ranging from seconds to years.
 *
 * @param   {String} isoDateString - An ISO-formatted date string (e.g., "2024-11-28T18:37:33.524Z").
 * @returns {String}               - A human-readable relative time (e.g., "Few seconds ago", "3 days ago").
 */
export const formatRelativeTime = (isoDateString) => {
    // Get the current time and the input time
    const now = new Date();
    const inputTime = new Date(isoDateString);

    // Calculate the time difference in seconds
    const diffInSeconds = Math.floor((now - inputTime) / 1000);

    // Return "In the future" if the input time is after the current time
    if (diffInSeconds < 0) return "In the future";

    // Handle cases for seconds ago
    if (diffInSeconds < 10) return "Few seconds ago";
    if (diffInSeconds < 60) return `${diffInSeconds} seconds ago`;

    // Calculate time differences in minutes, hours, days, weeks, months, and years
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) return `${diffInMinutes} minutes ago`;

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) return `${diffInHours} hours ago`;

    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 7) return `${diffInDays} days ago`;

    const diffInWeeks = Math.floor(diffInDays / 7);
    if (diffInWeeks < 4) return `${diffInWeeks} weeks ago`;

    const diffInMonths = Math.floor(diffInDays / 30.44); // Approximate average days in a month
    if (diffInMonths < 12) return `${diffInMonths} months ago`;

    const diffInYears = Math.floor(diffInDays / 365.25); // Account for leap years
    return `${diffInYears} years ago`;
};

// converts time from 24 hr format to AM PM format
export const convertTo12HourFormat = (time) => {
    if (!time) return "N/A"; // Return 'N/A'
    // Split the time into hours and minutes
    const [hours, minutes] = time.split(":").map(Number);

    // Determine if it's AM or PM
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    const hours12 = hours % 12 || 12;

    // Format the time as hh:mm AM/PM
    return `${hours12}:${minutes.toString().padStart(2, "0")} ${ampm}`;
};

// Converts time from 24 hr format to 12 hr format without AM/PM
export const convertTo12HourFormatNoPeriod = (time) => {
    if (!time) return "N/A"; // Return 'N/A' if time is null or undefined

    // Split the time into hours and minutes
    const [hours, minutes] = time.split(":").map(Number);

    // Convert hours to 12-hour format
    const hours12 = hours % 12 || 12;

    // Format the time as hh:mm without AM/PM
    return `${hours12}:${minutes.toString().padStart(2, "0")}`;
};

export const getDatesForDays = (days, startDate = new Date()) => {
    const dates = [];
    const start = new Date(startDate);
    start.setHours(0, 0, 0, 0);

    for (let i = 0; i < 30; i++) { // Check the next 30 days
        const tempDate = new Date(start);
        tempDate.setDate(start.getDate() + i);
        const dayName = tempDate.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();

        if (days.includes(dayName)) {
            dates.push(tempDate);
        }
    }
    return dates;
};

/**
 * Tahes a date object and return string of the smae object as DD-MM-YYYY
 * @param {object} date - a date object
 * @returns a string of the date object in DD-MM-YYYY format 
 */
export const convertDateObjectToDDMMYYYY = (date) => {
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
}

/**
 * returns array of classes days ['monday', 'thursday' ]
 * @param {string} className         - can be zumba, aerobics, etc
 * @param {object} classesSchedule   - object containing this structure classesSchedule>{weekDays}>[className]>[morningTime, eveningTime] 
 * @returns 
 */
export const getAvailableDates = (className, classesSchedule) => {
    const availableDates = [];
    for (const [day, classes] of Object.entries(classesSchedule)) {
        if (classes[className]) {
            availableDates.push(day);
        }
    }
    return availableDates;
};

/**
 * takes a date string as DD-MM-YYYY and return its date object
 * @param {String} date - a date in the format of DD-MM-YYYY
 * returns a date object of the give string date
 */
export const convertDDMMYYYToDateObject = (date) => {
    const [day, month, year] = date.split('-').map(Number); // Split and convert to numbers
    return new Date(year, month - 1, day); // Month is zero-based
};

/**
 * takes a date string as DD-MM-YYYY and return its timestamp
 * @param {String} date - a date in the format of DD-MM-YYYY
 * returns a date object of the give string date
 */
export const convertDDMMYYYYToTimestampMilliSeconds = (dateString) => {
    const [day, month, year] = dateString.split('-').map(Number); // Split and convert to numbers
    const dateObject = new Date(year, month - 1, day); // Create a Date object (month is zero-based)
    return dateObject.getTime(); // Get the timestamp in milliseconds
};

/**
 * takes timestamp as milliseconds and converts it to ISO format timestamp (we store timeStamp in ISO formats in database)
 * @param {number} timestamp - timestamp in milliseconds
 * returns ISO format timestamp
 */
export const convertMilliSecondsTimestampToISOFormat = (timestamp) => {
    const date = new Date(timestamp); // Create a Date object from the timestamp
    return date.toISOString(); // Convert it to ISO 8601 string
}

/**
 * Takes a DD-MM-YYYY string and convert it in ISO format timestamp
 * @param {string} DDMMYYYY - date in DD-MM-YYYY string
 * @returns ISO format timestamp
 */
export const convertDDMMYYYYToISOTimestamp = (DDMMYYYY) => {
    const [day, month, year] = DDMMYYYY.split('-').map(Number); // Split into day, month, year
    const dateObject = new Date(Date.UTC(year, month - 1, day)); // Create a Date object in UTC
    return dateObject.toISOString(); // Convert to ISO 8601 format
}

/**
 * Takes a string and format it in eg: input zumba output Zumba
 * @param {string} className 
 */
export const formattedClassName = (className) => {
    if (!className || typeof className !== 'string') return '';
    return className.charAt(0).toUpperCase() + className.slice(1).toLowerCase();
}

/**
 * converts timestamp to DD-MM-YYYY string and returns it
 * @param {string} isoDate - Timestamp in ISO format
 * @returns DD-MM-YYYY string
 */
export const convertISOToDDMMYYYY = (isoDate) => {
    if (!isoDate) return '';

    const date = new Date(isoDate);

    const day = String(date.getDate()).padStart(2, '0'); // Ensures two digits for the day
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensures two digits for the month
    const year = date.getFullYear(); // Gets the full year

    return `${day}-${month}-${year}`;
}

/**
 * Takes an array of anything like ['monday', 'tuesday', 'wednesday'] and returns monday, tuesday and wednesday
 * @param {Array} weekDayArrays 
 * @returns ['monday', 'tuesday', 'wednesday'] to monday, tuesday and wednesday
 */
export const formatWeekDays = (weekDayArrays) => {
    if (!weekDayArrays || weekDayArrays.length === 0) return '';

    // Join all days with commas except for the last one, which is joined with "and"
    const formatted = weekDayArrays.length > 1
        ? `${weekDayArrays.slice(0, -1).join(', ')} and ${weekDayArrays[weekDayArrays.length - 1]}`
        : weekDayArrays[0]; // For a single weekday, just return it

    return formatted;
}

// Function to get the class with the lowest exclusiveMonthlyPrice
export const getClassWithLowestMonthlyPrice = (prices) => {
    if (!prices || !prices.single) return null; // Handle cases where prices are undefined or empty

    const classes = Object.keys(prices.single); // Get all class names (e.g., ['zumba', 'yoga', 'aerobics'])
    if (classes.length === 0) return null;

    // Find the class with the minimum exclusiveMonthlyPrice
    const lowestPriceClass = classes.reduce((lowest, currentClass) => {
        const currentPrice = prices.single[currentClass]?.exclusiveMonthlyPrice;
        const lowestPrice = prices.single[lowest]?.exclusiveMonthlyPrice;

        return (currentPrice < lowestPrice ? currentClass : lowest); // Update if the current price is lower
    }, classes[0]); // Initialize with the first class

    return lowestPriceClass;
};

//To handle both cases where dates are in different formats (Firestore Timestamps and ISO date strings)
export const formatDateForProfilePageClasses = (date) => {
    if (!date) return "N/A";
  
    // Handle Firestore Timestamp
    if (date instanceof Timestamp) {
      const dateObject = new Date(date.seconds * 1000);
      return dateObject.toLocaleDateString("en-GB"); // DD-MM-YYYY
    }
  
    // Handle ISO or string date
    if (!isNaN(Date.parse(date))) {
      return new Date(date).toLocaleDateString("en-GB");
    }
  
    return date; // Return as-is for unsupported formats
  };
  
//Sorts pills in decreasing order
export const sortPills = (pills) => {
return pills.sort((a, b) => b.usedCount - a.usedCount);
};
