/* eslint-disable react/jsx-pascal-case */
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Route, Routes, Outlet, useParams } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './index.css';
// import Marketplace from './Screens/marketplace/Marketplace';
// import NavBar_Marketplace_helper from './helperFunctions/Navbar_helpers/Navbar_MarketPlace_helper';
// Rest
import { analytics } from "./config/firebase";
import { logEvent } from "firebase/analytics";
import './index.css';
import DiscussionForums from './Screens/Marketplace2025/DiscussionForums';
import Forum from './helperFunctions/DiscussionForumHelpers/Forum';
import { Toaster } from 'react-hot-toast';
import PaymentSuccessPage from './Screens/marketplace/PaymentSuccessPage';
// import Marketplace_Landing from './Screens/marketplace/Marketplace_Landing';
import HomePage from './Screens/Marketplace2025/HomePage';
import TrainRexNavbar from './components/NavBar';
import ResultsPage from './Screens/Marketplace2025/ResultsPage';
import GymPage from './Screens/marketplace/GymPage';
import Listing from './Screens/marketplace/Listing';
import ClassesList from './Screens/Marketplace2025/StudiosList';
import StudioPage from './Screens/Marketplace2025/StudioPage';
import { GymProvider } from './hooks/FetchEverythingContext';
import { getDailyTips } from './helperFunctions/MarketplaceHelpers/MarketplaceHelper';
import PaymentDetailsPage from './Screens/Marketplace2025/PaymentDetailsPage';
// import GestureBackNavigation from './assets/Gestures';


// Import Screens
// const HomePage = lazy(() => import('./Screens/AppDescription/HomePage'));
const TCPage = lazy(() => import('./Screens/AppDescription/TCPage'));
const PPPage = lazy(() => import('./Screens/AppDescription/PPPage'));
const GymCred = lazy(() => import('./Screens/GymCred'));
const BlogPost = lazy(() => import('./Screens/marketplace/BlogPost'));
const BlogList = lazy(() => import('./Screens/Marketplace2025/BlogList'));
const WriteBlog = lazy(() => import('./Screens/marketplace/WriteBlog'));
const Careers = lazy(() => import('./Screens/AppDescription/careers'));

// Import Components
// Fixed Imports
const Navbar_App_helper = lazy(() => import('./helperFunctions/Navbar_helpers/Navbar_App_helper'));
const SearchResult = lazy(() => import('./Screens/SearchResult'));
const EventContact = lazy(() => import('./Screens/AppDescription/eventcontact'));
const BottomBar = lazy(() => import("./components/BottomBar"));
const UserProfile = lazy(() => import("./Screens/marketplace/UserProfile"));
const AppDescription = lazy(() => import("./Screens/AppDescription/AppDescription"));


const NewNavbar = ({ selectedCity, onLocationChange, setShowUnlockGym }) => (
    <>
        <TrainRexNavbar selectedCity={selectedCity} onLocationChange={onLocationChange} setShowUnlockGym={setShowUnlockGym}/>
        <Outlet />
    </>
)

/**
 * Layout component for Marketplace-related pages
 */
// const MarketplaceLayout = ({ selectedCity, onLocationChange }) => (
//     <>
//         <NavBar_Marketplace_helper selectedCity={selectedCity} onLocationChange={onLocationChange} />
//         <Outlet />
//     </>
// );

/**
 * Layout component for Train Rex App-related pages.
 */
const TrainRexLayout = () => (
    <>
        <Navbar_App_helper />
        <Outlet />
    </>
);

function App() {
    const [loading, setLoading] = useState(true);
    const [selectedCity, setSelectedCity] = useState(() => {
        if (localStorage.getItem('selectedCity')) {
            return localStorage.getItem('selectedCity')
        } else if (window.location.href.includes('ghaziabad')) {
            return 'Ghaziabad'
        } else if (window.location.href.includes('ncr')) {
            return 'NCR'
        } else if (window.location.href.includes('noida')) {
            return 'Noida'
        } else {
            return 'Delhi'
        }
    });

    const [showUnlockGym, setShowUnlockGym] = useState(false);

    // Log an event to Firebase Analytics when the user enters the app
    useEffect(() => {
        const currentTime = new Date().toISOString();
        const userAgent = navigator.userAgent;
        logEvent(analytics, `${userAgent}, ${currentTime}`);
        // Simulate loading
        setTimeout(() => setLoading(false), 2000);
    }, []);

    // Update selectedCity when location changes
    const handleLocationChange = (city) => {
        setSelectedCity(city);
        localStorage.setItem('selectedCity', city);
    };

    return (

        <>
        <div className={`${loading? '': "hidden"} fixed inset-0 bg-white h-[100vh] flex justify-center items-center flex-col z-[100]`}>
            <div className="loader mb-6 mt-14 text-8xl font-extrabold scale-125">Train Rex</div>
            <div className="fill-text text-3xl lg:text-4xl m-4 font-semibold">{getDailyTips()}</div>
        </div>
        <GymProvider>
            <HelmetProvider>
                <div className="App">
                    <Toaster
                        position="top-right"
                        reverseOrder={false}
                    />
                    <Suspense fallback={<div></div>}>
                    {/* <GestureBackNavigation /> */}
                        <Routes>
                            {/* Marketplace routes with the Marketplace layout */}
                            {/* <Route element={<MarketplaceLayout selectedCity={selectedCity} onLocationChange={handleLocationChange} />}>
                                <Route path="/oldWebsite" element={<Marketplace selectedCity={selectedCity} />} />
                                <Route path="/:city" element={<Marketplace selectedCity={selectedCity} />} />
                                <Route path="/:city/:gyms" element={<CityWrapper component={Listing} />} />
                                <Route path="/blogs" element={<BlogList selectedCity={selectedCity} />} />
                                <Route path="/blogs/:blogSlug" element={<BlogPost selectedCity={selectedCity} />} />
                                <Route path="/WriteBlog" element={<WriteBlog />} />
                                <Route path="/Credentials" element={<GymCred />} />
                                <Route path="/:city/:gyms/:gymSlug" element={<CityWrapper component={GymPage} />} />
                                <Route path="/:city/result" element={<SearchResult />} />
                                <Route path="/profile" element={<UserProfile />} />
                                <Route path="/event" element={<EventContact />} />
                            </Route> */}

                            {/* New design routes */}
                            <Route element={<NewNavbar selectedCity={selectedCity} onLocationChange={handleLocationChange} setShowUnlockGym={setShowUnlockGym}/>}>
                                <Route path="/" element={<HomePage selectedCity={selectedCity} showUnlockGym={showUnlockGym} setShowUnlockGym={setShowUnlockGym}/>} />
                                <Route path="/landingPage" element={<HomePage  showUnlockGym={showUnlockGym} setShowUnlockGym={setShowUnlockGym}/>} />
                                <Route path="/:city" element={<HomePage selectedCity={selectedCity} showUnlockGym={showUnlockGym} setShowUnlockGym={setShowUnlockGym}/>} />
                                <Route path="/:city/gyms" element={<CityWrapper component={Listing} showUnlockGym={showUnlockGym} setShowUnlockGym={setShowUnlockGym}/>} />
                                <Route path="/:city/gyms/:gymSlug" element={<CityWrapper component={GymPage} showUnlockGym={showUnlockGym} setShowUnlockGym={setShowUnlockGym}/>} />
                                <Route path="/:city/gyms/:gymSlug/paymentDetails" element={<PaymentDetailsPage />} />
                                <Route path="/:city/studios" element={<CityWrapper component={ClassesList} showUnlockGym={showUnlockGym} setShowUnlockGym={setShowUnlockGym}/>} />
                                <Route path="/:city/studios/:studioSlug" element={<CityWrapper component={StudioPage} showUnlockGym={showUnlockGym} setShowUnlockGym={setShowUnlockGym}/>} />
                                <Route path="/blogs" element={<BlogList selectedCity={selectedCity} showUnlockGym={showUnlockGym} setShowUnlockGym={setShowUnlockGym}/>} />
                                <Route path="/blogs/:blogSlug" element={<BlogPost selectedCity={selectedCity} />} />
                                <Route path="/WriteBlog" element={<WriteBlog />} />
                                <Route path="/Credentials" element={<GymCred />} />
                                <Route path="/:city/result" element={<SearchResult />} />
                                <Route path="/profile" element={<UserProfile />} />
                                <Route path="/event" element={<EventContact />} />
                                <Route path="/forum" element={<DiscussionForums showUnlockGym={showUnlockGym} setShowUnlockGym={setShowUnlockGym}/>} />
                                <Route path="/forum/:question" element={<Forum />} />
                                <Route path="/myFitnessPlan" element={<ResultsPage showUnlockGym={showUnlockGym} setShowUnlockGym={setShowUnlockGym}/>} />
                                
                            </Route>

                            <Route path="/PaymentSuccessfulPage" element={<PaymentSuccessPage />}/>

                            {/* Train Rex App routes with the TrainRex layout */}
                            <Route element={<TrainRexLayout />}>
                                <Route path="/AppDescription" element={<AppDescription />} />
                                <Route path="/AppDescription/Terms&Conditions" element={<TCPage />} />
                                <Route path="/AppDescription/PrivacyPolicy" element={<PPPage />} />
                                <Route path="/AppDescription/Careers" element={<Careers />} />
                            </Route>
                        </Routes>
                    </Suspense>

                    {/* Lazy load BottomBar */}
                    <Suspense fallback={<div></div>}>
                        <BottomBar />
                    </Suspense>
                </div>
            </HelmetProvider>
        </GymProvider>
        </>
        
    );
}

/**
 * CityWrapper Component
 * A wrapper component to sync the city from the URL with the selectedCity state.
 * @param {Object} props - The props object.
 * @param {JSX.Element} props.component - The component to render.
 * 
 * @returns {JSX.Element} The rendered component with city synced from the URL.
 */
const CityWrapper = ({ component: Component , showUnlockGym, setShowUnlockGym}) => {
    const { city } = useParams(); // Capture city from URL
    const [selectedCity, setSelectedCity] = useState(() => {
        return localStorage.getItem('selectedCity') || city;
    });

    useEffect(() => {
        // Sync the city from the URL with the state and localStorage
        if (city) {
            setSelectedCity(city);
            localStorage.setItem('selectedCity', city);
        }
    }, [city]);

    return <Component selectedCity={selectedCity} showUnlockGym={showUnlockGym} setShowUnlockGym={setShowUnlockGym}/>;
};

export default App;
