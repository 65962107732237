import { arrayRemove, arrayUnion, collection, deleteDoc, doc, getDoc, getDocs, increment, limit, query, setDoc, updateDoc, where } from "@firebase/firestore";
import { db } from "../../config/firebase";
import { forumList, replyList, tagList, voteList } from "../../components/Databases";
import { capitalizeFirstLetter, generateHashedDocID, generateDocumentID, getTime, removeSpacesAndConvertLowercase, removeSpecialCharacters } from "../basicHelper";

/**
 * currently not in use
 * @param {object} forum - 
 * @param {string} url   - 
 * @returns if someone directly opens the link, then to fetch the forum
 */
export const getForum = async (forum, url) => {
    try {
        if (forum) {
            const forumRef = doc(db, forumList, forum.id); // Reference to the forum document
            
            const forumDoc = await getDoc(forumRef); // Fetch the document
            
            if (forumDoc.exists()) {
                // If the document exists, return the data
                // console.log("Forum data:", forumDoc.data());
                return forumDoc.data(); // Return the data of the forum document
            } else {
                // console.log("No such forum document!");
                return null; // Return null if the document does not exist
            }
        }
    } catch ( error ) {
        console.error("Error fetching forum", error);
    }
}

/**
 * 
 * @param {String} question - question of the forum from params
 * @returns forum where question == question in the forums doc collection
 */
export const getForumByURL = async (question) => {

    
    // Adding the questionMark explicity
    question += '?';
    try {
        const forumRef = collection(db, forumList);
        const q = query(forumRef, where("question", "==", question));

        const querySnapshot = await getDocs(q); 

        if (!querySnapshot.empty) {
            // Return the first matching document (assuming unique questions)
            const forumData = querySnapshot.docs[0].data();
            return { id: querySnapshot.docs[0].id, ...forumData };
        } else {
            console.warn("No forum found with the given question.");
            return null;
        }
    } catch ( error ) {
        console.error("Error fetching forum bu URL", error);
    }
}

/**
 * 
 * @returns Fetches and returns all the forums from the forums collection
 */
export const getAllForums = async () => {
    try {
        // Reference the 'forumList' collection
        const forumRef = collection(db, forumList);
        
        // Fetch all documents from the collection
        const querySnapshot = await getDocs(forumRef);

        // Extract and format the data
        const forums = querySnapshot.docs.map((doc) => ({
            id: doc.id, // Include document ID for further operations
            ...doc.data(), // Spread the document data
        }));

        return forums; // Return the list of forums
    } catch (error) {
        console.error("Error fetching forums: ", error);
        throw new Error("Could not fetch forums");
    }
};

/**
 * Fetches all the reply documents for the given forum and sorts them.
 * 
 * @param {object} forum - Can be either the forum or reply document.
 * @returns {Array} - Sorted list of reply documents.
 */
export const getAllReplies = async (forum) => {
    try {
        const repliesRef = collection(db, replyList);

        // Create a query to filter replies where parentID matches the forum.id
        const repliesQuery = query(repliesRef, where("parentID", "==", forum.id), limit(forum.repliesCount));

        // Fetch the filtered documents from the Replies collection
        const querySnapshot = await getDocs(repliesQuery);

        // Extract, format, and sort the data
        const replies = querySnapshot.docs
            .map((doc) => ({
                id: doc.id, // Include reply document ID
                ...doc.data(), // Spread the document data
            }))
            .sort((a, b) => {
                // Primary sort: by upVotes - downVotes (descending)
                const scoreA = a.upVotes - a.downVotes;
                const scoreB = b.upVotes - b.downVotes;
                if (scoreA !== scoreB) {
                    return scoreB - scoreA; // Higher score comes first
                }

                // Secondary sort: by repliesCount (descending)
                if (a.repliesCount !== b.repliesCount) {
                    return b.repliesCount - a.repliesCount; // More replies come first
                }

                // Tertiary sort: by timestamp (descending)
                return new Date(b.timestamp) - new Date(a.timestamp); // Earlier timestamps come last
            });

        return replies; // Return the sorted list of replies
    } catch (error) {
        // console.error("Error getting replies:", error);
        // throw new Error("Could not fetch replies");
    }
};



/**
 * 
 * @param {object} DocToReply   - the document to which the user is replying to
 * @param {object} repliedBy    - all info about the logged in user
 * @param {string} reply        - the reply
 * @returns success or not
 */
export const submitReply = async (DocToReply, repliedBy, reply) => {

    try {
        const quesRef = doc(db, DocToReply.id[0] === 'd' ? forumList : replyList, DocToReply.id);
        const replyRef = collection(db, replyList);
        const documentName = generateDocumentID('reply'); // Generate document name based on the given word

        // Prepare the reply data
        const replyData = {
            parentID    : DocToReply.id,
            authorName  : repliedBy.displayName,  // Assuming `repliedBy` contains user data
            authorEmail : repliedBy.email,
            upVotes     : 0,
            downVotes   : 0,
            timestamp   : getTime(),  // Automatically set timestamp
            isAnonymous : reply.isAnonymous,  // From form data
            isExpert    : false,  // Assuming a default value for expert status
            reply       : reply.answer  // The actual reply content
        };

        // incrementing total replies count of the question by 1
        await updateDoc(quesRef, {
            repliesCount : increment(1)
        });

        // Add the reply document to the replies subcollection with a custom document name
        const replyDocRef = doc(replyRef, documentName);
        await setDoc(replyDocRef, replyData);

        // console.log("Reply added successfully!");
        return { success: true, documentName };

    } catch ( error ) {
        console.error("Error sending reply : ", error)
        return { success: false, error: error.message };
    }
}

/**
 * Handles User's Vote when they click the resp. button on the action bar
 * @param {String} parentType   - to identify if the document is a question (forum) or a reply
 * @param {string} docID        - it is the ID of the document
 * @param {object} userID       - Uesr's ID
 * @param {string} voteType     - "up" or "down" (the type of vote being cast)
 * @returns {object}            - success status or error information
 */
export const handleVote = async (parentType, docID, userID, voteType) => {
    try {
        // Determine if it's a forum question or reply
        const parentRef = doc(db, parentType === 'forum' ? forumList : replyList, docID);
        const voteRef = doc(db, voteList, generateHashedDocID(userID, docID)); // Unique vote ID for the user and document

        const voteFieldMap = {
            up: "upVotes",
            down: "downVotes",
        };

        const voteDoc = await getDoc(voteRef);

        if (voteDoc.exists()) {
            // User has already voted
            const previousVote = voteDoc.data().vote;

            if (previousVote === voteType) {
                // Undo the vote
                await updateDoc(parentRef, {
                    [voteFieldMap[voteType]]: increment(-1),
                });
                await deleteDoc(voteRef);
                // console.log(`${voteType} removed`);
            } else {
                // Change the vote type
                await updateDoc(parentRef, {
                    [voteFieldMap[previousVote]]: increment(-1),
                    [voteFieldMap[voteType]]: increment(1),
                });
                await setDoc(voteRef, {
                    parentID    : docID,
                    authorID    : userID,
                    timestamp   : getTime(),
                    vote        : voteType,
                });
                // console.log(`Vote changed to ${voteType}`);
            }
        } else {
            // First-time vote
            // console.log("No existing vote. Creating a new vote document.");

            // Increment the vote count
            await updateDoc(parentRef, {
                [voteFieldMap[voteType]]: increment(1),
            });

            // Create a new vote document
            await setDoc(voteRef, {
                parentID    : docID,
                authorID    : userID,
                timestamp   : getTime(),
                vote        : voteType,
            });

            // console.log(`${voteType} added`);
        }

        return { success: true };
    } catch (error) {
        console.error("Error handling vote: ", error);
        return { success: false, error: error.message };
    }
};

/**
 * 
 * @param {object} forum 
 * @returns deletes the forum and all related documents
 */
export const deleteForum = async (forum) => {
    try {
        const forumRef = doc(db, forumList, forum.id);

        // Delete replies where parentID === forum.id
        const repliesQuery = query(
            collection(db, replyList),
            where("parentID", "==", forum.id)
        );
        const repliesSnapshot = await getDocs(repliesQuery);
        const deleteRepliesPromises = repliesSnapshot.docs.map((doc) =>
            deleteDoc(doc.ref)
        );

        // Delete votes where parentID === forum.id
        const votesQuery = query(
            collection(db, voteList),
            where("parentID", "==", forum.id)
        );
        const votesSnapshot = await getDocs(votesQuery);
        const deleteVotesPromises = votesSnapshot.docs.map((doc) =>
            deleteDoc(doc.ref)
        );

        // Wait for all related documents to be deleted
        await Promise.all([...deleteRepliesPromises, ...deleteVotesPromises]);

        // Delete the forum document
        await deleteDoc(forumRef);

        // console.log(`Forum with ID ${forum.id} and related documents deleted successfully!`);
        return { success: true };
    } catch (error) {
        console.error("Error deleting Forum: ", error);
        return { success: false, error: error.message };
    }
};

/**
 * Handles the Follow/Unfollow button on the forum
 * 
 * @param {Object} user         -   Info about logged in user 
 * @param {Object} forum        -   The forum Data
 * @param {Bool} isFollowing    -   True, if the user is following the forum; false, is otherwise
 * @returns add and removes the user's email from the forum's followers array
 */
export const followUnfollowForum = async (user, forumID, isFollowing) => {
    try {
        const forumRef = doc(db, forumList, forumID);

        // If the user is already following the forum
        // Toggle it
        if (isFollowing) {
            // Unfollow: Remove user's email from the followers array
            await updateDoc(forumRef, {
                followers   : arrayRemove(user.email),
            });

        // Else if the user is not following the forum
        } else {
            // Follow: Add user's email to the followers array
            await updateDoc(forumRef, {
                followers   : arrayUnion(user.email),
            });
        }

        return true;
    } catch (error) {
        // console.error("Error following/unfollowing forum:", error);
        return false;
    }
};

/**
 * Checks if the user has voted on the forum or a reply
 */
export const checkUserVote = async (user, document) => {
    try {
        const voteRef = doc(db, voteList, generateHashedDocID(user.uid, document?.id));
        const docSnapshot = await getDoc(voteRef);

        if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            return { 
                [data.parentID] :   data.vote,
            }; // Returning vote status for this specific document
        }
        return {};
    } catch (error) {
        console.error("Error fetching user vote:", error);
        return {};
    }
};

/**
 * 
 * @param {String} tag - tag name
 * @returns {Boolean} - Whether the tag was successfully added or not
 */
export const addForumTag = async (tag) => {
    try {
        const tagsRef = collection(db, tagList); // Replace "tagList" with your collection name

        // Format the document name
        const docName = removeSpecialCharacters(removeSpacesAndConvertLowercase(tag));

        // Reference to the document
        const docRef = doc(tagsRef, docName);
        
        // Check if the document already exists
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
            // Document already exists, return true
            return true;
        }

        // Create the document data
        const tagData = {
            tag             : docName, // Tag in lowercase with hyphens
            tagDisplayName  : capitalizeFirstLetter(tag), // Original tag input with trimmed spaces
            usedCount       : 0, // Initial count is 0
        };

        // Set the document with the formatted name
        await setDoc(docRef, tagData);

        return true;
    } catch (error) {
        return false;
    }
};

/**
 * Checks if the tag is already present in the database
 * @param {string} tag 
 * @returns {bool} return true if the tag is already prsent in the dtabase else false
 */
export const isTagAlreadyInDatabase = async (tag) => {
    try {
        const tagsRef = collection(db, tagList); // Replace "tagList" with your collection name

        // Format the document name
        const docName = removeSpecialCharacters(removeSpacesAndConvertLowercase(tag));

        // Get the document
        const tagDoc = await getDoc(doc(tagsRef, docName));

        // Check if the document exists
        if (tagDoc.exists()) {
            // console.log("Tag already exists in the database:", tag);
            return true;
        }

        // console.log("Tag does not exist in the database:", tag);
        return false;
    } catch (error) {
        console.error("Error checking tag existence:", error);
        return false; // Treat as non-existent on error
    }
};

/**
 * Updates fields in a document
 * @param {string} forumID - can be a reply or forum ID 
 * @param {object} updatedFields - an object containing the new values of the fields to be updated
 */
export const updateForum = async (forumID, updatedFields) => {
    try {
        // Reference to the specific forum document
        const forumRef = doc(db, forumID[0] === 'd' ? forumList : replyList, forumID);

        // Update the document with the provided fields
        await updateDoc(forumRef, updatedFields);

    } catch (error) {
        console.error("Error updating forum: ", error);
    }
};

export const incrementViewsCount = async (forumID) => {
    try {
        const forumRef = doc(db, forumList, forumID);

        // Increment the "viewsCount" field by 1
        await updateDoc(forumRef, {
            views: increment(1),
        });
    } catch ( error ) {
        console.error("Error incrementing views count: ", error);
    }
}
// export const upvoteQuestion = async (data, user) => {
//     try {
//         const questionRef = doc(db, forumList, data.id);
//         const votesRef = doc(db, voteList, "d"+data.id); // Generate custom document name for votes

//         // Increment the upvotes field by 1
//         await updateDoc(questionRef, {
//             upvotes: increment(1)  // This increments the 'upvotes' field by 1
//         });

//         // Prepare the vote data
//         const voteData = {
//             parentID: data.id,     // The ID of the question being upvoted
//             authorID: user.uid,    // The UID of the user performing the upvote
//             timestamp: getTime(),  // The timestamp of the upvote
//             vote: "up",            // Indicates an upvote
//         };

//         // Add the vote document to the voteList collection
//         await setDoc(votesRef, voteData);

//         console.log("Upvote successful!");

//         // Optionally return success status or updated document
//         return { success: true };

//     } catch ( error ) {
//         console.error("Error in upvoting Question : ", error)
//     }
// }

// export const downvoteQuestion = async (data, user) => {
//     try {
//         const questionRef = doc(db, forumList, data.id);
//         const votesRef = doc(db, voteList, "d" + data.id); // Generate custom document name for votes

//         // Increment the upvotes field by 1
//         await updateDoc(questionRef, {
//             downvotes: increment(1)  // This increments the 'upvotes' field by 1
//         });

//         // Prepare the vote data
//         const voteData = {
//             parentID: data.id,     // The ID of the question being upvoted
//             authorID: user.uid,    // The UID of the user performing the upvote
//             timestamp: getTime(),  // The timestamp of the upvote
//             vote: "down",          // Indicates an upvote
//         };

//         // Add the vote document to the voteList collection
//         await setDoc(votesRef, voteData);

//         console.log("Downvote successful!");

//         // Optionally return success status or updated document
//         return { success: true };

//     } catch ( error ) {
//         console.error("Error in downvoting Question : ", error)
//     }
// }

// export const upvoteAnswer = async (data, user) => {
//     try {
//         const ansRef = doc(db, replyList, data.id);
//         const votesRef = doc(db, voteList, "v" + data.id)
        
//         // Increment the upvotes field by 1
//         await updateDoc(ansRef, {
//             upvotes: increment(1)  // This increments the 'upvotes' field by 1
//         });

//         // Prepare the vote data
//         const voteData = {
//             parentID: data.id,     // The ID of the question being upvoted
//             authorID: user.uid,    // The UID of the user performing the upvote
//             timestamp: getTime(),  // The timestamp of the upvote
//             vote: "up",          // Indicates an upvote
//         };

//         // Add the vote document to the voteList collection
//         await setDoc(votesRef, voteData);

//         console.log("Upvote answer successful!");

//         // Optionally return success status or updated document
//         return { success: true };

//     } catch ( error ) {
//         console.error("Error in upvoting Answer : ", error)
//         return { success: false }
//     }
// }

// export const downvoteAnswer = async (data, questionData, user) => {
//     try {
//         const ansRef = doc(db, replyList, data.id);
//         const votesRef = doc(db, voteList, "v" + data.id)
        
//         // Increment the downvotes field by 1
//         await updateDoc(ansRef, {
//             downvotes: increment(1)  // This increments the 'upvotes' field by 1
//         });

//         // Prepare the vote data
//         const voteData = {
//             parentID: data.id,     // The ID of the question being upvoted
//             authorID: user.uid,    // The UID of the user performing the upvote
//             timestamp: getTime(),  // The timestamp of the upvote
//             vote: "down ",          // Indicates an upvote
//         };

//         // Add the vote document to the voteList collection
//         await setDoc(votesRef, voteData);

//         console.log("Downvote answer successful!");

//         // Optionally return success status or updated document
//         return { success: true };

//     } catch ( error ) {
//         console.error("Error in downvoting Answer : ", error)
//         return { success: false }
//     }
// }