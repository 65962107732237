import { addDoc, collection, doc, getDoc, getDocs, updateDoc, where, query } from "@firebase/firestore";
import { db } from "../config/firebase";
import { addMonthsToDate, addMonthsToISODate } from "./userDatabaseHelper";
import { convertDDMMYYYYToISOTimestamp, generateRandomStringId, getCurrentYear, getDateInISO } from "./basicHelper";
import { cityList, classReceipts, financeList, gymList, revenueList, studioList, userList } from "../components/Databases";

/**
 * Stores data in gym's database
 * @param   {Object}    myData      - User data including city, gymName, months, userID, contactNo
 * @param   {Object}    response    - Response object from Razorpay containing paymentID, orderID, signature
 * @returns {boolean}               - True if save successful, false otherwise
 */
export const storeGymData = async (myData, response, userResponseData) => {
    let startDate   = userResponseData?.startDate || getDateInISO();
    let endDate     = userResponseData?.endDate   || addMonthsToISODate(startDate, myData.months);
    try {
        const gymRef = collection(db, cityList, myData.city, myData?.orderType === 'studio' ? studioList : gymList);
        const gymDocRef = doc(gymRef, myData.gymName);
        let gymDoc = await getDoc(gymDocRef);

        // If gym doesn't exist
        if (!gymDoc.exists()) {
            // const city = myData.city.toLowerCase().replace(/\s+/g, '');
            const gymName = myData.gymName.toLowerCase().replace(/\s+/g, '');
            const gymQuery = query(gymRef, where('codeName', '==', gymName));
            const querySnapshot = await getDocs(gymQuery);
            if (!querySnapshot.empty) {
                // If the gym document exists, get the first matching document
                gymDoc = querySnapshot.docs[0];
            } else {
                return false;
            }
        }
        
        const userRef = collection(db, cityList, myData.city, myData?.orderType === 'studio' ? studioList : gymList, gymDoc.id, userList);
        const userQuery = query(userRef, where('userID', '==', myData.user.uid));
        const userSnapshot = await getDocs(userQuery);
        
        // If user is renewing
        if (!userSnapshot.empty) {
            
            const userDoc = userSnapshot.docs[0];
            const userData = userDoc.data();
            
            // Check if `membershipTill` is in ISO format or 'DD-MM-YYYY'
            if (userData?.membershipTill) {
                // Check for ISO format by attempting to parse it into a Date object
                const isISOFormat = !isNaN(Date.parse(userData.membershipTill));
                
                if (isISOFormat) {
                    endDate = addMonthsToISODate(userData.membershipTill, myData.months);
                } else {
                    endDate = addMonthsToISODate(convertDDMMYYYYToISOTimestamp(userData.membershipTill), myData.months);
                }
            }           
            
            await updateDoc(userDoc.ref, {
                status          : true,
                membershipTill  : endDate,
                // Add a gym membership ID if necessary
            });
        }
        // If user is new to the gym
        else {
            const userDBRef = doc(collection(db, 'user'), myData.user.uid);
            const userDBDoc = await getDoc(userDBRef);
            const userDBData = userDBDoc.data();
            await addDoc(userRef, {
                gender              : userDBData.gender || myData.gender || 'Male',
                // age                 : userDBData.age || myData.age || userDBData.dob ? calculateAge(userDBData.dob) : '',
                phoneNumber         : myData.user.phoneNumber || myData.phoneNumber || '',
                profilePic          : userDBData.profilePic || "",
                email               : myData.user.email || userDBData.email || myData.user.uid,
                userID              : myData.user.uid,
                userName            : userDBData.name || userDBData.displayName || myData.user.uid,
                membershipFrom      : startDate,
                memberSince         : getDateInISO(),
                membershipTill      : endDate,
                status              : true,
                DOB                 : myData.dateOfBirth || userDBData.dob || '',
                // Add a gym membership ID if necessary
            });
        }

        // Add the receipts to another collection
        const revenueRef = collection(
            db,
            cityList,
            myData.city,
            myData?.orderType === 'studio' ? studioList : gymList,
            gymDoc.id,
            financeList,
            getCurrentYear(),
            revenueList
        );

        storeReceiptinDatabase(revenueRef, {
            ...myData,
            startDate   : startDate,
            endDate     : endDate,
        }, response.razorpay_payment_id);

        return {
            status        :   true,
            errorMessage  :   '',
        };

    } catch (error) {
        console.log('Error in Storing Gym Data : ', error);
        return {
            status          :   false,
            errorMessage    :   error
        };
    }
};

/**
 * Function used to store receits
 * @param {Ref} databaseRef   - The Reference of the database
 * @param {Object} myData     - The Data to be inserted in the database
 * @param {String} paymentID  - The paymentID of the receipt
 */
export const storeReceiptinDatabase = async (databaseRef, myData, paymentID) => {
    try {
        // Get a reference to the user document
        const userRef = doc(db, "user", myData.user.uid || myData.userID);
        const userSnap = await getDoc(userRef); 
        const userData = userSnap.data(); // Get the document data
        const email = userData.email; // Access the email field
      await addDoc ( databaseRef, {
        timestamp           :   new Date().toISOString(),
        paymentID           :   paymentID,
        gymDisplayName      :   myData.gymDisplayName,
        cityDisplayName     :   myData.cityDisplayName,
        city                :   myData.city,
        gymEmail            :   myData.gymEmail,
        orderType           :   myData?.orderType === 'studio' ? 'Studio Membership' : "Gym Membership",
        months              :   myData.months || null,
        classSchedule       :   myData.classSchedule || null,
        userID              :   myData.user.uid || myData.userID || "",
        amount              :   myData.amount || null,
        userName            :   myData.user.displayName || myData.userName,
        email               :   myData.user.email || myData.email || email || "",
        personalTrainer     :   myData.hasTrainer ? myData.trainerName : false, // If hasTrainer is True, store their name, else false
        phoneNumber         :   myData.user.phoneNumber || myData.phoneNumber || '',
        startDate           :   myData.startDate || null,
        endDate             :   myData.endDate || null,
        gymContactNo        :   myData.contactNo || myData.gymContactNo || '',
        receiptNumber       :   generateRandomStringId(),
        totalMemberships    :   myData.totalMemberships || 1,
        settled             :   false,
        timingOfClass       :   myData?.orderType === 'studio' ? myData?.selectedShift : '',
        className           :   myData?.orderType === 'studio' ? myData?.className : '',
        user                :   myData.user ? {
            displayName     :   myData.user.displayName,
            phoneNumber     :   myData.user.phoneNumber,
            email           :   myData.user.email,
            gender          :   myData.user.gender,
        } : {},
        address             :   myData.address,

      })
    } catch (error) {
      console.error('Error in storing Receipt : ', error);
    }
};

export const updateMemberShipBoughtCount = async (city, gymName, orderType = '') => {
    try {    
        const gymRef = collection(db, cityList, city, orderType === 'studio' ? studioList : gymList);
        const gymDocRef = doc(gymRef, gymName);
        const gymDoc = await getDoc(gymDocRef);
        const gymData = gymDoc.data();

        // Initialize membershipSold if it doesn't exist
        let membershipSold = {
            thisWeek: 0,
            total: 0,
            lastUpdated: null, // track last update for thisWeek
        };

        // If the membershipSold field exists, use the current data
        if (gymData && gymData.membershipSold) {
            membershipSold = gymData.membershipSold;
        }

        const today = new Date();
        const currentDay = today.getDay(); // get the current day (0 = Sunday, 1 = Monday, etc.)
        const lastUpdatedDate = membershipSold.lastUpdated ? new Date(membershipSold.lastUpdated) : null;

        // Check if today is Monday and if it's a new week compared to last update
        if (currentDay === 1 && (!lastUpdatedDate || lastUpdatedDate.getDay() !== 1)) {
            // Reset thisWeek on Monday
            membershipSold.thisWeek = 0;
        }

        // Update membership counts
        membershipSold.thisWeek += 1;
        membershipSold.total += 1;
        membershipSold.lastUpdated = today.toISOString(); // Update the lastUpdated timestamp

        await updateDoc(gymDocRef, {
            membershipSold: membershipSold,
        });
    } catch (error) {
        // console.log('Error in updating membership count : ', error);
    }
};


/**
 * Gets the gym data for the user profile
 * @param {Object} city - City name
 * @param {Object} gymName - Gym name
 * @returns {Object} - Gym data
 */
export const getGymDataForProfile = async (city, gymName, studioName) => {
    const gymRef = collection(db, cityList, city, gymList);
    const studioRef = collection(db, cityList, city, studioList);
    const gymDocRef = doc(gymRef, gymName);
    const studioDocRef = doc(studioRef, studioName)
    const gymDoc = await getDoc(gymDocRef);
    const studioDoc = await getDoc(studioDocRef);
    const gymData = gymDoc.data();
    const studioData = studioDoc.data();

    return gymData+studioData;
}


/**
 * Stores the "Unknown" User in Gym's Database
 * This is the manual addition from the Gym's Dashboard
 * 
 * @param   {Object}    myData  - Stores all the form and gym/studio details
 * @returns {Boolean}           - if storing is successful; false otherwise
 */
export const storeUnknownUserInGym = async (myData) => {
    let startDate = getDateInISO();
    let endDate = '';

    try {
        const gymRef = collection(
            db,
            cityList,
            myData.city,
            myData?.orderType === 'studio' ? studioList : gymList,
            myData.gymName,
            userList
        );
        // Get a reference to the user document
        // const userRef = doc(db, "user", myData.user.uid);
        // const userSnap = await getDoc(userRef); 
        // const userData = userSnap.data(); // Get the document data
        // const email = userData.email; // Access the email field
        const gymQuery = query(gymRef, where("email", "==", myData.user.email));
        const querySnapshot = await getDocs(gymQuery);

        // Check if User is "Kanjar", still not making an account
        // But always buying a membership through the gym
        if (!querySnapshot.empty) {
            // "Kanjar" member found
            const gymDoc = querySnapshot.docs[0];
            const gymData = gymDoc.data();
            const membershipTillDate = gymData.membershipTill;

            if (membershipTillDate) {
                // Check for ISO format by attempting to parse it into a Date object
                const isISOFormat = !isNaN(Date.parse(membershipTillDate));
                
                if (isISOFormat) {
                    if (startDate < membershipTillDate) {
                        // If start Date is less than the already ending membership Date
                        // Update startDate
                        startDate = membershipTillDate
                    }
                    endDate = addMonthsToISODate(membershipTillDate, myData.months);
                } else {
                    if (startDate < convertDDMMYYYYToISOTimestamp(membershipTillDate)) {
                        // If start Date is less than the already ending membership Date
                        // Update startDate
                        startDate = convertDDMMYYYYToISOTimestamp(membershipTillDate);
                    }
                    endDate = addMonthsToDate(startDate, myData.months);
                }
            } 

            // Only update the gymDoc
            await updateDoc(gymDoc.ref, {
                status          : true,
                membershipTill  : endDate,
                membershipFrom  : startDate,
                personalTrainer : myData.hasTrainer ? myData.trainerName : false, // If hasTrainer is True, store their name, else false
            });

        } else {
            // New member
            // User Does Not Exist in Gym's Database
            endDate = addMonthsToDate(startDate, myData.months);

            try {
                await addDoc(gymRef, {
                    age             : myData.user.age,
                    gender          : myData.user.gender,
                    userName        : myData.user.displayName,
                    email           : myData.user.email,
                    status          : true,
                    memberSince     : startDate,
                    membershipFrom  : startDate,
                    membershipTill  : endDate,
                    months          : myData.months,
                    phoneNumber     : myData.user.phoneNumber || "",
                    personalTrainer : myData.hasTrainer ? myData.trainerName : false, // If hasTrainer is True, store their name, else false
            });
            } catch (error) {
                // console.log("Error adding doc in storeUnkownUser :", error);
                return ({
                    status          :   false,
                    startDate,
                    endDate,
                    errorMessage    :   error
                })
            }
        }

        // Save Receipt
        const receiptRef = collection(
            db,
            cityList,
            myData.city,
            myData?.orderType === 'studio' ? studioList : gymList,
            myData.gymName,
            financeList,
            getCurrentYear(),
            revenueList
        );
        // Storing Receipt in the database
        storeReceiptinDatabase(receiptRef, {
            ...myData,
            startDate   : startDate,
            endDate     : endDate,
        }, "Self");

        return ({
            status      :   true,
            startDate,
            endDate,

        });
    } catch (error) {
        // console.log("Error in storing unknown user :", error);
        return ({
            status          :   false,
            startDate,
            endDate,
            errorMessage    :   error
        });
    }
};


export const storeClasssReceiptInGymDatabase = async (myData, response) => {
    try {
        const gymRef = collection(db, cityList, myData.city, myData?.orderType === 'studio' ? studioList : gymList, myData.gymName, classReceipts);
        await addDoc(gymRef, {
            userID          :   myData.user.uid,
            userEmail       :   myData.user.email,
            timestamp       :   new Date().toISOString(),
            amount          :   myData.amount,
            className       :   myData.className                  || null,
            classSchedule   :   myData.classSchedule              || null,
            day             :   myData.selectedDay                || null,
            classDate       :   myData.classDate                  || null,
            paymentID       :   response.razorpay_payment_id      || "Self",
        })

        return ({status : true});

    } catch (error) {
        // console.log('error in storeClasssReceiptInGymDatabase : ', error);
        return ({status : false});
    }
}