import React, { useEffect, useState } from 'react';
import { fetchStudioReviews } from '../../helperFunctions/RatingAndReviews/HandlingReviews';
import StarRating from '../StarRating';
import { ReviewItem } from '../../helperFunctions/RatingAndReviews/DisplayReview';
import { getRatingCounts, NoReviews, ReviewActions } from '../../helperFunctions/RatingAndReviews/ReviewElements';
import AddReviewModal from '../../helperFunctions/RatingAndReviews/AddReviewModal';

const StudioRatingAndReviews = ({
    setSignInModal,
    isUserPresent,
    currStudioData,
    user,
}) => {

    const [reviews            ,             setReviews] = useState({});
    const [                   ,       setAverageRating] = useState();
    const [ratingCounts                               ] = useState();
    const [visibleReviews     ,      setVisibleReviews] = useState(3);
    const [showAddReviewModal ,  setShowAddReviewModal] = useState(false);
    

    // to fetch Studio reviews
    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const fetchedReviews = await fetchStudioReviews(currStudioData.city, currStudioData.studioName);
                setReviews(fetchedReviews);
    
                // Calculate average rating
                if (fetchedReviews?.length > 0) {
                    const totalRating = fetchedReviews.reduce((acc, review) => acc + review.rating, 0);
                    const averageRating = totalRating / fetchedReviews?.length;
                    setAverageRating(averageRating.toFixed(1)); // Set average rating and round to 1 decimal place
                } else {
                    setAverageRating(0); // If no reviews, set average rating to 0
                }
    
                // Call getRatingCounts directly with fetchedReviews
                getRatingCounts(fetchedReviews);
    
            } catch (error) {
                // Handle the error
                // console.error("Error fetching reviews:", error);
            }
        };
    
        fetchReviews();
    }, [currStudioData]); // Only depend on currStudioData

    const showMoreReviews = () => {
        setVisibleReviews((prevVisibleReviews) => prevVisibleReviews + 3);
    };

    const showLessReviews = () => {
        setVisibleReviews(3)
    }

    return (
        <div className="flex flex-col justify-start lg:border-4 border-secondary rounded-2xl w-full lg:w-[50%] lg:h-[700px]">
            <div className="flex flex-col lg:flex-row justify-between items-left mr-2">
                <p className="flex lg:px-4 py-4 pb-2 text-secondary text-xl lg:text-3xl font-bold text-left">
                    Rating and Reviews
                </p>

                <div className="flex flex-col lg:px-4 py-2">
                    {ratingCounts && Object.keys(ratingCounts).reverse().map(star => (
                        <div key={star} className="flex items-center justify-between -mb-1 cursor-default">
                        <StarRating rating={Number(star)} showNoOfReviews={false} color="secondary" />
                        <p className="ml-2 text-secondary text-sm lg:text-lg font-semibold">
                            : {ratingCounts[star]}
                        </p>
                        </div>
                    ))}
                </div>
            </div>

            <div className="flex lg:mx-4 border-t-2 border-secondary lg:my-4"></div>

            <div className="flex flex-col lg:px-4 relative h-full overflow-y-scroll">
                {reviews?.length > 0 ? (
                    <>
                        {/* Displays the reviews */}
                        {Array.isArray(reviews) && reviews.slice(0, visibleReviews).map((review, index) => (
                            <ReviewItem key={index} review={review} />
                        ))}
                        {/* 2 Buttons at the bottom */}
                        <ReviewActions
                            setSignInModal        =   {setSignInModal}
                            showAddReviewModal    =   {showAddReviewModal}
                            setShowAddReviewModal =   {setShowAddReviewModal}
                            isUserPresent         =   {isUserPresent} 
                            currGymData           =   {currStudioData}
                            user                  =   {user} 
                            isUserRecent          =   {true} 
                            visibleReviews        =   {visibleReviews}
                            showMoreReviews       =   {showMoreReviews}
                            showLessReviews       =   {showLessReviews}
                            NoOfReview            =   {currStudioData.NoOfReview}
                        />
                        
                        {showAddReviewModal && isUserPresent ? (
                            // Add Review Modal
                            <AddReviewModal 
                                setShowAddReviewModal  =   {setShowAddReviewModal} 
                                gymLocation            =   {currStudioData.city} 
                                gymName                =   {currStudioData.studioName} 
                                isRecentGymUser        =   {true} 
                                user                   =   {user} 
                                isGymReview            =   {false}
                            />
                        ) : null}
                    </>
                ) : (
                    <NoReviews 
                        showAddReviewModal     =   {showAddReviewModal}
                        setShowAddReviewModal  =   {setShowAddReviewModal} 
                        isUserPresent          =   {isUserPresent} 
                        currGymData            =   {currStudioData} 
                        user                   =   {user} 
                        isUserRecent           =   {true} 
                        isGymReview            =   {false}
                    />
                )}
            </div>
        </div>
    );
};

export default StudioRatingAndReviews;
