import React, { useEffect, useRef, useState } from "react";
import "../../App.css";
import { Helmet } from "react-helmet-async"; // Import Helmet for SEO
import { ListCard } from "../../helperFunctions/MarketplaceHelpers/ButtonsLibrary";
import "../../index.css";
import { getDailyTips } from "../../helperFunctions/MarketplaceHelpers/MarketplaceHelper";
import { fetchAllGyms } from "../../helperFunctions/GymInfoHelpers/FetchGymsList";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../config/firebase";
// import Map from "../../helperFunctions/MarketplaceHelpers/MarketplaceLandingPageComponents/Mapbox";
// import Sidebar from "../../components/resultPage/Sidebar";
import { getTrackBackground, Range } from "react-range";
import { themeColors } from "../../theme";
import { FilterSVG, SortSVG } from "../../assets/Website_content/svg";
import { getSellingPrice } from "../../helperFunctions/MarketplaceHelpers/gymDataHelpers/PriceHelpers";
import { calculateDistance, requestLocationAccess } from "../../helperFunctions/MarketplaceHelpers/MapHelpers/DistanceAndLocationHelpers";
import UserFitnessForm from "../../helperFunctions/AIandMore/UserFitnessForm/UserFitnessForm";

/**
 * Listing Component
 * 
 * @component
 * 
 * This component is responsible for displaying a list of gyms for a selected city. 
 * It fetches gym data either from the navigation state (if available) or from 
 * the database using the `fetchAllGyms` function. It includes pagination for gym listings, 
 * a map view, and SEO using `Helmet`.
 * 
 * @returns {JSX.Element} The rendered Listing component.
 */

const Listing = ({showUnlockGym, setShowUnlockGym}) => {
    const noResultsRef                                     = useRef(false);
    const searchResultsRef                                 = useRef(null);
    const location                                         = useLocation();
    const { city }                                         = useParams();
    const [searchParams         ,         setSearchParams] = useSearchParams();
    const currentPageFromUrl                               = parseInt(searchParams.get("page")) || 1; // Get current page from query parameters, default to 1
    const itemsPerPage                                     = 10;
    const [baseGyms             ,             setBaseGyms] = useState([]);    
    const [currentPage          ,          setCurrentPage] = useState(currentPageFromUrl);
    
    const [loading              ,              setLoading] = useState(true);
    const [filteredGyms         ,         setFilteredGyms] = useState([]);
    // const [paginatedGyms        ,        setPaginatedGyms] = useState([]);   
    const [userLocation         ,         setUserLocation] = useState(null);
    
    const [sort                 ,                 setSort] = useState('');
    
    const [budget               ,               setBudget] = useState([1,10000]);
    const [distance             ,             setDistance] = useState([0.1,10]);
    
    const [filtersOpen          ,          setFiltersOpen] = useState(false);
    const [filterType           ,           setFilterType] = useState(); // to check if the user used the price or budegt filter
    const [sortingOpen          ,          setSortingOpen] = useState(false);
    const [locationDenied       ,       setLocationDenied] = useState(false);
    const [USR                  ,                  setUSR] = useState(window.devicePixelRatio);  //userScreenResolution
    const [isFromSearch         ,         setIsFromSearch] = useState(false);

    /**
     * Handles search query persistence and state updates.
     * - Retrieves query from URL, state, or localStorage.
     * - Loads stored search results and "no results" state.
     * - Updates base gyms list if results exist.
     * - Ensures query persists in the URL.
     */
    useEffect(() => {
        // Get search query from URL, state, or localStorage
        const searchQuery = searchParams.get('search') || location?.state?.searchQuery;
        const storedIsFromSearch = localStorage.getItem("isFromSearch") === "true";
    
        setIsFromSearch(Boolean(searchQuery || storedIsFromSearch));
    
        const storedNoResults = JSON.parse(localStorage.getItem("noResults") || "false");
        const storedSearchResults = JSON.parse(localStorage.getItem("searchResults") || "null");
    
        noResultsRef.current = location?.state?.noResults ?? storedNoResults;
        searchResultsRef.current = location?.state?.searchResults ?? storedSearchResults;
    
        if (searchQuery && storedSearchResults) {
            setBaseGyms(storedSearchResults);
        }
    
    }, [location?.state, searchParams, setSearchParams]);
    
    // Combined effect for page navigation and search persistence
    useEffect(() => {
        const newSearchParams = new URLSearchParams(searchParams);
        
        if (currentPage > 1) {
            newSearchParams.set('page', currentPage.toString());
        } else {
            newSearchParams.delete('page');
        }
    
        // Preserve search query in params
        const searchQuery = searchParams.get('search');
        if (searchQuery) {
            newSearchParams.set('search', searchQuery);
        }
        else newSearchParams.delete('search');
    
        setSearchParams(newSearchParams, { replace: true });
    }, [currentPage, searchParams, setSearchParams]);
       
    // Fetch user's location
    useEffect(() => {
        // Check if location is already stored
        const storedLocation = localStorage.getItem("userLocation");
        const locationDenied = localStorage.getItem("locationDenied");
        
        if (storedLocation) {
            // If location is already stored, use it
            setUserLocation(JSON.parse(storedLocation));
        } else if (!locationDenied) {
            // If no stored location and location access not denied, request access
            requestLocationAccess(
                (location) => {
                    // Successfully obtained location
                    setUserLocation(location);
    
                    // Store location in localStorage
                    localStorage.setItem("userLocation", JSON.stringify(location));
                },
                (error) => {
                    console.warn("Location access denied:", error);
    
                    // Set a flag indicating location access was denied
                    localStorage.setItem("locationDenied", "true");
                }
            );
        }
    }, []);

    /**
     * useEffect to log a page view event to Firebase Analytics when the location changes.
     * The log includes the current path to track user navigation.
     * 
     * @fires logEvent
     * @param {Object} location - The location object from React Router.
     */
    useEffect(() => {
        logEvent(analytics, "Marketplace Listing", {
            page_path: location.pathname,
        });
    }, [location]);

    /**
     * sets the USR statewith the current resolution of user's browser
     * screen resolution , 1 === 100%, 1.25 = 125%, 1.5 = 150% resolution
     */
    useEffect(() => {
        const handleResize = () => {
            const newResolution = window.devicePixelRatio;
            setUSR((prevResolution) => {
                if (prevResolution !== newResolution) {
                    return newResolution;
                }
                return prevResolution;
            });
        };
        window.addEventListener("resize", handleResize);

        // Cleanup listener on unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Fetch gyms only if not present in navigation
    useEffect(() => {
        const fetchGyms = async () => {
            setLoading(true);
            const isFromSearch = localStorage.getItem("isFromSearch") === "true";
            const storedSearchResults = JSON.parse(localStorage.getItem("searchResults"));
            const searchQuery = searchParams.get('search');
    
            try {
                // If there's a search query in URL, preserve the search state
                if (searchQuery && storedSearchResults) {
                    setBaseGyms(storedSearchResults);
                    setFilteredGyms(storedSearchResults);
                }
                // If there are search results in localStorage but no query, use them
                else if (isFromSearch && storedSearchResults) {
                    setBaseGyms(storedSearchResults);
                    setFilteredGyms(storedSearchResults);
                }
                // If no search state and no gyms, fetch all gyms
                else if (baseGyms?.length === 0) {
                    const gyms = await fetchAllGyms(city);
                    setBaseGyms(gyms);
                    setFilteredGyms(gyms);
                    
                    // Only clear search-related localStorage if we're fetching fresh data
                    localStorage.removeItem("isFromSearch");
                    localStorage.removeItem("searchResults");
                    localStorage.removeItem("noResults");
                }
            } catch (error) {
                console.error("Error fetching gyms:", error);
                // Handle error appropriately
            } finally {
                setLoading(false);
            }
        };
    
        fetchGyms();
    }, [city, baseGyms, searchParams]); // Added searchParams to dependencies

    // // Creates array of paginated Gyms
    // useEffect(() => {
    //     const createPagination = () => {
    //         const pages = [];
    //         for (let i = 0; i < filteredGyms.length; i += itemsPerPage) {
    //             pages.push(filteredGyms.slice(i, i + itemsPerPage));
    //         }
    //         setPaginatedGyms(pages);
    //         setCurrentPage(1);
    //     };
    //     createPagination();
    // }, [filteredGyms, itemsPerPage]);

    // /**
    //  * Gets the Gyms for a specific page
    //  * @param   {Number} currentPage    -   Page Number
    //  * @returns {List}                  -   List of the gyms on that page
    //  */
    // const getPageGyms = (currentPage) => {
    //     return(
    //         paginatedGyms[currentPage - 1] || []
    //     );
    // }

    // Reset to page 1 when filter/sort changes
    // useEffect(() => {
    //     setCurrentPage(1);
    // }, [filteredGyms]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Smooth scroll
        });
    }, [currentPage])

    /** 
     * @const totalPages 
     * @description Calculates the total number of pages for pagination, based on the length of the filteredGyms.
     * @returns {number} - Total pages.
     */
    const totalPages = filteredGyms ? Math.ceil(filteredGyms ? filteredGyms?.length / itemsPerPage : filteredGyms?.length / itemsPerPage) : 0;

    /**
     * Handles filtering and sorting based on user interactions
     */
    const handleFilterAndSort = (type = 'filter') => {
        let gyms = [...baseGyms];
    
        if (type === 'filter') {
            // Distance Filter
            if(filterType === 'distance') {
                gyms = gyms.filter((gym) => {
                    if (userLocation) {
                        const distanceInKm = calculateDistance(
                            { lat: userLocation.lat, lng: userLocation.lng },
                            parseFloat(gym.latitude),
                            parseFloat(gym.longitude),
                            locationDenied, 
                            () => requestLocationAccess(setUserLocation, setLocationDenied) 
                        );
    
                        // console.log("Distance in KM :", distanceInKm)
                        // console.log("less : ", distanceInKm <= distance[1])
                        // console.log("More : ", distanceInKm >= distance[0] )
                        // console.log("return : ",gym.gymDisplayName, distanceInKm >= distance[0] && distanceInKm <= distance[1])
        
                        return distanceInKm >= distance[0] && distanceInKm <= distance[1];
                    }
                    return true; // If user location is not available, don't filter by distance
                });
            }
    
            // Budget Filter
            if(filterType === 'budget') {
                gyms = gyms.filter((gym) => {
                    const pricePerMonth = getSellingPrice(parseFloat(gym?.prices?.single?.exclusiveMonthlyPrice), gym?.isExclusive, gym?.gymName, gym?.city)
    
                    // console.log("prices per month :", pricePerMonth)
                    // console.log("budget  low :", budget[0])
                    // console.log("budget high :", budget[1])
                    // console.log("First :", pricePerMonth >= budget[0])
                    // console.log("Second :", pricePerMonth <= budget[1])
                    // console.log("ीाेहतू :", pricePerMonth >= budget[0] && pricePerMonth <= budget[1])
    
        
                    return pricePerMonth >= budget[0] && pricePerMonth <= budget[1];
                });
            }
    
            setFiltersOpen(false);
        } else {
            // Sorting Logic
            setSortingOpen(false);
    
            gyms = [...filteredGyms];
    
            if (sort === 'LtoH') {
                gyms.sort((a, b) => (a?.prices?.single?.monthlyPrice || 0) - (b?.prices?.single?.monthlyPrice || 0));
            } else if (sort === 'HtoL') {
                gyms.sort((a, b) => (b?.prices?.single?.monthlyPrice || 0) - (a?.prices?.single?.monthlyPrice || 0));
            } else if (sort === 'AtoZ') {
                gyms.sort((a, b) => (a?.gymDisplayName || '').localeCompare(b?.gymDisplayName || ''));
            } else if (sort === 'ZtoA') {
                gyms.sort((a, b) => (b?.gymDisplayName || '').localeCompare(a?.gymDisplayName || ''));
            } else if (sort === '') {
                gyms = [...baseGyms];
            }
        }
    
        setFilteredGyms(gyms);
        setCurrentPage(1); // Reset page to 1
    };

    // if the user filters out all the gyms, then they are restored to the origin state, filter type to see if the user has made the filtered gyms empty or the state is just getting updated
    useEffect(() => {
        if(filteredGyms?.length === 0 && filterType) {
            // console.log(999999)
            setTimeout(() => {
                // console.log(911111111199999)
                setFilteredGyms(baseGyms);
                setDistance([0.1, 10]);
                setBudget([1, 10000])
            }, 5000)
        }
    }, [filteredGyms, baseGyms, filterType])

    const renderPaginationButtons = () => {
        const maxPagesToShow = 5;
        let pages = [];
    
        if (totalPages <= maxPagesToShow) {
            // Show all pages if total pages are ≤ 5
            pages = Array.from({ length: totalPages }, (_, i) => i + 1);
        } else {
            if (currentPage <= 3) {
                // Case: First few pages (1,2,3)
                pages = [1, 2, 3, 4, "...", totalPages];
            } else if (currentPage >= totalPages - 2) {
                // Case: Last few pages (last 3)
                pages = [1, "...", totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
            } else {
                // Case: Middle pages
                pages = [1, "...", currentPage - 1, currentPage, currentPage + 1, "...", totalPages];
            }
        }
    
        return pages.map((page, index) =>
            typeof page === "number" ? (
                <button
                    key={index}
                    onClick={() => setCurrentPage(page)}
                    className={`px-3 py-1 rounded ${
                        currentPage === page ? "bg-secondary text-tertiary" : "bg-tertiary text-secondary border-2 border-secondary"
                    }`}
                >
                    {page}
                </button>
            ) : (
                <span key={index} className="px-2">...</span>
            )
        );
    };
    
    

    return (
        <>
            {/* SEO: Helmet for Title, Meta Descriptions, and Keywords */}
            <Helmet>
                <title>Find Gyms in {city} | Top Fitness Centers | Train Rex</title>
                <meta
                    name="description"
                    content={`Explore top-rated gyms and fitness centers in ${city}. Discover membership deals, free trials, and expert trainers. Start your fitness journey today with Train Rex.`}
                />
                <meta
                    name="keywords"
                    content={`gyms in ${city}, fitness centers, free trials, membership deals, personal trainers`}
                />
                <link rel="canonical" href={`https://trainrexofficial.com/${city}/gyms`} />
            </Helmet>

            {/* Main Listing Section */}
            <div className={`bg-tertiary flex flex-col px-4 lg:px-6 min-h-screen pt-28 ${USR <= 1 ? 'lg:pt-28' : USR === 1.25 ? 'lg:pt-28' : USR === 1.5 ? 'lg:pt-24' : 'lg:pt-28'}`}>
                {loading ? (
                    <div className="h-[80vh] flex justify-center items-center flex-col">
                        <div className="loader mb-6 mt-14 text-8xl font-extrabold scale-125">Train Rex</div>
                        <div className="fill-text text-3xl lg:text-4xl m-4 font-semibold">{getDailyTips()}</div>
                    </div>
                ) : (filteredGyms && (
                    <div className={`flex gap-12 my-8 ${USR >= 1.5 ? 'lg:mx-6' : USR === 1 ? 'lg:mx-44' : USR === 1.25 ? 'lg:mx-20' : 'lg:mx-40'} min-h-screen`}>

                        <div className="flex flex-col w-full">

                            {/* Title */}
                            <h1 className="fade-in-slide-up-delayed flex items-center gap-1 text-secondary text-xl lg:text-4xl font-bold text-left pl-2">
                                <div>
                                    {isFromSearch 
                                        ? (noResultsRef.current ? 'NO GYMS FOUND, CHECK OUT THE REST' : 'SHOWING SEARCH RESULTS')
                                        : 'SHOWING GYMS IN YOUR AREA'}
                                </div>
                            </h1>

                            <div className={`w-full flex justify-end gap-2 -ml-2 mt-4`}>
                                <button onClick={() => setFiltersOpen(true)} className={`p-2 text-sm text-purple font-semibold bg-lightPurple rounded-lg flex items-center gap-1`}><FilterSVG color='#876eaf' height='18px' /><div>FILTER BY</div></button>
                                <button onClick={() => setSortingOpen(true)} className={`p-2 text-sm text-purple font-semibold bg-lightPurple rounded-lg flex items-center gap-1`}><SortSVG color='#876eaf' height='18px' /><div>SORT BY</div>  </button>
                            </div>

                            {/* List of Gyms with Fade and Slide Animation */}
                            <div className="fade-in-slide-up-delayed">
                                <div className="flex flex-wrap">
                                    {filteredGyms?.length !== 0 && filteredGyms.slice((currentPage-1)*itemsPerPage, currentPage*itemsPerPage).map((card, index) => (
                                        <ListCard key={index} {...card} buttonText="Explore" USR={USR}/>
                                    ))}

                                    {filteredGyms?.length === 0 && (
                                        <div className="font-bold text-purple mt-40 text-2xl mx-12">
                                            Currently there are<br />no gyms available that meet your requirements
                                        </div>
                                    )}
                                </div>

                                {/* Pagination */}
                                <div className="flex justify-center mt-8 space-x-2">
        {renderPaginationButtons()}
    </div>
                            </div>
                        </div>
                    </div>
                )
                )}
            </div>

            <UserFitnessForm
                isModalOpen={showUnlockGym}
                onClose={() => setShowUnlockGym(false)}   // so we can close the modal from inside the form if needed
                
            />

            {/* Filter Component */}
            <div className={`fixed inset-0 z-50 h-[100vh] w-[100vw] flex justify-center lg:justify-end items-end transition-opacity duration-500 ${filtersOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
                <div className={`relative h-2/3 w-full lg:h-full lg:w-1/3 right-0 bg-tertiary rounded-tl-[40px] rounded-tr-[40px] lg:rounded-tr-none lg:rounded-bl-[40px] flex flex-col text-lg p-8 transition-transform duration-700 transform ${filtersOpen ? 'translate-y-0 lg:translate-x-0' : 'translate-y-full lg:translate-x-full'}`} style={{boxShadow: '4px 4px 10px black'}}>
                    <div onClick={() => setFiltersOpen(false)} className="h-8 w-8 absolute right-5 top-2 text-4xl cursor-pointer">×</div>
                    
                    <div className="font-semibold text-purple text-left mt-4  flex items-center gap-1">
                        <FilterSVG color='#876eaf' height='18px' />
                        <div>FILTER</div>
                    </div>

                    <div className={`max-h-[85%] overflow-y-scroll mt-8 ml-4 pr-8`}>
                        {/* Price Filter */}
                        <div>
                            <label className="block font-semibold text-2xl text-primary mb-2 text-left">
                                Price
                            </label>
                            <div className="flex justify-between text-md text-primary mb-2">
                                <span>Min: <span className="font-extrabold">₹{budget[0]}</span></span>
                                <span>Max: <span className="font-extrabold">₹{budget[1]}</span></span>
                            </div>

                            <Range
                                step={100}
                                min={0}
                                max={10000}
                                values={budget.map((value) => Math.max(0, Math.min(10000, Math.round(value / 100) * 100)))}
                                onChange={setBudget}
                                onFinalChange={() => {
                                    handleFilterAndSort("filter");
                                    setFilterType("budget");
                                }}
                                renderTrack={({ props, children }) => (
                                    <div
                                    key="range-track"
                                    {...props}
                                    className="h-2 w-full rounded-md"
                                    style={{
                                        background: getTrackBackground({
                                        values: budget,
                                        colors: ["#ccc", themeColors.complementPurple, "#ccc"],
                                        min: 1,
                                        max: 10000,
                                        }),
                                    }}
                                    >
                                    {children}
                                    </div>
                                )}
                                renderThumb={({ props }) => (
                                    <div
                                    key="range-thumb"
                                    {...props}
                                    className="w-6 h-6 rounded-full bg-primary shadow-lg flex items-center justify-center"
                                    ></div>
                                )}
                                />
                        </div>

                        {/* Distance Filter */}
                        <div>
                            <label className="block font-semibold text-2xl text-primary mb-2 mt-12 text-left">
                                Distance
                            </label>
                            <div className="flex justify-between text-md text-primary mb-2">
                                <span className="font-extrabold">{distance[0]}KM</span>
                                <span className="font-extrabold">{distance[1]}KM</span>
                            </div>

                            <Range
                                step={0.1}
                                min={0}
                                max={10}
                                values={distance}
                                onChange={setDistance}
                                onFinalChange={() => {handleFilterAndSort('filter'); setFilterType('distance')}} // Trigger filtering on range adjustment
                                renderTrack={({ props, children }) => (
                                    <div
                                        {...props}
                                        className="h-2 w-full rounded-md"
                                        style={{
                                            background: getTrackBackground({
                                                values: distance,
                                                colors: ["#ccc", themeColors.complementPurple, "#ccc"],
                                                min: 0.1,
                                                max: 10,
                                            }),
                                        }}
                                    >
                                        {children}
                                    </div>
                                )}
                                renderThumb={({ props }) => (
                                    <div
                                        {...props}
                                        className="w-6 h-6 rounded-full bg-primary shadow-lg flex items-center justify-center"
                                    ></div>
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>


            {/* Sort Component */}
            <div className={`fixed inset-0 z-50 h-[100vh] w-[100vw] flex justify-center lg:justify-end items-end transition-opacity duration-500 ${sortingOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
                <div className={`relative h-[70vh] w-full lg:h-full lg:w-1/3 right-0 bg-tertiary rounded-tl-[40px] rounded-tr-[40px] lg:rounded-tr-none lg:rounded-bl-[40px] flex flex-col text-lg p-8 transition-transform duration-700 transform ${sortingOpen ? 'translate-y-0 lg:translate-x-0' : 'translate-y-full lg:translate-x-full'}`} style={{boxShadow: '4px 4px 10px black'}}>
                    <div onClick={() => setSortingOpen(false)} className="h-8 w-8 absolute right-5 top-2 text-4xl cursor-pointer">×</div>
                        
                    <div className="font-semibold text-purple text-left mt-4  flex items-center gap-2">
                        <SortSVG color='#876eaf' height='18px' />
                        <div>SORT</div>
                    </div>

                        <div className={`mt-10 font-semibold text-2xl text-left pl-4`}>Price</div>
                        <div className={`flex flex-col items-center`}>
                            <div onClick={() => {sort === 'LtoH' ? setSort('') : setSort('LtoH') }} className={`w-[50vw] lg:w-[70%] rounded-md py-2 border-purple p-[1px] shadow-md mt-4 font-bold ${sort === 'LtoH' ? 'text-tertiary bg-purple' : 'text-purple bg-tertiary'} cursor-pointer`}>
                                Lowest to Highest
                            </div>
                            <div onClick={() => {sort === 'HtoL' ? setSort('') : setSort('HtoL') }} className={`w-[50vw] lg:w-[70%] rounded-md py-2 border-purple p-[1px] shadow-md mt-4 font-bold ${sort === 'HtoL' ? 'text-tertiary bg-purple' : 'text-purple bg-tertiary'} cursor-pointer`}>
                                Highest to Lowest
                            </div>
                        </div>

                        <div className={`mt-4 font-semibold text-2xl text-left pl-4`}>Name</div>
                        <div className={`flex flex-col items-center`}>
                            <div onClick={() => {sort === 'AtoZ' ? setSort('') : setSort('AtoZ')}} className={`w-[50vw] lg:w-[70%] rounded-md py-2 border-purple p-[1px] shadow-md mt-4 font-bold ${sort === 'AtoZ' ? 'text-tertiary bg-purple' : 'text-purple bg-tertiary'} cursor-pointer`}>
                                A to Z
                            </div>
                            <div onClick={() => {sort === 'ZtoA' ? setSort('') : setSort('ZtoA')}} className={`w-[50vw] lg:w-[70%] rounded-md py-2 border-purple p-[1px] shadow-md mt-4 font-bold ${sort === 'ZtoA' ? 'text-tertiary bg-purple' : 'text-purple bg-tertiary'} cursor-pointer`}>
                                Z to A
                            </div>
                        </div>
                    

                        <div className={`flex justify-end w-full gap-4 mt-10`}>
                            <div 
                                onClick={() => handleFilterAndSort('sort')} 
                                className={`px-4 py-2 rounded-md border-[1px] border-purple shadow-md text-lg font-semibold text-purple`}
                            >
                                Save
                            </div>
                            <div 
                                onClick={() => {setSort(''); setSortingOpen(false);}} 
                                className={`px-4 py-2 rounded-md border-[1px] border-purple shadow-md text-lg font-semibold text-purple`}
                            >
                                Cancel
                            </div>
                        </div>
                </div>
            </div>
        </>
    );
}

export default Listing;
