import { collection, doc, getDoc, getDocs } from "@firebase/firestore";
import { cityList, studioList } from "../../components/Databases"
import { db } from "../../config/firebase"

export const fetchStudios = async (city) => {
    try {
        const studioesRef = collection(db, cityList, city, studioList);
        
        // Fetch all documents from the collection
        const querySnapshot = await getDocs(studioesRef);
        
        // Map through documents and extract data
        const studios = querySnapshot.docs.map((doc) => ({
            ...doc.data(), // Spread document data
        }));

        // console.log(studios)

        return studios; // Return the list of studios
    } catch (error) {
        // console.error("Error fetching studios:", error);
        throw error; // Propagate the error to handle it in the caller
    }
};

export const fetchStudio = async (studioName, city) => {
    try {
        const studioRef = doc(db, cityList, city, studioList, studioName);
        const docSnapshot = await getDoc(studioRef);
        
        if (docSnapshot.exists()) {
            // Return only the document data
            return docSnapshot.data();
        } else {
            // console.error("No such document found!");
            return null; // Or throw an error if needed
        }
    } catch (error) {
        // console.error("Error fetching studio document:", error);
        throw error; // Propagate the error for the caller to handle
    }
};