import { getDistance } from "geolib";
import Alert from "../../../components/customComponents/Alert";

/**
 * Calculate distance between user's location and gym location.
 * @param {Object} userLocation - User's geolocation (latitude and longitude).
 * @param {number} gymLat - Gym's latitude.
 * @param {number} gymLng - Gym's longitude.
 * @param {boolean} locationDenied - If location access was denied.
 * @param {Function} requestLocationAccess - Function to prompt location access.
 * @returns {string|JSX.Element} - Distance in kilometers or a prompt for location access.
 */
export const calculateDistance = (userLocation, gymLat, gymLng, locationDenied = false) => {
    try {

        if (userLocation) {
            const distance = getDistance(
                { latitude: userLocation.lat, longitude: userLocation.lng },
                { latitude: gymLat, longitude: gymLng }
            );

            if(window.location.href.includes('gyms')) {
                return distance;
            }
            return `${(distance / 1000).toFixed(2)} km`; // Converts meters to kilometers
        } else if (!locationDenied) {
            return (
                <span
                    onClick={() => requestLocationAccess()}
                    style={{ cursor: "pointer", color: "black", textDecoration: "underline" }}
                >
                    Allow Location Access
                </span>
            );
        } else {
            return <span>Loading...</span>;
        }
    } catch (error) {
        return 'N/A';
    }
};

/**
 * Generates a navigation link for selected gym.
 * @param {number} lat - Gym's latitude.
 * @param {number} lng - Gym's longitude.
 * @returns {string} - URL for navigation based on the user's device.
 */
export const getNavigationLink = (lat, lng) => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
    const appleMapsUrl = `http://maps.apple.com/?daddr=${lat},${lng}`;
    return isIOS ? appleMapsUrl : googleMapsUrl; // Use Apple Maps for iOS, Google Maps otherwise
};

// Declare a module-level variable to track whether the message has been shown
let messageShown = false;

console.log("Location Message shown?  ", messageShown)
/**
 * Request location access from the user.
 * @param {Function} setUserLocation - Function to update user location state.
 * @param {Function} setLocationDenied - Function to update location denied state.
 */
export const requestLocationAccess = (setUserLocation, setLocationDenied) => {
    navigator.geolocation.getCurrentPosition(
        (position) => {
            const userCoords = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            };
            setUserLocation(userCoords);
            setLocationDenied(false); // Reset flag upon successful permission
        },
        () => {
            if (!messageShown) {
                Alert('info', "Please enable location access in your browser settings for accurate distance information.");
                messageShown = true; // Prevent further alerts
            }
            setLocationDenied(true);
        }
    );
};