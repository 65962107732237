import { useEffect, useMemo, useState } from "react";
import { getCurrencyToDisplay } from "../../helperFunctions/PaymentHelpers";
import { getSellingPrice } from "../../helperFunctions/MarketplaceHelpers/gymDataHelpers/PriceHelpers";

const StudioMembershipPrices = ({ USR, gymName, city, prices, isExclusive, toggleSummaryModal, setSelectedCategory }) => {
    const [planType] = useState(1); // 1 is single, 2 is couple, 3 is family (default to single)
    const [className, setClassName] = useState("zumba"); // Default to Zumba

    const planName = useMemo(() => ({
        1: "single",
        2: "couple",
        3: "family",
    }), []);



    useEffect(() => {
        setSelectedCategory(planName[planType]);
    }, [planType, setSelectedCategory, planName]);

    // // Check if the current plan type exists in the data
    // const hasPlan = (plan) => prices?.[plan] && Object.keys(prices?.[plan])?.length > 0;

    const currentPlan = planName[planType];
    const availableClasses = Object.keys(prices[currentPlan] || {});

    // Check if selected class has pricing data
    const hasCurrentClass = className && prices[currentPlan]?.[className];

    return (
        <div className="relative">
            <div className="absolute top-10 right-2 lg:right-4 flex gap-4 items-center">
                {/* Plan Type Dropdown */}
                <div className="">
                    {/* <label className="mr-2 text-lg font-medium">Plan</label> */}
                    {/* <select
                        value={planType}
                        onChange={(e) => setPlanType(Number(e.target.value))}
                        className="px-4 py-2 rounded-md outline-none"
                    >
                        <option value={1}>Single</option>
                        <option value={2}>Couple</option>
                        <option value={3}>Family</option>
                    </select> */}
                    <div className={`px-4 py-2 rounded-full bg-lightSecondary`}>Single</div>
                </div>

                {/* Class Type Dropdown */}
                <div className="">
                    <label className="mr-2 text-lg font-medium">Class</label>
                    <select
                        value={className}
                        onChange={(e) => setClassName(e.target.value)}
                        className="px-4 py-2 rounded-md outline-none"
                    >
                        <option value="">Select Class</option>
                        {availableClasses.map((className) => (
                            <option key={className} value={className}>
                                {className.charAt(0).toUpperCase() + className.slice(1)}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            {/* If No Prices Available for Selected Combination */}
            {!hasCurrentClass ? (
                <div className="no-prices-available text-center mt-20 h-[80vw] lg:h-[15vw] flex items-center justify-center shadow-lg rounded-2xl">
                    <p className="text-2xl font-bold text-red-500">No prices available for the selected combination.</p>
                </div>
            ) : (
                <div>
                    {/* Display Prices for Selected Class and Plan */}
                    <div className="class-pricing-section mb-8">
                        <h2 className="text-xl font-bold mb-4">
                            {className.charAt(0).toUpperCase() + className.slice(1)} Class Membership Prices
                        </h2>
                        <Prices
                            USR={USR}
                            gymName={gymName}
                            city={city}
                            currClassName={className}
                            currClassData={prices[currentPlan][className]}
                            isExclusive={isExclusive}
                            toggleSummaryModal={toggleSummaryModal}
                            planName={currentPlan}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

const Prices = ({ USR, gymName, city, currClassName, currClassData, isExclusive, toggleSummaryModal, planName }) => {
    return (
        <div className="prices-container bg-white shadow-md p-4 pt-16 rounded-md">
            <div className="prices-list">
                {[
                    { duration: "1 Day"     , months: 0,  key: "dailyPrice"      },
                    { duration: "1 Month"   , months: 1,  key: "monthlyPrice"    },
                    { duration: "3 Months"  , months: 3,  key: "quarterlyPrice"  },
                    // { duration: "6 Months"  , months: 6,  key: "halfYearlyPrice" },
                    // { duration: "12 Months" , months: 12, key: "yearlyPrice"     },
                ].map(({ duration, months, key }) => {
                    const mrp = currClassData?.[key];
                    const exclusivePrice = currClassData?.[`exclusive${key.charAt(0).toUpperCase() + key.slice(1)}`];
                    const price = getSellingPrice(exclusivePrice, isExclusive, gymName, city)
                    const currency = getCurrencyToDisplay();
                    const className = currClassName;

                    return (
                        <div key={key} className="price-item flex justify-between items-center mt-4">
                            <span className="font-medium">{duration}</span>
                            <div className="price-values flex items-center gap-4">
                                {mrp && (
                                    <span className="line-through text-highlightRed">{`${currency}${mrp}`}</span>
                                )}
                                <button
                                    onClick={() => toggleSummaryModal(months, price, mrp, className, planName, exclusivePrice)}
                                    className="bg-lightSecondary px-4 py-2 rounded-full"
                                >
                                    {exclusivePrice ? `${currency}${price}` : mrp ? `${currency}${price}` : "No Price"}
                                </button>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default StudioMembershipPrices;
