import { collection, getDocs } from "@firebase/firestore";
import { db } from "../../config/firebase";
import { tagList } from "../../components/Databases";
/**
 * 
 * @returns all the tags object in forumTags collection
 */
export const getAllTags = async () => {
    try {
        const tagsRef = collection(db, tagList);
        const querySnapshot = await getDocs(tagsRef); // Fetch all documents from the collection

        const tags = [];
        querySnapshot.forEach((doc) => {
            tags.push(doc.data()); 
        });

        // Deduplicate tags if they might overlap across documents
        const uniqueTags = [...new Set(tags)];

        return uniqueTags;
    } catch (error) {
        console.error("Error getting tags: ", error);
        return [];
    }
}